<template>
  <ValidationProvider tag="div" v-slot="{ errors }" :rules="rules" :name="name" class="block mb-6">
    <label :for="name" >
      <span v-if="showLabel" class="select-label">{{ label }} </span>
    </label>
    <select
        class="select"
        :class="[isError ? 'border-red-base' : 'border-grey-dark']"
        :name="name"
        :id="name"
        :required="required"
        @change="$emit('change', $event.target.value)"
        @input="
        $emit('input', $event.target.value),
          $emit('getSelectValue', $event.target.value)
      "
    >
      <option :selected="!selected" disabled>{{ description }}</option>
      <option
          v-for="(option, index) in optionsList"
          :key="index"
          :value="returnValue(option)"
          :selected="selected === option.value"
      >
        {{ option.name }}
      </option>
    </select>
    <span
        v-if="errors[0]"
        class="mt-2 font-sans text-sm font-medium error-msg text-red-base"
    >
      {{ errors[0].replaceAll('-'," ")  }}
    </span>
  </ValidationProvider>
</template>
<script>
import {ValidationProvider} from 'vee-validate';

export default {
  name: "SelectDropdown",
  inheritAttrs: false,
  components: {
    ValidationProvider
  },
  props: {
    showLabel: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    selected: {
      type: [String, Number],
    },
    description: {
      type: String,
    },
    label: {
      type: String,
      default: "Label",
    },
    optionsList: {
      type: Array,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    errorMsg: {
      type: String,
      default: "",
    },
    vid: {
      type: String,
      default: undefined
    },
    required: {
      type: Boolean,
      required: false
    },
    returnValue:{
      type:Function,
      default:(item)=>item.value
    },
    rules: {
      type: [Object, String],
      required: false
    }
  },
};
</script>
<style scoped>
.select-label {
  @apply text-black-base block font-sans font-medium;
  @apply text-base;
}

.select {
  @apply rounded border focus:border-teal-base focus:ring-teal-lighter;
  @apply focus:ring-2 mt-1.5 sm:mt-3 p-2 py-3 w-full;
}
</style>