import Partner from "@/views/Partners/Partner.vue";
import Request from "@/views/Requests/Request";
import CreatePartner from "@/views/Partners/CreatePartner.vue";
import EditPartner from "@/views/Partners/EditPartner.vue";
import Partners from "@/views/Partners/Partners";


export default [
    {
        path: "/partners",
        name: "Partners",
        component:Partners,
        meta: {requiresAuth: true},
    },
    {
        path: "/partners/create",
        name: "CreatePartners",
        component: CreatePartner,
        meta: {requiresAuth: true},
    },
    {
        path: "/partners/edit/:id",
        name: "EditPartner",
        component: EditPartner,
        meta: {requiresAuth: true},
    },
    {
        path: "/partners/:id",
        name: "Partner",
        component: Partner,
        meta: {requiresAuth: true},
    },
    {
        path: "/partners/:partnerId/requests/:requestId",
        name: "PartnerRequest",
        component: Request,
        props: true,
        meta: {requiresAuth: true},
    }
]