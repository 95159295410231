<template>
  <section id="itinerary">
    <h1 class="form-page-title">Itinerary</h1>
    <ExperiencesConfigurator
      v-if="tourDateRangeString"
      @createExperience="$emit('createExperience')"
      :alreadyHasItinerary="alreadyHasItinerary"
      :destination="tourDetails.destination"
      :dates="tourDateRangeString"
      :selectedItinerary="tourDetails.itinerary"
      @openSheet="emitOpenSheet"
      @reset="resetItinerary"
      @removeItinerary="removeItineraryFromTour"
      @addItinerary="addItineraryToTour"
    />
  </section>
</template>
<script>
import ExperiencesConfigurator from "@/components/Offer/Itinerary/ExperiencesConfigurator";
import { mapActions, mapGetters } from "vuex";
import { tourDateRangeFromDurationISO } from "@/shared/dateHandler";

export default {
  name: "Itinerary",
  components: {
    ExperiencesConfigurator,
  },
  props: {
    alreadyHasItinerary: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      tourDetails: "groupTours/tourDetails",
      selectedExperiences: "experiences/selectedExperiences",
      experiences: "experiences/experiences",
    }),
    tourDateRangeString() {
      if (this.tourDetails.tourDates) {
        return tourDateRangeFromDurationISO(
          this.tourDetails.tourDates[0].split("|")[0],
          this.tourDetails.duration
        );
      }
      return null;
    },
  },
  methods: {
    ...mapActions({
      setShowExperienceListSheet: "experiences/setShowExperienceListSheet",
      addItinerary: "groupTours/addItinerary",
      removeItinerary: "groupTours/removeItinerary",
      resetItinerary: "groupTours/resetItinerary",
    }),

    addItineraryToTour(itinerary) {
      this.addItinerary(itinerary);
    },

    removeItineraryFromTour(index) {
      this.removeItinerary(index);
    },
    emitOpenSheet() {
      this.$emit("openSheet");
      this.setShowExperienceListSheet(true);
    },
  },
};
</script>
<style scoped>
#itinerary {
  @apply pb-44;
}

.form-page-title {
  @apply font-sans font-bold text-black-base text-xl mt-12 sm:text-3xl mb-4 sm:mb-8;
}
</style>
