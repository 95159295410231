<template>
  <div>
    <slot v-if="!rowData"></slot>

    <div v-else>
      <div class="t-row">
        <template v-for="(tableData, index) in rowData">
          <template v-if="!showID && index.toLowerCase() == 'id'"> </template>

          <template
            v-else-if="
              ['image', 'img', 'pic', 'avatar'].includes(index.toLowerCase())
            "
          >
            <div
              class="
                text-sm
                font-sans
                text-black-base
                flex
                items-center
                col-span-1
              "
              :key="index"
            >
              <img :src="tableData" class="rounded-full h-10" alt="" />
            </div>
          </template>

          <template v-else>
            <div
              class="row-text"
              :class="index == 'id' ? 'col-span-1' : 'col-span-2'"
              :key="index"
            >
              <template v-if="['status'].includes(index.toLowerCase())">
                <div
                  class="status-label"
                  :class="{
                    'bg-violet-light': tableData.toLowerCase() == 'changes',
                    'bg-yellow-light': tableData.toLowerCase() == 'pending',
                    'bg-red-light': tableData.toLowerCase() == 'canceled',
                    'bg-green-light': tableData.toLowerCase() == 'completed',
                  }"
                >
                  {{ tableData }}
                </div>
              </template>
              <template v-else>
                {{ tableData }}
              </template>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableRow",
  props: {
    rowData: {
      type: Object,
      required: false,
    },
    showID: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
  },
};
</script>
<style scoped>
.t-row {
  @apply grid grid-flow-col grid-cols-12 gap-4;
  @apply h-14 sm:px-6 w-full bg-white shadow-sm hover:shadow-md transition duration-300 rounded;
}
.status-label {
  @apply text-sm font-sans text-black-base font-semibold flex items-center;
}
.row-text {
  @apply text-sm font-sans text-black-base flex items-center;
}
</style>