<template>
  <aside class="sidebar">
    <div class="flex justify-between">
      <router-link class="flex" to="/">
        <img
          class="h-11"
          alt="Travel Afrique Logo"
          src="@/assets/img/logo.svg"
        />
      </router-link>
      <ButtonClose class="btn-mb" @click.native="$emit('closeMenu')" />
    </div>
    <nav class="pt-10">
      <template v-for="link in links">
        <SidebarButton :key="link.name+'_link'"
            :text="link.name"
            :route="link.route"
            :iconClass="link.iconClass"
            class="mb-3"
        />
      </template>
      <ButtonIconText
        @click.native.prevent="submitLogout"
        text="Logout"
        class="w-full h-12 mt-20"
        iconClass="bx bx-log-out"
      />
    </nav>
  </aside>
</template>
<script>
import ButtonIconText from "@/components/Buttons/ButtonIconText";
import ButtonClose from "@/components/Buttons/ButtonClose";
import SidebarButton from "@/components/Layout/Sidebar/SidebarButton";
import { mapActions } from "vuex";

export default {
  name: "Sidebar",
  components: {
    ButtonClose,
    ButtonIconText,
    SidebarButton,
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),
    async submitLogout() {
      this.logout()
        .then(() => {
          this.$router.push({ name: "kota" });
        })
        .catch(() => {
          this.$router.push({ name: "kota" });
        });
    },
  },
  data(){
    return {
      links:[
        {
          name:"Dashboard",
          route:"/",
          iconClass:"bxs-dashboard"
        },
        {
          name:"Requests",
          route:"/requests",
          iconClass:"bxs-chat"
        },
        {
          name:"Group Tours",
          route:"/group-tours",
          iconClass:"bxs-plane-take-off"
        },
        {
          name:"Customers",
          route:"/customers",
          iconClass:"bxs-user-pin"
        },
        {
          name:"Partners",
          route:"/partners",
          iconClass:"bxs-briefcase-alt"
        },
        {
          name:"My account",
          route:"/account",
          iconClass:"bxs-user-account"
        }
      ]
    }
  }
};
</script>
<style scoped>
.sidebar {
  @apply h-full block bg-white w-72 px-4 sm:px-6 py-8;
  @apply hidden md:block lg:left-0 lg:shadow-none lg:relative;
}

.btn-mb {
  @apply block md:hidden;
}
</style>