var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.rowData)?_vm._t("default"):_c('div',[_c('div',{staticClass:"t-row"},[_vm._l((_vm.rowData),function(tableData,index){return [(!_vm.showID && index.toLowerCase() == 'id')?void 0:(
            ['image', 'img', 'pic', 'avatar'].includes(index.toLowerCase())
          )?[_c('div',{key:index,staticClass:"text-sm font-sans text-black-base flex items-center col-span-1"},[_c('img',{staticClass:"rounded-full h-10",attrs:{"src":tableData,"alt":""}})])]:[_c('div',{key:index,staticClass:"row-text",class:index == 'id' ? 'col-span-1' : 'col-span-2'},[(['status'].includes(index.toLowerCase()))?[_c('div',{staticClass:"status-label",class:{
                  'bg-violet-light': tableData.toLowerCase() == 'changes',
                  'bg-yellow-light': tableData.toLowerCase() == 'pending',
                  'bg-red-light': tableData.toLowerCase() == 'canceled',
                  'bg-green-light': tableData.toLowerCase() == 'completed',
                }},[_vm._v(" "+_vm._s(tableData)+" ")])]:[_vm._v(" "+_vm._s(tableData)+" ")]],2)]]})],2)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }