import api from "@/api/index"

export default {
  namespaced: true,
  state: {
    authenticate: false,
    token: localStorage.getItem('token') || null,
    user: null,
    role: null,
  },

  getters: {
    authenticated(state) {
      return state.authenticated;
    },
    token(state) {
      return state.user;
    },
    user(state) {
      return state.user;
    },
    role(state) {
      return state.role;
    },
  },

  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },

    SET_TOKEN(state, value) {
      localStorage.setItem('token',value);

    },

    SET_USER(state, value) {
      state.user = value;
    },
    SET_ROLE(state, value) {
      state.role = value;
    },
  },

  actions: {
    async login({ commit }, credentials) {
      let response = await api.post("login", credentials);

      try {

        if (response.data.user.role === "administrator") {
          commit("SET_AUTHENTICATED", true);
          commit("SET_TOKEN", response.data.token);
          commit("SET_USER", response.data.user);
          return response;
        } else {
          window.location.href='https://www.travelafrique.com';
        }

      } catch (error) {
        commit("SET_AUTHENTICATED", false);
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
        return response;
      }
    },

    async logout({ commit }) {
      await api.post(
        "/logout",
        {},
      );
      localStorage.setItem('token',null );
      commit("SET_AUTHENTICATED", false);
      commit("SET_TOKEN", null);
      commit("SET_USER", null);
    },
  },
};
