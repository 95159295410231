import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import store from '@/store/index';
import Request from "@/views/Requests/Request";
import offer from "./offer.routes";
import groupTours from "./group-tours.routes";
import partners from "./partners.routes";
import customers from "./customer.routes";
import PageNotFound from "@/views/404";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {requiresAuth: true},
    }, {
        path: "/account",
        name: "account",
        component: () => import("@/views/Account.vue"),
        meta: {requiresAuth: true},
    }, {
        path: "/kota",
        name: "kota",
        component: () => import("@/views/Login.vue"),
        meta: {requiresAuth: false},
    }, {
        path: "/request/:requestId",
        name: "Request",
        component: Request,
        props: true,
        meta: {requiresAuth: true},
    }, {
        path: "/requests",
        name: "Requests",
        component: () => import("@/views/Requests/Requests.vue"),
        meta: {requiresAuth: true},
    },
    ...partners,
    ...customers,
    ...offer,
    ...groupTours,
    {
        path: '/:catchAll(.*)',
        component: PageNotFound,
        name: 'NotFound'
    }
];

const router = new VueRouter({
    mode: "history",
    routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
});

router.beforeEach((to, from, next) => {

    window.scrollTo(0, 0);

    if (from.matched.some(record => record.meta.needsConfirm)) {

        let showWarning = true;
        if (to.params.showWarning) {
            //converting the string to a boolean
            showWarning = JSON.parse(to.params.showWarning);
        }
        if (showWarning) {
            if (window.confirm("Are you sure you want to leave the page? \nIf you leave without saving, your changes will be lost.")) {
                next();
            } else {
                return
            }
        } else {
            next();
        }
    }
    const loginQuery = {path: "/kota", query: {redirect: to.fullPath}};
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.state.auth.authenticated) {
            next()
            return
        }
        next(loginQuery)
    }
    next();
});

export default router;
