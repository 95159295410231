import {
  defaultStartStopDates,
  reformatDatesToIso,
} from "@/shared/dateHandler";

import api from "@/api";

export default {
  namespaced: true,
  state: {
    loadingMessage: "Updating offer",
    savingResponseMessage: "Success",
    savingResponseStatus: "Success",
    isSubmittingOffer: false,
    shouldRedirectAfterOfferSave: false,
    showUnsavedChangesWarning: true,
    isLoadingData: false,
    showOfferSummary: false,
    tripDetails: {
      tripGroupType: "",
      tripAdults: 0,
      tripChildren: 0,
      tripChildrenAge: "",
      tripBudget: 0,
      baseTripCurrency: "CHF",
      tripCurrency: "USD",
      tripDestination: "Algeria",
      tripReason: "",
      tripDates: defaultStartStopDates(),
      tripAccompany: "",
      tripExperiences: "",
      tripDetails: "",
      tripAccommodation: [],
      tripSingleRooms: 0,
      tripMealPlan: "",
      tripSalutation: "",
      tripFirstname: "",
      tripLastname: "",
      tripEmail: "",
      tripPhone: "",
      tripConsultation: 0,
      userId: 0,
      tripRequestStatus: "",
      tripInclusions: [],
      tripExclusions: [],
      tripAccommodationDetails: [],
      tripItinerary: [],
      tripFullRefundDays: "",
      tripPartialRefund: "",
      tripPartialRefundDays: "",
      tripNoRefundDays: "",
      tripPricePerAdult: 0,
      tripTotalPrice: 0,
      tripSingleRoomPrice: 0,
      baseTripTotalPrice: 0,
      baseTripPricePerAdult: 0,
      baseTripPricePerChild: 0,
      baseTripSingleRoomPrice: 0,
      tripAgencies: [],
      tripEarningFees: 0,
    },
    customerDetails: {},
    offerPricing: {
      currency: "USD",
      pricePerAdult: 0,
      numberOfAdults: 1,
      pricePerChild: 0,
      numberOfChildren: 0,
      roomPrice: 0,
      numberOfRooms: 0,
      earningFees: 0,
    },
    requestedChanges: [],
    hasAlreadySavedOffer: false,
    exchangeRates: {},
  },

  getters: {
    offerPricing(state) {
      return state.offerPricing;
    },
    offerDetails(state) {
      return state.offerDetails;
    },
    tripDetails(state) {
      return state.tripDetails;
    },
    requestedChanges(state) {
      return state.requestedChanges;
    },
    showOfferSummary(state) {
      return state.showOfferSummary;
    },
    savingResponseMessage(state) {
      return state.savingResponseMessage;
    },
    savingResponseStatus(state) {
      return state.savingResponseStatus;
    },
    isSubmittingOffer(state) {
      return state.isSubmittingOffer;
    },
    isLoadingData(state) {
      return state.isLoadingData;
    },
    loadingMessage(state) {
      return state.loadingMessage;
    },
    shouldRedirectAfterOfferSave(state) {
      return state.shouldRedirectAfterOfferSave;
    },
    showUnsavedChangesWarning(state) {
      return state.showUnsavedChangesWarning;
    },
    exchangeRates(state) {
      return state.exchangeRates;
    },
  },

  mutations: {
    SET_LOADING_MESSAGE(state, value) {
      state.loadingMessage = value;
    },

    SET_SAVING_RESPONSE_MESSAGE(state, value) {
      state.savingResponseMessage = value;
    },

    SET_SAVING_RESPONSE_STATUS(state, value) {
      state.savingResponseStatus = value;
    },

    SET_IS_SUBMITTING_OFFER(state, value) {
      state.isSubmittingOffer = value;
    },

    SET_IS_LOADING_DATA(state, value) {
      state.isLoadingData = value;
    },

    SET_HAS_ALREADY_SAVED_OFFER(state, value) {
      state.hasAlreadySavedOffer = value;
    },

    SET_TRIP_DETAILS(state, value) {
      state.tripDetails = value;
    },

    SET_REQUESTED_CHANGES(state, value) {
      state.requestedChanges = value;
    },

    SET_SHOW_OFFER_SUMMARY(state, value) {
      state.showOfferSummary = value;
    },

    SET_SHOULD_REDIRECT_AFTER_OFFER_SAVE(state, value) {
      state.shouldRedirectAfterOfferSave = value;
    },
    SET_SHOW_UNSAVED_CHANGES_WARNING(state, value) {
      state.showUnsavedChangesWarning = value;
    },
    SET_EXCHANGE_RATES(state, value) {
      state.exchangeRates = value;
    },
    REMOVE_AN_ITINERARY(state, index) {
      state.tripDetails.tripItinerary.splice(index, 1);
    },

    REMOVE_AN_ACCOMMODATION(state, index) {
      state.tripDetails.tripAccommodationDetails.splice(index, 1);
    },
    UNSET_ACCOMMODATION_DETAILS(state) {
      state.tripDetails.tripAccommodationDetails = [];
    },

    UNSET_ITINERARY_DETAILS(state) {
      state.tripDetails.tripItinerary = [];
    },
  },

  actions: {
    addAccommodation({ state }, accommodationDetail) {
      //push to the accommodations array

      //attempt getting the index of the item in the array
      const data = state.tripDetails.tripAccommodationDetails;
      const getIndex =
        data.findIndex((x) => {
          return x.id == parseInt(accommodationDetail.id);
        }) || null;

      if (getIndex > -1) {
        //if the index is greater than -1 then the item exists
        state.tripDetails.tripAccommodationDetails.splice(
          getIndex,
          1,
          accommodationDetail
        );
      } else {
        //else the item does not exist
        state.tripDetails.tripAccommodationDetails.splice(
          accommodationDetail.id,
          0,
          accommodationDetail
        );
      }
    },

    addItinerary({ state }, itineraryDetail) {
      //attempt getting the index of the item in the array
      const data = state.tripDetails.tripItinerary;
      const getIndex = data.findIndex((x) => {
        return x.id == itineraryDetail.id;
      });
      if (getIndex > -1) {
        //if the index is greater than -1 then the item exists
        state.tripDetails.tripItinerary.splice(getIndex, 1, itineraryDetail);
      } else {
        //else the item does not exist
        state.tripDetails.tripItinerary.splice(
          itineraryDetail.id,
          0,
          itineraryDetail
        );
      }
    },

    removeItinerary({ state, commit }, id) {
      const data = state.tripDetails.tripItinerary;
      const getIndex = data.findIndex((x) => {
        return x.id === id;
      });
      commit("REMOVE_AN_ITINERARY", getIndex);
    },

    removeAccommodation({ state, commit }, id) {
      const data = state.tripDetails.tripAccommodationDetails;
      const getIndex = data.findIndex((x) => x.id === id);
      if (getIndex > -1) {
        commit("REMOVE_AN_ACCOMMODATION", getIndex);
      }
    },

    resetAccommodationDetails({ commit }) {
      commit("UNSET_ACCOMMODATION_DETAILS");
    },

    resetItineraryDetails({ commit }) {
      commit("UNSET_ITINERARY_DETAILS");
    },

    setTripDetails({ commit }, tripDetails) {
      commit("SET_TRIP_DETAILS", tripDetails);
    },

    async getTripRequestDetails({ dispatch, commit }, requestId) {
      commit("SET_IS_LOADING_DATA", true);
      commit("SET_HAS_ALREADY_SAVED_OFFER", false);
      api
        .getAll([
          `/single-trip-request/${requestId}`,
          `/single-offer/${requestId}`,
          `/offer-draft/${requestId}`,
          `/get-trip-requested-changes/${requestId}`,
        ])
        .then((response) => {
          let singleTripResponseData = response[0].data.tripRequest;
          let singleOfferData = null;
          if (singleTripResponseData.tripRequestStatus === "draft") {
            singleOfferData = response[2].data.offerDraft;
          } else {
            singleOfferData = response[1].data.offer;
            console.log("Offer data", singleOfferData);
          }

          //since single offer is used interchangeably when creating an offer and offer draft we need to save the original offer id
          if (response[1].data.offer) {
            singleOfferData.originalOfferId = response[1].data.offer.id;
          }

          commit(
            "SET_SHOW_OFFER_SUMMARY",
            !["open", "draft"].includes(
              singleTripResponseData.tripRequestStatus
            )
          );
          commit("SET_HAS_ALREADY_SAVED_OFFER", !!response[1].data.offer);
          let requestedChangesResponseData = [];
          if (response[3].data.requestedChanges) {
            requestedChangesResponseData = response[3].data.requestedChanges;
          }

          if (
            singleTripResponseData.tripDestination ===
            "Democratic Republic of Congo"
          ) {
            singleTripResponseData.tripDestination =
              "Democratic Republic of the Congo";
          }

          let destination = singleTripResponseData.tripDestination;

          singleTripResponseData.tripRequestId = singleTripResponseData.id;
          singleTripResponseData.tripSingleRoomPrice = "";
          singleTripResponseData.tripInclusions = "";
          singleTripResponseData.tripExclusions = "";
          singleTripResponseData.tripAccommodationDetails = [];
          singleTripResponseData.tripItinerary = [];
          singleTripResponseData.originalAccommodation =
            singleTripResponseData.tripAccommodation;
          singleTripResponseData.originalExperiences =
            singleTripResponseData.tripExperiences;
          singleTripResponseData.tripFullRefundDays = "";
          singleTripResponseData.tripPartialRefund = "";
          singleTripResponseData.tripPartialRefundDays = "";
          singleTripResponseData.tripNoRefundDays = "";
          singleTripResponseData.tripDays = 0;

          //check to see if trip dates contain  week
          const regex = new RegExp(["week", "semaines"].join("|"), "i");
          singleTripResponseData.tripDateKnown = !regex.test(
            singleTripResponseData.tripDates
          );

          singleTripResponseData.tripTitle = "";
          singleTripResponseData.tripPricePerAdult = "";
          singleTripResponseData.tripPricePerChild = "";
          singleTripResponseData.tripTotalPrice = 0;
          singleTripResponseData.tripEarningFees = 0;

          if (singleOfferData) {
            //if singleOfferData.tripDateKnown is available true else false
            singleOfferData.tripDateKnown =
              !!singleOfferData.tripDateKnown || !singleOfferData;

            //parse the accommodation
            singleOfferData.tripAccommodationDetails = JSON.parse(
              singleOfferData.tripAccommodation
            );

            //parse the itenerary

            singleOfferData.tripItinerary = JSON.parse(
              singleOfferData.tripItinerary
            );

            if (
              singleOfferData.tripDateKnown &&
              singleOfferData.tripDates &&
              (singleOfferData.tripDates.includes("/") ||
                singleOfferData.tripDates.includes(","))
            ) {
              singleOfferData.tripDates = reformatDatesToIso(
                singleOfferData.tripDates
              );
            }

            //parsing the trip agency from a string to an int array
            if (singleOfferData.tripAgencies) {
              if (typeof singleOfferData.tripAgencies === "string") {
                singleOfferData.tripAgencies = singleOfferData.tripAgencies
                  .split(",")
                  .map((x) => parseInt(x));
              } else if (typeof singleOfferData.tripAgencies === "object") {
                singleOfferData.tripAgencies = singleOfferData.tripAgencies.map(
                  (x) => parseInt(x)
                );
              }
            } else {
              singleOfferData.tripAgencies = [];
            }

            //inclusions
            if (singleOfferData.tripInclusions == null) {
              singleOfferData.tripInclusions = "";
            }

            //exclusions
            if (singleOfferData.tripExclusions == null) {
              singleOfferData.tripExclusions = "";
            }

            singleOfferData.tripBudget = singleTripResponseData.tripBudget;
            singleOfferData.tripAccompany =
              singleTripResponseData.tripAccompany;
            singleOfferData.tripChildrenAge =
              singleTripResponseData.tripChildrenAge;
            singleOfferData.tripConsultation =
              singleTripResponseData.tripConsultation;
            singleOfferData.tripDays = singleTripResponseData.tripDays;
            singleOfferData.tripDetails = singleTripResponseData.tripDetails;
            singleOfferData.tripGroupType =
              singleTripResponseData.tripGroupType;
            singleOfferData.tripLanguage = singleTripResponseData.tripLanguage;
            singleOfferData.tripMealPlan = singleTripResponseData.tripMealPlan;
            singleOfferData.tripReason = singleTripResponseData.tripReason;
            singleOfferData.tripRequestStatus =
              singleTripResponseData.tripRequestStatus;
            singleOfferData.userId = singleTripResponseData.userId;
            singleOfferData.originalAccommodation =
              singleTripResponseData.originalAccommodation;
            singleOfferData.originalExperiences =
              singleTripResponseData.originalExperiences;

            if (
              singleOfferData.tripDestination === "Democratic Republic of Congo"
            ) {
              singleOfferData.tripDestination =
                "Democratic Republic of the Congo";
            }
            commit("SET_TRIP_DETAILS", { ...singleOfferData });
            //setting the destination in case it is different
            destination = singleOfferData.tripDestination;
          } else {
            commit("SET_TRIP_DETAILS", { ...singleTripResponseData });
          }

          if (requestedChangesResponseData) {
            commit("SET_REQUESTED_CHANGES", requestedChangesResponseData);
          }
          dispatch("experiences/getExperiences", destination, { root: true });
          commit("SET_IS_LOADING_DATA", false);
          commit("SET_SHOW_UNSAVED_CHANGES_WARNING", true);
        })
        .catch(() => {
          commit("SET_IS_LOADING_DATA", false);
        });
    },

    getExchangeRates({ commit }) {
      api.get("/ta-exchange-rates").then(({ data }) => {
        console.log(data);
        commit("SET_EXCHANGE_RATES", data.rates);
      });
    },
    async submitOffer({ commit, state }, saveType = "publish") {
      commit("SET_SAVING_RESPONSE_STATUS", null);
      commit("SET_IS_SUBMITTING_OFFER", true);
      let url = "";

      function compare(a, b) {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      }

      if (saveType === "draft") {
        url = "/save-offer-draft";
        commit("SET_LOADING_MESSAGE", "Saving to drafts");
      } else {
        if (state.hasAlreadySavedOffer) {
          url = "/update-offer";
        } else {
          url = "/save-new-offer";
        }
        commit("SET_LOADING_MESSAGE", "Updating offer");
      }

      const dataToSubmit = {
        tripAccommodation: JSON.stringify(
          state.tripDetails.tripAccommodationDetails.sort(compare)
        ),
        tripAdults: state.tripDetails.tripAdults,
        tripAgencies: state.tripDetails.tripAgencies
          ? state.tripDetails.tripAgencies.toString()
          : "",
        tripChildren: state.tripDetails.tripChildren,
        tripDateKnown: state.tripDetails.tripDateKnown,
        tripDates: state.tripDetails.tripDates,
        tripDestination: state.tripDetails.tripDestination,
        tripEarningFees: state.tripDetails.tripEarningFees,
        tripEmail: state.tripDetails.tripEmail,
        tripExclusions: state.tripDetails.tripExclusions,
        tripFirstname: state.tripDetails.tripFirstname,
        tripFullRefundDays: state.tripDetails.tripFullRefundDays,
        tripInclusions: state.tripDetails.tripInclusions,
        tripItinerary: JSON.stringify(
          state.tripDetails.tripItinerary.sort(compare)
        ),
        tripLastname: state.tripDetails.tripLastname,
        tripNoRefundDays: state.tripDetails.tripNoRefundDays,
        tripPartialRefund: state.tripDetails.tripPartialRefund,
        tripPartialRefundDays: state.tripDetails.tripPartialRefundDays,
        tripPhone: state.tripDetails.tripPhone,
        tripRequestId: state.tripDetails.tripRequestId,
        tripSalutation: state.tripDetails.tripSalutation,
        tripSingleRooms: state.tripDetails.tripSingleRooms,
        tripTitle: state.tripDetails.tripTitle,

        baseTripCurrency: state.tripDetails.baseTripCurrency,
        baseTripPricePerAdult: state.tripDetails.baseTripPricePerAdult,
        baseTripPricePerChild: state.tripDetails.baseTripPricePerChild,
        baseTripTotalPrice: state.tripDetails.baseTripTotalPrice,
        baseTripSingleRoomPrice: state.tripDetails.baseTripSingleRoomPrice,

        tripCurrency: state.tripDetails.tripCurrency,
        tripPricePerAdult: state.tripDetails.tripPricePerAdult,
        tripPricePerChild: state.tripDetails.tripPricePerChild,
        tripTotalPrice: state.tripDetails.tripTotalPrice,
        tripSingleRoomPrice: state.tripDetails.tripSingleRoomPrice,
      };

      if (state.tripDetails.id) {
        dataToSubmit.offerId = state.tripDetails.id;
      }

      //setting the offer id to its original if there is an existing offer when you are not saving as draft
      if (state.hasAlreadySavedOffer && saveType !== "draft") {
        dataToSubmit.offerId = state.tripDetails.originalOfferId;
      }

      // this.disableSubmitButton = true;
      api
        .post(url, dataToSubmit)
        .then(({ data }) => {
          commit("SET_SAVING_RESPONSE_MESSAGE", data.message);
          commit("SET_SAVING_RESPONSE_STATUS", data.response);
          commit("SET_IS_SUBMITTING_OFFER", false);
          commit("SET_SHOW_UNSAVED_CHANGES_WARNING", false);
          // SET_SHOULD_REDIRECT_AFTER_OFFER_SAVE to false when we are not saving as draft
          commit("SET_SHOULD_REDIRECT_AFTER_OFFER_SAVE", saveType !== "draft");
        })
        .catch((error) => {
          commit("SET_SAVING_RESPONSE_MESSAGE", error.response.data.message);
          commit("SET_SAVING_RESPONSE_STATUS", "failed");
          commit("SET_IS_SUBMITTING_OFFER", false);
          commit("SET_SHOULD_REDIRECT_AFTER_OFFER_SAVE", false);
          commit("SET_SHOW_UNSAVED_CHANGES_WARNING", true);
        });
    },

    async dateChanged({ commit }) {
      commit("UNSET_ACCOMMODATION_DETAILS");
      commit("UNSET_ITINERARY_DETAILS");
    },

    async cancelOffer({ commit }, requestID) {
      commit("SET_IS_LOADING_DATA", false);
      return await api.post(`/cancel-offer/${requestID}`);
    },
  },
};
