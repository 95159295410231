<template>
  <div class="requests flex bg-grey-light h-100vh overflow-hidden">
    <Sidebar/>
    <div class="self-start w-full relative h-full overflow-y-auto">
      <Navbar :showBackBtn="true" class="w-full hidden" route="/partners"/>
      <section class="px-4 md:px-12 mt-5 pb-20">
        <div class="flex justify-center">
          <TabButtons :buttons="mainTabItems"/>
        </div>

        <template v-if="isLoadingData">
          <Loading/>
        </template>

        <template v-else>
          <div id="details" class="mt-28" v-if="mainTabItems[0].isActive">
            <div class="wrapper">
              <div class="flex justify-between w-full">
                <div class="customer-details">
                  <div>
                    <img
                        :src="`${basePath}${partner.profilePhoto}`"
                        :alt="partner.name"
                        class="inline object-cover w-16 h-16 mr-2 rounded-full"
                    />
                  </div>
                </div>

                <div class="flex w-100">
                  <template v-if="isSubmittingData">
                    <Loading label="Submitting" show-label margin="my-0"/>
                  </template>

                  <template v-else>
                    <template v-if="partner.status != 'approved'">
                      <ButtonPrimary
                          text="Accept"
                          route="/partners/accept"
                          class="w-32 mr-2"
                          @click.native="acceptPartner"
                      />

                      <ButtonPrimary
                          text="Reject"
                          route="/partners/edit"
                          class="btn-reject"
                          @click.native="rejectPartner"
                      />
                    </template>

                    <div v-else>
                      <ButtonLink
                          text="Edit partner"
                          :route="`/partners/edit/${partner.id}`"
                          class="w-32"
                      />
                    </div>
                  </template>
                </div>
              </div>
              <Divider class="my-4"/>
              <div class="flex justify-between w-calc-full mt-3">
                <h6 class="font-sans text-black-lightest">Partner ID</h6>
                <p class="font-sans text-black-base mt-1">
                  {{ partner.id }}
                </p>
              </div>
              <Divider class="my-4"/>
              <div class="flex justify-between w-calc-full mt-3">
                <h6 class="font-sans text-black-lightest">First name</h6>
                <p class="font-sans text-black-base mt-1">
                  {{ partner.firstName }}
                </p>
              </div>
              <Divider class="my-4"/>
              <div class="flex justify-between w-calc-full mt-3">
                <h6 class="font-sans text-black-lightest">Last name</h6>
                <p class="font-sans text-black-base mt-1">
                  {{ partner.lastName }}
                </p>
              </div>

              <Divider class="my-4"/>
              <div class="flex justify-between w-calc-full mt-3">
                <h6 class="font-sans text-black-lightest">Position</h6>
                <p class="font-sans text-black-base mt-1">
                  {{ partner.position }}
                </p>
              </div>

              <Divider class="my-4"/>
              <div class="flex justify-between w-calc-full mt-3">
                <h6 class="font-sans text-black-lightest">Phone nr.</h6>
                <p class="font-sans text-black-base mt-1">
                  {{ partner.phone }}
                </p>
              </div>
              <Divider class="my-4"/>
              <div class="flex justify-between w-calc-full mt-3">
                <h6 class="font-sans text-black-lightest">Email</h6>
                <p class="font-sans text-black-base mt-1">
                  {{ partner.email }}
                </p>
              </div>

              <Divider class="my-4"/>
              <div class="flex justify-between w-calc-full mt-3">
                <h6 class="font-sans text-black-lightest">
                  Uploaded Passport or ID
                </h6>
                <p class="font-sans text-black-base mt-1">
                  <a download :href="`${basePath}${partner.identification}`">
                    Click here to download</a>
                </p>
              </div>

              <Divider class="my-4"/>

              <div>
                <p class="font-sans text-black-base font-semibold text-xl mb-4">
                  Company Details
                </p>
              </div>
              <div class="flex justify-between w-calc-full mt-3">
                <h6 class="font-sans text-black-lightest">Company Name</h6>
                <p class="font-sans text-black-base mt-1">
                  {{ partner.companyName }}
                </p>
              </div>
              <Divider class="my-4"/>
              <div class="flex justify-between w-calc-full mt-3">
                <h6 class="font-sans text-black-lightest">Company Website</h6>
                <p class="font-sans text-black-base mt-1">
                  {{ partner.website }}
                </p>
              </div>
              <Divider class="my-4"/>
              <div class="flex justify-between w-calc-full mt-3">
                <h6 class="font-sans text-black-lightest">Company Email</h6>
                <p class="font-sans text-black-base mt-1">
                  {{ partner.companyEmail }}
                </p>
              </div>
              <Divider class="my-4"/>
              <div class="flex justify-between w-calc-full mt-3">
                <h6 class="font-sans text-black-lightest">Company Location</h6>
                <p class="font-sans text-black-base mt-1">
                  {{ partner.companyLocation }}
                </p>
              </div>

              <Divider class="my-4"/>
              <div class="flex justify-between w-calc-full mt-3">
                <h6 class="font-sans text-black-lightest">
                  Company Street & House Number
                </h6>
                <p class="font-sans text-black-base mt-1">
                  {{ partner.companyStreet }}
                </p>
              </div>

              <Divider class="my-4"/>
              <div class="flex justify-between w-calc-full mt-3">
                <h6 class="font-sans text-black-lightest">Company ZIP Code</h6>
                <p class="font-sans text-black-base mt-1">
                  {{ partner.companyZipCode }}
                </p>
              </div>

              <Divider class="my-4"/>
              <div class="flex justify-between w-calc-full mt-3">
                <h6 class="font-sans text-black-lightest">
                  Uploaded Company Document
                </h6>
                <p class="font-sans text-black-base mt-1">
                  <a
                      download
                      :href="`${basePath}${partner.companyIdentification}`">
                    Click here to download</a>
                </p>
              </div>
              <template v-if="partner.createdAt">
                <Divider class="my-4"/>
                <div class="flex justify-between w-calc-full mt-3">
                  <h6 class="font-sans text-black-lightest">Created At</h6>
                  <p class="font-sans text-black-base mt-1">
                    <timeago
                        :datetime="partner.createdAt"
                        :auto-update="60"
                    ></timeago>
                  </p>
                </div>
              </template>

              <Divider class="my-4"/>
              <div>
                <p class="font-sans text-black-base font-semibold text-xl mb-4">
                  Company Operations
                </p>
              </div>
              <div class="flex justify-between w-calc-full mt-3">
                <h6 class="font-sans text-black-lightest">
                  Destination Management Company
                </h6>
                <p class="font-sans text-black-base mt-1">
                  {{ partner.dmc ? "Yes" : "No" }}
                </p>
              </div>

              <Divider class="my-4"/>

              <div class="flex justify-between w-calc-full mt-3">
                <h6 class="font-sans text-black-lightest">
                  Has Travel Agency License
                </h6>
                <p class="font-sans text-black-base mt-1">
                  {{ partner.license ? "Yes" : "No" }}
                </p>
              </div>

              <Divider class="my-4"/>

              <div class="flex justify-between w-calc-full mt-3">
                <h6 class="font-sans text-black-lightest">
                  Has Public Liability Insurance
                </h6>
                <p class="font-sans text-black-base mt-1">
                  {{ partner.insurance ? "Yes" : "No" }}
                </p>
              </div>

              <Divider class="my-4"/>
              <div class="flex justify-between w-calc-full mt-3">
                <h6 class="font-sans text-black-lightest">Start Year</h6>
                <p class="font-sans text-black-base mt-1">
                  {{ partner.startYear }}
                </p>
              </div>
            </div>
          </div>
          <div id="requests" class="mt-28" v-if="mainTabItems[1].isActive">
            <template v-if="requests">
              <div class="" v-if="requests.length > 0">
                <Table
                    :showId="false"
                    :columns="[
                    'Destination',
                    'Reference Nr.',
                    'Name',
                    'Request value',
                    'Date',
                  ]"
                >
                  <div
                      class="t-row"
                      v-for="(request, index) in requests"
                      :key="index"
                  >
                    <div @click.prevent="selectRequest(index)" class="row-mb">
                      <div class="w-full">
                        <div class="row-mb-header">
                          <timeago
                              class="row-mb-date"
                              :datetime="request.createdAt"
                              :auto-update="60"
                          >
                          </timeago>
                        </div>
                        <div class="row-mb-name">
                          {{ request.tripFirstname }}
                          {{ request.tripLastname }}
                        </div>
                        <div class="row-mb-text">
                          {{ request.tripDestination }}
                        </div>
                        <div class="row-mb-text">{{ tripTotalPrice(request) }} offer value</div>
                      </div>
                      <div class="row-mb-icon-container">
                        <i
                            class="bx bx-chevron-right bx-sm absolute bottom-0"
                        ></i>
                      </div>
                    </div>


                    <router-link
                        :to="{
                          name: 'PartnerRequest',
                          params: { partnerId: partnerID,  requestId: request.id },
                        }"
                    >
                      <div class="row-wrap">
                        <div class="table-column table-column-sb">
                          {{ request.tripDestination }}
                        </div>

                        <div class="table-column">
                          {{ request.id }}
                        </div>

                        <div class="table-column">
                          {{ request.tripFirstname }} {{ request.tripLastname }}
                        </div>

                        <div class="table-column">
                          {{ tripTotalPrice(request) }}
                        </div>

                        <template>
                          <div class="table-column" v-if="request.createdAt">
                            <timeago
                                :datetime="request.createdAt"
                                :auto-update="60"
                            ></timeago>
                          </div>
                        </template>
                      </div>

                    </router-link>
                  </div>
                </Table>
              </div>
            </template>
            <template v-else>
              <div class="empty-state w-full">
                <figure
                    class="max-w-lg relative left-1/2 transform -translate-x-1/2">
                  <img
                      class="w-full h-full object-cover"
                      src="@/assets/img/empty-states/no-change-requests.svg"
                      alt="Empty State"
                  />
                </figure>
                <h3
                    class="
                    font-sans
                    text-black-base text-xl
                    font-semibold
                    text-center
                    mt-6
                  "
                >
                  No new requests yet
                </h3>
                <p class="font-sans text-black-lighter text-center">
                  There are no new requests from this customer yet.
                </p>
              </div>
            </template>
          </div>
        </template>
      </section>
    </div>

    <ButtonClose
        v-show="showSheet"
        @click.native="closeBottomSheet()"
        class="fixed z-20 right-3 top-10"
    />
    <BackgroundLayer
        @click.native="closeBottomSheet()"
        v-show="showOverlay"
        class="fixed z-10 top-0"
    />
    <RequestSheet
        id="requestsheet"
        :showCloseButton="false"
        :hasExtras="true"
        :request="selectedRequest"
        :changeRequests="changeRequests"
        :class="[
        'h-90vh fixed w-full z-10 transition-all duration-700 ease-in-out',
        requestSheetPosition,
      ]"
    />
  </div>
</template>

<script>
import ButtonLink from "@/components/Buttons/ButtonLink";
import BackgroundLayer from "@/components/Layout/BackgroundLayer";
import Divider from "@/components/Layout/Divider";
import Navbar from "@/components/Layout/Navbar";
import Sidebar from "@/components/Layout/Sidebar/Sidebar";
import Table from "@/components/Table/Table";
import TabButtons from "@/components/Tabs/TabButtons";
import RequestSheet from "@/components/Sheets/RequestSheet";
import ButtonPrimary from "@/components/Buttons/ButtonPrimary";
import Loading from "@/components/Preloaders/Loading";
import {mapGetters, mapActions} from "vuex";
import ButtonClose from "../../components/Buttons/ButtonClose";

export default {
  name: "Partner",
  components: {
    ButtonClose,
    Loading,
    ButtonLink,
    BackgroundLayer,
    Divider,
    Navbar,
    Sidebar,
    Table,
    TabButtons,
    RequestSheet,
    ButtonPrimary,
  },
  data() {
    return {
      mainTabItems: [
        {
          name: "Details",
          isActive: true,
        },
        {
          name: "Offers",
          isActive: false,
        },
      ],
      requestSheetPosition: "-bottom-full",
      showOverlay: false,
      showSheet: false,
      accommodation: "Yes",
      active: "details",
      tabItems: [
        {
          name: "Request details",
          isActive: true,
          textColor: "text-black-base",
        },
        {
          name: "Requested changes",
          isActive: false,
          textColor: "text-grey-dark",
        },
      ],
      selectedRequest: {},
      showBottomSheet: false,
      pageIsLoading: true,
      isLoading: false,
    };
  },
  methods: {
    ...mapActions({
      getSelectedPartnerAndOffers: "partners/getSelectedPartnerAndOffers",
      getSelectedTripRequestRequestedChanges:
          "requests/getSelectedTripRequestRequestedChanges",
      acceptNewPartner: "partners/acceptNewPartner",
      rejectNewPartner: "partners/rejectNewPartner",
    }),
    tripTotalPrice(request) {
      return this.formatNumber(
          request.tripTotalPrice,
          request.tripCurrency,
          "en-US"
      );
    },
    acceptPartner() {
      this.acceptNewPartner(this.$route.params.id).then(({data}) => {

        this.$notify({
          type: "success",
          title: "Success",
          text: data.message,
        });
        //redirect
        this.$router.push({name: 'Partners'})

      }).catch(error => {
        this.$notify({
          type: "error",
          title: `${error.message}`,
          text: `${error.response.data.message}`,
        });
      })

    },
    rejectPartner() {
      this.rejectNewPartner(this.$route.params.id).then(({data}) => {
        this.$notify({
          type: "success",
          title: "Success",
          text: data.message,
        });
        //redirect
        this.$router.push({name: 'Partners'})

      }).catch(error => {
        this.$notify({
          type: "error",
          title: `${error.message}`,
          text: `${error.response.data.message}`,
        });
      });

    },
    formatNumber(value, currency, region) {
      value = new Intl.NumberFormat(region, {
        style: "currency",
        currency: currency,
      }).format(value);
      return value;
    },
    closeSheets() {
      this.requestSheetPosition = "-bottom-full";
      this.showDialog = this.showOverlay = this.showSheet = false;
    },
    openBottomSheet() {
      this.requestSheetPosition = "bottom-0";
      this.showOverlay = this.showSheet = true;
      //Scroll content of request sheet to the top
      let container = this.$el.querySelector("#requestsheet");
      container.scrollTo(0, 0);
    },
    selectRequest(index) {
      this.selectedRequest = this.requests[index];
      this.getSelectedTripRequestRequestedChanges(
          this.selectedRequest.tripRequestId
      );
      this.openBottomSheet();
    },
  },

  beforeMount() {
    this.getSelectedPartnerAndOffers(this.$route.params.id);
  },

  computed: {
    ...mapGetters({
      partner: "partners/selectedPartner",
      requests: "partners/selectedPartnerOffers",
      isLoadingData: "partners/isLoadingData",
      isSubmittingData: "partners/isSubmittingData",
      changeRequests: "requests/selectedTripRequestRequestedChanges",
    }),
    partnerID() {
      return this.$route.params.id;
    },
    showEmptyState() {
      let boolean = this.requests.length > 0;
      return !boolean;
    },
    basePath() {
      return process.env.VUE_APP_FILE_BASE_PATH;
    },
  },
};
</script>

<style scoped>
.row-wrap {
  @apply cursor-pointer mt-1 h-14 px-6 w-full bg-white shadow-sm hover:shadow-md rounded;
  @apply hidden sm:grid grid-flow-col grid-cols-12 gap-4;
  @apply transition duration-300 ease-in-out;
}

.wrapper {
  @apply max-w-3xl relative left-1/2 transform -translate-x-1/2;
}

.table-column {
  @apply text-sm font-sans text-black-base font-normal flex items-center col-span-2;
}

.table-column-sb {
  @apply font-semibold;
}

.btn-reject {
  @apply w-32 bg-white shadow-sm border border-grey-base text-red-base;
  @apply hover:bg-red-base transition-all ease-out duration-300;
  @apply hover:text-white;
}

.t-row {
  @apply relative w-full cursor-pointer grid;
  @apply w-full mb-4;
  @apply bg-white shadow-sm;
  @apply transition duration-300 rounded;
}

.row-mb {
  @apply sm:hidden w-full flex justify-between p-4;
  @apply bg-white rounded;
}

.row-mb-header {
  @apply inline-flex items-center;
}

.row-mb-date {
  @apply mr-3 text-xs text-black-lighter;
}

.row-mb-name {
  @apply font-semibold leading-8;
}

.row-mb-reference {
  @apply text-sm text-black-lighter leading-5;
}

.row-mb-text {
  @apply text-sm leading-5;
}

.row-mb-icon-container {
  @apply relative w-7 h-full;
}

.sheet {
  @apply h-100vh fixed w-full z-10 transition-all duration-700 ease-in-out;
}
</style>