<template>
  <div class="flex overflow-hidden bg-grey-light h-100vh">
    <BackgroundLayer @click.native="$emit('overlayClicked')" v-if="showOverlay" class="fixed z-30 top-0 left-0"/>
    <Sidebar/>
    <div class="relative self-start w-full h-full overflow-y-auto">
      <Navbar class="w-full" :showBackBtn="showBackBtn" :showMenuBtn="showBackBtn"/>

      <section class="px-4 pt-8 pb-12 mt-5 md:px-12 min-h-screen">
        <slot name="title">
          <div class="flex justify-between items-center  mb-8" v-if="showTitle">
            <h1 class="font-sans font-bold text-black-base text-xl sm:text-2xl">{{ title }}</h1>
            <slot name="actions"/>
          </div>
        </slot>
        <slot/>
      </section>
    </div>

    <slot name="dialogs"/>
  </div>
</template>

<script>

import Sidebar from "./Sidebar/Sidebar";
import BackgroundLayer from "./BackgroundLayer";
import Navbar from "./Navbar";

export default {
  name: "PageComponent",
  components: {
    BackgroundLayer,
    Navbar,
    Sidebar
  },
  props: {
    title: String,
    showTitle: {
      type:Boolean,
      default:true,
    },
    showOverlay: Boolean,
    showBackBtn:Boolean,
    showMenuBtn:{
      type:Boolean,
      default: true
    },

  },
  metaInfo() {
    return {
      title: this.title,
    }
  }
}
</script>

<style scoped>

</style>