//this file contains the api wrapper for all axios requests
import axios from "axios";
import { camelizeKeys, decamelize } from 'humps';
import Router from "../router";

const api = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API,
})

api.interceptors.response.use((response) => {
    if (
        response.data &&
        response.headers['content-type'] === 'application/json'
    ) {
        response.data = camelizeKeys(response.data);
    }
    return response;
});

api.interceptors.response.use((response) => {
    return response;
}, function (error) {
    if (error.response.status === 404) {
        Router.push({
            name: 'NotFound'
        });
    }
    return Promise.reject(error.response);
});


api.interceptors.request.use(
    (config) => {
        let token = localStorage.getItem('token')
        if (token) {
            config.headers['Authorization'] = `Bearer ${ token }`
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
);


const decamelizeThatDontBreaksFile = (object) => {
    if (object && !(object instanceof File)) {
        if (object instanceof Array) {
            return object.map(item => decamelizeThatDontBreaksFile(item))
        }
        if (object instanceof FormData) {
            let formData = new FormData();
            for (const [key, value] of object.entries()) {
                formData.append(decamelize(key), value)
            }
            return formData
        }
        if (typeof object === 'object') {
            return Object.keys(object).reduce((acc, next) => ({
                ...acc,
                [decamelize(next)]: decamelizeThatDontBreaksFile(object[next]),
            }), {});
        }
    }
    return object
};


api.interceptors.request.use((config ) => {
    const newConfig = { ...config };
    newConfig.url = `${config.url}`;

    if (config.params) {
        newConfig.params = decamelizeThatDontBreaksFile(config.params);
    }

    if (config.data) {
        newConfig.data = decamelizeThatDontBreaksFile(config.data);
    }

    return newConfig;
});

api.getAll = async (requestPathArray) => {
    let newArray = requestPathArray.map((x) => {
        return api.get(x);
    });
    return await Promise.all(newArray);
}

export default api;
