<template>
  <button
    :type="type"
    :disabled="disAttribute"
    :class="['btn-primary', styleMode]"
  >
    <slot> {{ text }}</slot>

  </button>
</template>
<script>
export default {
  name: "ButtonPrimary",
  data() {
    return {};
  },
  props: {
    text: {
      type: String,
      default: "Button",
    },
    disAttribute: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "button",
    },
    mode: {
      type: String,
      default: "light",
    },
  },
  computed: {
    styleMode() {
      if (this.mode == "light") return "btn-light";
      return "btn-dark";
    },
  },
};
</script>
<style scoped>
.btn-primary {
  @apply focus:outline-none focus:scale-95 focus:ring-offset-2 focus:ring-2;
  @apply text-xs xs:text-base font-sans;
  @apply duration-300 ease-in-out transition;
  @apply text-white shadow rounded border h-12;
}

.btn-light {
  @apply hover:bg-teal-dark focus:ring-teal-darker focus:border-teal-base;
  @apply bg-teal-base border-teal-dark;
}

.btn-dark {
  @apply hover:bg-black-darker focus:ring-black-darker focus:border-black-base;
  @apply bg-black-base border-black-darker;
}
</style>