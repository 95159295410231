<template>
  <PageComponent title="Group Tours">
    <template #actions>
      <ButtonLink text="Create a group tour" route="/group-tours/create"/>
    </template>
    <div>

      <div class="w-full relative overflow-hidden mb-8">
        <Tab :tabItems="tabSections" class="tab-mb"/>
      </div>

      <template v-if="isLoadingData">
        <Loading/>
      </template>

      <template v-else>
        <GroupTourTable
            :header="tableHeader"
            :groupTours="groupTours"
            v-if="showTours"
        />
        <GroupTourBookingsTable
            :bookings="groupTourBookings"
            v-if="showBookings"
        />
      </template>

    </div>
  </PageComponent>
</template>

<script>
import {PageComponent, ButtonLink, GroupTourTable, GroupTourBookingsTable, Tab} from "@/components";
import Loading from "@/components/Preloaders/Loading";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "GroupTours",
  components: {
    PageComponent,
    ButtonLink,
    GroupTourTable,
    Loading,
    GroupTourBookingsTable,
    Tab
  },
  data() {
    return {
      tabSections: [
        {name: "Tours", isActive: true, textColor: "text-black-base"},
        {name: "All Bookings", isActive: false, textColor: "text-grey-dark"},
      ],
      tableHeader: [
        "Tour ID",
        "Destination",
        "Title",
        "Created At",
      ],

    }
  },
  beforeMount() {
    this.getGroupTours();
  },
  methods: {
    ...mapActions({
      getGroupTours: 'groupTours/getGroupTours',
    })
  },
  computed: {
    ...mapGetters({
      groupTours: 'groupTours/groupTours',
      groupTourBookings: 'groupTours/groupToursBookings',
      isLoadingData: 'groupTours/isLoadingData'
    }),

    showTours() {
      return this.tabSections[0].isActive;
    },
    showBookings() {
      return this.tabSections[1].isActive;
    },
  }
}
</script>


