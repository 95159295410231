<template>
  <div id="table" class="relative">

    <div class="empty-state w-full" v-if="showEmptyState">
      <figure class="max-w-lg relative left-1/2 transform -translate-x-1/2">
        <img
            class="w-full h-full object-cover"
            src="@/assets/img/empty-states/no-change-requests.svg"
            alt="Empty State"
        />
      </figure>
      <h3
          class="font-sans text-black-base text-xl font-semibold text-center mt-6"
      >
        No tours yet
      </h3>
      <p class="font-sans text-black-lighter text-center">
        There are no tours under this section yet.
      </p>
    </div>

    <div v-else>
      <div class="t-header">
        <div
            v-for="(title, index) in header"
            :key="index"
            :class="[
            'text-xs uppercase font-sans text-black-lighter',
            index == 5 ? 'col-span-1' : 'col-span-2',
          ]"
        >
          {{ title }}
        </div>
      </div>

      <GroupToursTableRow
          class="mb-2"
          v-for="(tour, index) in groupTours"
          :key="index"
          :tour="tour"
          :showStatus="true"
      />
    </div>
  </div>
</template>
<script>
import GroupToursTableRow from "./GroupTourTableRow";

export default {
  name: "GroupToursTable",
  components: {
    GroupToursTableRow,
  },
  props: {
    header: {
      type: Array,
      default: () => [
        "Title 1",
        "Title 2",
        "Title 3",
        "Title 4",
        "Title 5",
        "Title 6",
      ],
    },
    groupTours: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object,
      required: false
    },
  },
  computed: {
    showEmptyState() {
      let boolean = this.groupTours.length > 0;
      return !boolean;
    },
  },
};
</script>

<style>
.table-column {
  @apply text-sm font-sans text-black-base font-semibold flex items-center col-span-2;
}

.t-header {
  @apply hidden sm:grid px-6 grid-flow-col grid-cols-12 gap-4 mt-8 pb-3 w-full;
}
</style>