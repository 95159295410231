import api from "@/api";

export default {
    namespaced: true,
    state: {
        customers: [],
        isLoadingData: false,
        selectedCustomer: {},
        selectedCustomerTripRequests: [],
    },
    getters: {
        customers(state) {
            return state.customers
        },
        isLoadingData(state) {
            return state.isLoadingData
        },
        selectedCustomer(state) {
            return state.selectedCustomer
        },
        selectedCustomerTripRequests(state){
            return state.selectedCustomerTripRequests
        }
    },
    mutations: {
        SET_CUSTOMERS(state, value) {
            state.customers = value;
        },

        SET_SELECTED_CUSTOMER(state, value) {
            state.selectedCustomer = value;
        },

        SET_SELECTED_CUSTOMER_TRIP_REQUESTS(state, value) {

            state.selectedCustomerTripRequests = value;
        },

        SET_IS_LOADING_DATA(state, value) {
            state.isLoadingData = value
        }
    },
    actions: {
        getCustomers({commit}) {
            commit('SET_IS_LOADING_DATA', true);
            api.get("/all-customers")
                .then(({data}) => {
                    commit('SET_CUSTOMERS', data.customers);
                    commit('SET_IS_LOADING_DATA', false);
                })
                .catch(() => {
                    commit('SET_IS_LOADING_DATA', false);
                });
        },

        getSelectedCustomerAndTripRequests({commit}, customerID) {
            commit('SET_IS_LOADING_DATA', true);

            api.getAll([
                    `/single-customer/${customerID}`,
                    `/single-customer/${customerID}/trip_requests`,
                ])
                .then((response) => {
                    commit('SET_SELECTED_CUSTOMER', response[0].data.customer);
                    commit('SET_SELECTED_CUSTOMER_TRIP_REQUESTS', response[1].data.tripRequests);
                    commit('SET_IS_LOADING_DATA', false);
                })
                .catch(() => {
                    commit('SET_IS_LOADING_DATA', false);
                });
        }
    }
}