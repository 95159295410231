<template>
  <div id="summarysheet" class="sheet">
    <nav class="navbar">
      <ButtonClose @click.native="$emit('closeSheet')" class="btn-close"/>
    </nav>
    <TourSummary :tourDetails="tourDetails" :dates="dates" :isLastStep="isLastStep"
                 @submitClicked="$emit('submitClicked')" :isLoading="isLoading"/>
  </div>
</template>
<script>
import ButtonClose from "@/components/Buttons/ButtonClose";
import TourSummary from "@/components/GroupTours/TourSummary";

export default {
  name: "TourSummarySheet",
  components: {
    TourSummary,
    ButtonClose,
  },
  props: {
    dates: {
      type: String,
      required: false
    },
    tourDetails: {
      type: [Object,String],
      default: "Trip title",
    },
    isLastStep: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },

  },
};
</script>
<style scoped>
.sheet {
  @apply bg-white px-4 overflow-y-auto pb-20;
}

.navbar {
  @apply max-w-screen-sm fixed z-30 left-1/2 transform -translate-x-1/2 w-full py-4;
}

.btn-close {
  @apply absolute right-4 top-4;
}

</style>