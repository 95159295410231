<template>
  <div class="grid grid-flow-col  gap-4 w-full"
       :class="multiStepItems.length<=6?`grid-cols-${multiStepItems.length}`:'grid-cols-6' ">

    <template v-for="(item, index) in multiStepItems">
      <MultiStepItem
          :key="index"
          :linkName="item.linkName"
          :text="item.text"
          :index="index"
          :isActive="index == selectedItemIndex"
          :controlRouteFromParent="controlRouteFromParent"
          @redirectTo="$emit('redirectTo',$event)"
      />
    </template>
  </div>
</template>
<script>
import MultiStepItem from "@/components/Offer/Navbar/MultiStepItem";

export default {
  name: "MultiStepBar",
  components: {
    MultiStepItem,
  },
  data() {
    return {
      selectedItemIndex: 0,
    };
  },
  props: {
    controlRouteFromParent: {
      type: Boolean,
      default: false,
    },
    multiStepItems: {
      type: Array,
      default: () => [
        {
          text: "Customer details",
          name: "customer-details",
          linkName: "CustomerDetails",
          isActive: true,
          statusStyle:
              "bg-teal-dark text-white hover:bg-teal-base duration-300 transition ease-in-out",
        },
        {
          text: "Trip details",
          name: "trip-details",
          linkName: "TripDetails",
          isActive: false,
          statusStyle:
              "text-black-lightest border border-grey-dark hover:text-black-light hover:border-black-lightest duration-300 transition ease-in-out",
        },
        {
          text: "Itinerary",
          name: "itinerary",
          linkName: "Itinerary",
          isActive: false,
          statusStyle:
              "text-black-lightest border border-grey-dark hover:text-black-light hover:border-black-lightest duration-300 transition ease-in-out",
        },
        {
          text: "Accommodations",
          name: "accommodations",
          linkName: "Accommodations",
          isActive: false,
          statusStyle:
              "text-black-lightest border border-grey-dark hover:text-black-light hover:border-black-lightest duration-300 transition ease-in-out",
        },
        {
          text: "Pricing",
          name: "pricing",
          linkName: "Pricing",
          isActive: false,
          statusStyle:
              "text-black-lightest border border-grey-dark hover:text-black-light hover:border-black-lightest duration-300 transition ease-in-out",
        },
        {
          text: "Cancellation",
          name: "cancellation",
          linkName: "Cancellation",
          isActive: false,
          statusStyle:
              "text-black-lightest border border-grey-dark hover:text-black-light hover:border-black-lightest duration-300 transition ease-in-out",
        },
      ],
    },
    currentStepItemIndex: {
      type: Number,
      default: () => {
        return 0;
      },
    },
  },
  methods: {
    toggleMultiStepItem(itemIndex) {
      this.selectedItemIndex = itemIndex;
      for (let [index, multiStepItem] of this.multiStepItems.entries()) {
        multiStepItem.isActive = false;
        multiStepItem.statusStyle =
            "text-black-lightest border border-grey-dark hover:text-black-light hover:border-black-lightest";
        if (itemIndex == index) {
          multiStepItem.isActive = true;
          multiStepItem.statusStyle ="bg-teal-dark text-white hover:bg-teal-base ";
        }
      }
    },
  },

  watch: {
    currentStepItemIndex(newIndex) {
      this.selectedItemIndex = newIndex;
    },
  },
};
</script>

<style scoped>
.bar {
  @apply grid grid-flow-col grid-cols-6 gap-4 w-full ;
}

.active {
  @apply bg-black-base text-white hover:bg-black-light;
}

.inactive {
  @apply text-black-lightest border border-grey-dark hover:text-black-light hover:border-black-lightest;
}
</style>