<template>
  <div>
    <nav class="navbar">
      <div class="nav-wrapper">
        <button
            v-if="currentPage > 1"
            class="close-btn"
            type="button"
            @click="goToPreviousPage()"
        >
          <i class="bx bx-arrow-back"></i>
          <span class="close-btn-txt">Back</span>
        </button>

        <button
            v-else
            class="close-btn"
            type="button"
            @click="exitCreatePartnerForm"
        >
          <i class="bx bx-arrow-back"></i>
          <span class="close-btn-txt">Exit</span>
        </button>

        <ProgressIndicator
            :currentPage="currentPage"
            :totalPages="pages.length"
        />
      </div>
    </nav>
    <form action="" class="form" @submit="showData($event)">
      <template v-if="isLoadingData">
        <Loading/>
      </template>
      <template v-else>
        <div v-show="pages[0].show" class="form-page">
          <h2 class="form-page-title">
            Partner's personal details
          </h2>
          <p class="form-page-description">
            Let us know who is behind the company
          </p>
          <div class="form-page-container">
            <div class="form-input-container">
              <InputText
                  v-model="form.firstName"
                  class="field-half"
                  label="First name"
                  name="first-name"
                  placeholder="First name"
              />
              <InputText
                  v-model="form.lastName"
                  class="field-half"
                  label="Last name"
                  name="last-name"
                  placeholder="Last name"
              />
              <InputText
                  v-model="form.email"
                  class="field-full"
                  label="Email"
                  name="email"
                  placeholder="Email"
              />
                <InputPhone
                    v-model="form.phone"
                    :phoneNumber="form.phone"
                    @getObj="updateNumberObj($event)"
                    @input="updateNumber($event)"
                    @sliceZeros="updateNumber($event)"
                />

              <div v-if="!showPartnerIdentificationUploadBox" class="mt-6 w-full">
                <p>
                  <span class="input-label">Uploaded partners ID or passport</span>
                  <span class="input-description">This is the uploaded Identification</span>
                </p>
                <div class="img-preview">
                  <ImagePreview
                      :image="{
                    name: 'Partner ID',
                    path: `${basePath}${form.identification}`,
                  }"
                      class="img-preview-content"
                      @remove="unsetUploadedPartnerIdentification"
                  />
                </div>
              </div>

              <InputUpload
                  v-if="showPartnerIdentificationUploadBox"
                  v-model="form.identification"
                  :allowMultiple="false"
                  label="Upload a copy of the partners ID or passport"
                  name="personal-doc"
                  text="Only pdf, jpeg, jpg and png allowed (max 10 MB)."
                  title="Click to upload the partner's file"
              />
            </div>
          </div>
        </div>
        <div v-show="pages[1].show" class="form-page">
          <h2 class="form-page-title">
            Partner's Company details
          </h2>
          <p class="form-page-description">
            About the partner's local agency
          </p>
          <div class="form-page-container">
            <div class="form-input-container">
              <InputText
                  v-model="form.companyName"
                  class="field-half"
                  label="Legal name of the company"
                  name="company-name"
                  placeholder="Company name"
              />
              <InputText
                  v-model="form.position"
                  class="field-half"
                  label="Your position in the company"
                  name="position"
                  placeholder="CEO, Manager, Agent, etc."
              />

              <div class="w-full">
                <h4 class="form-page-section-title">
                  Company address
                </h4>
              </div>

              <InputText
                  v-model="form.companyLocation"
                  class="field-full"
                  label="Location"
                  name="location"
                  placeholder="Enter partners location"
              />

              <InputText
                  v-model="form.companyStreet"
                  class="field-half"
                  label="Street & housenr."
                  name="street"
                  placeholder="Street & housenr."
              />
              <InputText
                  v-model="form.companyZipCode"
                  class="field-half"
                  label="ZIP Code"
                  name="street"
                  placeholder="ZIP"
                  type="number"
              />

              <SelectDropdown
                  v-model="form.companyCountry"
                  :optionsList="$africanDestinations"
                  :selected="form.companyCountry"
                  :showLabel="true"
                  class="field-full"
                  description="Country of headquarters"
                  label="Country of headquarters"
              />

              <div v-if="!showCompanyIdentificationUploadBox" class="mt-6 w-full">
                <p>
                <span class="text-black-base block font-sans font-semibold"
                >Uploaded partners Company Documents</span
                >
                  <span class="text-black-lighter text-sm block font-sans mt-1"
                  >This is the uploaded company identification documents</span
                  >
                </p>
                <div class="img-preview">
                  <ImagePreview
                      :image="{
                    name: 'Company ID',
                    path: `${basePath}${form.companyIdentification}`,
                  }"
                      class="img-preview-content"
                      @remove="unsetUploadedCompanyIdentification"
                  />
                </div>
              </div>

              <InputUpload
                  v-if="showCompanyIdentificationUploadBox"
                  v-model="form.companyIdentification"
                  :allowMultiple="false"
                  class="w-full col-span-2"
                  label="Upload a document with the registered name of the partner's company and the address you entered (e.g. electricity bill, bank statement, certificate of incorporation etc.)"
                  name="company-doc"
                  text="Only pdf, jpeg, jpg and png allowed (max 10 MB)."
                  title="Click to upload the partner's file"
              />
            </div>
          </div>
        </div>
        <div v-show="pages[2].show" class="form-page">
          <h2 class="form-page-title">
            Operations
          </h2>
          <p class="form-page-description">
            Choose the options that best suit you
          </p>
          <div class="form-page-container">
            <div class="form-input-container">
              <div class="w-full mb-6">
                <p class="input-label">
                  Are you a DMC? (Destination Management Company)
                </p>
                <label class="mr-8">
                  <input v-model="form.dmc" :value="true" name="dmc" type="radio"/>
                  <span class="label ml-2">Yes</span>
                </label>
                <label>
                  <input
                      v-model="form.dmc"
                      :value="false"
                      name="dmc"
                      type="radio"
                  />
                  <span class="label ml-2">No</span>
                </label>
              </div>
              <div class="w-full mb-6">
                <p class="input-label">
                  Do you have a travel agency license?
                </p>

                <label class="mr-8">
                  <input
                      v-model="form.license"
                      :value="true"
                      name="license"
                      type="radio"
                  />
                  <span class="label ml-2">Yes</span>
                </label>

                <label>
                  <input
                      v-model="form.license"
                      :value="false"
                      name="license"
                      type="radio"
                  />
                  <span class="label ml-2">No</span>
                </label>
              </div>

              <div class="mb-6 w-full">
                <MultiSelect
                    label="Destination(s) in which you provide services for travelers"
                    placeholder="Select one or more countries"
                    class="block w-full"
                    :options="$africanDestinations"
                    :optionDisplayValue="(option)=>{return option.value}"
                    v-model="form.destinations"
                />
              </div>

              <div class="w-full mb-6">
                <p class="font-sans font-semibold mb-2 text-black-base">
                  Do you have a public liability insurance?
                </p>

                <label class="mr-8">
                  <input
                      v-model="form.insurance"
                      :value="true"
                      name="insurance"
                      type="radio"
                  />
                  <span class="label ml-2">Yes</span>
                </label>

                <label>
                  <input
                      v-model="form.insurance"
                      :value="false"
                      name="insurance"
                      type="radio"
                  />
                  <span class="label ml-2">No</span>
                </label>
              </div>

              <InputText
                  v-model="form.website"
                  class="field-full"
                  label="Website (optional)"
                  name="website"
                  placeholder="e.g. www.website.com"
              />

              <InputText
                  v-model="form.companyEmail"
                  class="field-full"
                  label="Company Email (optional)"
                  name="company-email"
                  placeholder="e.g. info@example.com"
              />

              <InputText
                  v-model="form.startYear"
                  class="field-full"
                  label="When did the partner's agency start its operations?"
                  name="operation-start"
                  placeholder="e.g. 12. June 1993"
              />

              <div class="w-full col-span-2">
                <p class="font-sans font-semibold mb-2 text-black-base mt-8">
                  In which languages do you offer the partner's services?
                </p>

                <template v-for="(language, index) in $officialLanguages">
                  <div :key="index" class="flex flex-wrap my-6">
                    <label class="flex items-center w-full">
                      <input
                          v-model="form.languages"
                          :value="language.value"
                          class="check-box-component form-checkbox"
                          type="checkbox"
                      />
                      <span class="check-box-text">{{ language.name }}</span>
                    </label>
                  </div>
                </template>
              </div>

              <div v-if="!showProfilePhotoUploadBox" class="mt-6 w-full">
                <p>
                <span class="text-black-base block font-sans font-semibold"
                >Uploaded Photo of partner</span
                >
                  <span class="text-black-lighter text-sm block font-sans mt-1"
                  >This is the image users will see</span
                  >
                </p>
                <div class="img-preview">
                  <ImagePreview
                      :image="{
                    name: 'Profile Photo',
                    path: `${basePath}${form.profilePhoto}`,
                  }"
                      class="img-preview-content"
                      @remove="unsetUploadedProfilePhoto"
                  />
                </div>
              </div>

              <InputUpload
                  v-if="showProfilePhotoUploadBox"
                  v-model="form.profilePhoto"
                  :allowMultiple="false"
                  :showDescription="true"
                  allowedFileTypes=".jpg, .png, .jpeg"
                  class="mt-3 w-full col-span-2"
                  description="Customers will see this photo, when booking through the partner's agency"
                  label="Upload a high quality photo of the partner"
                  name="photo"
                  text="Only pdf, jpeg, jpg and png allowed (max 10 MB)."
                  title="Click to upload the partner's file"
              />
            </div>
          </div>
        </div>
        <div class="bottom-nav">
          <div class="bottom-nav-wrapper">
            <div v-if="isSubmittingData" class="s:w-64 block ml-auto py-2">
              <Loading :showLabel="true" label="Updating Partner" margin="my-0"/>
            </div>
            <ButtonPrimary
                v-else
                :text="buttonText"
                class="s:w-64 block px-8 h-12 ml-auto"
                @click.native="goToNextPage()"
            />

          </div>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

import {
  ButtonPrimary,
  InputPhone,
  InputText,
  InputUpload,
  Loading,
  MultiSelect,
  ImagePreview,
  ProgressIndicator,
  SelectDropdown,
} from "@/components";

export default {
  name: "BusinessForm",
  components: {
    InputPhone,
    ButtonPrimary,
    InputText,
    InputUpload,
    SelectDropdown,
    MultiSelect,
    ProgressIndicator,
    ImagePreview,
    Loading,
  },
  data() {
    return {
      showProfilePhotoUploadBox: false,
      showPartnerIdentificationUploadBox: false,
      showCompanyIdentificationUploadBox: false,
      pages: [{show: true}, {show: false}, {show: false}],

      form: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        identification: "",
        companyName: "",
        position: "",
        companyCountry: "",
        companyAddress: "",
        companyStreet: "",
        companyLocation: "",
        companyZipCode: "",
        companyIdentification: "",
        companyEmail: "",
        dmc: "",
        destinations: [],
        license: "",
        insurance: "",
        website: "",
        startYear: "",
        languages: [],
        profilePhoto: "",
      },
    };
  },
  methods: {

    ...mapActions({
      editPartner: 'partners/editPartner',
      getSelectedPartner: 'partners/getSelectedPartner'
    }),
    updateNumberObj(obj) {
      this.form.phone = obj.dialCode + obj.phone;
    },
    updateNumber(value) {
      this.form.phone = value.dialCode + value.phone;
    },
    submitForm() {
      this.form.companyAddress =
          this.form.companyStreet + ',' + this.form.companyLocation + ',' + this.form.companyZipCode;

      let formData = new FormData();
      for (let key in this.form) {
        if (key === "identification") {
          if (typeof this.form.identification == "object") {
            formData.append(`identification`, this.form.identification);
          }
        } else if (key === "companyIdentification") {
          if (typeof this.form.companyIdentification == "object") {
            formData.append(
                `companyIdentification`,
                this.form.companyIdentification
            );
          }
        } else if (key === "profilePhoto") {
          if (typeof this.form.profilePhoto == "object") {
            formData.append(`profilePhoto`, this.form.profilePhoto);
          }
        } else if (key === "languages") {
          formData.append("languages", this.form.languages.toString());
        } else if (key === "dmc" || key === "license" || key === "insurance") {
          //change true or false to 1 and zero
          formData.append(key, this.form[key] === true ? 1 : 0);
        }else if (key === "destinations") {
          const selectedDestinations= this.$africanDestinations.reduce((container, currentItem)=>{
            if(this.form.destinations.includes (currentItem.id))
              container.push(currentItem.value)
            return container;
          },[])
          formData.append(key, selectedDestinations.toString());
        }  else {
          formData.append(key, this.form[key]);
        }
      }

      this.editPartner(formData)

    },
    exitCreatePartnerForm() {
      this.$router.go(-1);
    },
    showData(e) {
      e.preventDefault();
    },
    goToPreviousPage() {
      for (let [index, page] of this.pages.entries()) {
        if (page.show === true) {
          page.show = false;
          this.pages[index - 1].show = true;
          break;
        }
      }
    },
    goToNextPage() {
      if (this.currentPage === this.pages.length) {
        this.submitForm();
      }

      for (let [index, page] of this.pages.entries()) {
        if (page.show === true && index !== this.pages.length - 1) {
          page.show = false;
          this.pages[index + 1].show = true;
          break;
        }
      }
    },

    unsetUploadedProfilePhoto() {
      (this.form.profilePhoto = null),
          (this.showProfilePhotoUploadBox = !this.showProfilePhotoUploadBox);
    },

    unsetUploadedPartnerIdentification() {
      (this.form.identification = null),
          (this.showPartnerIdentificationUploadBox =
              !this.showPartnerIdentificationUploadBox);
    },

    unsetUploadedCompanyIdentification() {
      (this.form.companyIdentification = null),
          (this.showCompanyIdentificationUploadBox =
              !this.showCompanyIdentificationUploadBox);
    },
  },
  computed: {

    ...mapGetters({
      isSubmittingData: 'partners/isSubmittingData',
      isLoadingData: 'partners/isLoadingData',
      selectedPartner: 'partners/selectedPartner',
      submissionStatus: 'partners/submissionStatus',
      submissionStatusMessage: 'partners/submissionStatusMessage',
    }),
    basePath() {
      return process.env.VUE_APP_FILE_BASE_PATH;
    },
    currentPage() {
      let currentPage;
      for (let [index, page] of this.pages.entries()) {
        if (page.show === true) {
          currentPage = index + 1;
          break;
        }
      }
      return currentPage;
    },
    buttonText() {
      if (this.currentPage === this.pages.length) {
        return "Submit";
      }
      return "Continue";
    },
  },
  beforeMount() {
    this.getSelectedPartner(this.$route.params.id)
  },
  watch: {
    submissionStatus(newVal) {
      if (newVal === 'success') {
        this.$notify({
          type: "success",
          title: "Success",
          text: this.submissionStatusMessage,
        });
        this.$router.push({name: 'Partner', params: {id: this.selectedPartner.id}});
      } else {
        this.$notify({
          type: "error",
          title: "OOPS! Something went wrong",
          text: "Please check and ensure all experience form fields are filled",
        });
      }
    },
    selectedPartner(newVal) {
      let partnerData = newVal;
      partnerData.languages = partnerData.languages
          .split(",")
          .map((x) => x.trim());
      let addressSplit = partnerData.companyAddress.split(",");
      partnerData.companyStreet = addressSplit[0];
      partnerData.companyLocation = addressSplit[1];
      partnerData.partnerId = partnerData.id;
      partnerData.dmc === 1
          ? (partnerData.dmc = true)
          : (partnerData.dmc = false);
      partnerData.insurance === 1
          ? (partnerData.insurance = true)
          : (partnerData.insurance = false);
      partnerData.license === 1
          ? (partnerData.license = true)
          : (partnerData.license = false);
      
      let selectedDestinations = partnerData.destinations.split(',').map((des)=>des.toLowerCase())
        const selectedDestinationIDs= this.$africanDestinations.reduce((container, currentItem)=>{
          if(selectedDestinations.includes(currentItem.value.toLowerCase()))
            container.push(currentItem.id)
          return container;
        },[])
      partnerData.destinations = selectedDestinationIDs;
      this.form = partnerData;

    }
  }
};
</script>

<style scoped>

.navbar {
  @apply top-0 bg-white;
  @apply flex flex-wrap items-center;
  @apply w-full h-20 px-4 md:px-12;
}

.close-btn {
  @apply text-xl text-black-lightest;
  @apply flex items-center;
  @apply focus:outline-none focus:ring-2 focus:ring-black-lighter focus:scale-95 focus:ring-offset-2;
  @apply hover:text-black-base transition ease-in-out duration-300;
  @apply rounded p-3
}

.close-btn-txt {
  @apply pl-3 uppercase text-sm font-semibold;
}

.nav-wrapper {
  @apply relative transform left-1/2 -translate-x-1/2;
  @apply w-full max-w-screen-md h-12;
  @apply flex items-center justify-between;
}

.check-box-component {
  @apply w-5 h-5 border rounded  border-black-lightest;
}

.check-box-text {
  @apply ml-3 text-sm text-black-base;
}

.form {
  @apply w-full px-4 pb-48 md:px-12 mt-10;
}

.form-page {
  @apply max-w-screen-md relative transform left-1/2 -translate-x-1/2;
}

.form-page-title {
  @apply font-sans font-bold sm:text-center text-3xl;
  @apply mb-4 sm:text-4xl text-black-base;
}

.form-page-section-title {
  @apply text-black-lightest uppercase font-semibold text-xs my-3;
}

.form-page-description {
  @apply font-sans text-black-lightest text-left sm:text-center text-lg sm:text-xl;
  @apply mb-16;
}

.form-page-container {
  @apply mb-6 sm:mb-8 w-full;
}

.form-input-container {
  @apply flex flex-wrap justify-between;
}

.field-full {
  @apply w-full;
}

.field-half {
  @apply w-full sm:w-calc-1/2;
}

.field-full, .field-half {
  @apply mb-8;
}

.bottom-nav {
  @apply fixed left-0 bottom-0 p-4;
  @apply border-t border-grey-base;
  @apply w-full bg-white sm:mt-16;
}

.bottom-nav-wrapper {
  @apply flex justify-between items-center;
  @apply max-w-screen-md relative transform left-1/2 -translate-x-1/2;
}

.img-preview {
  @apply grid grid-cols-4 gap-6 w-full;
}

.img-preview-content {
  @apply rounded-md border border-grey-base overflow-hidden mt-4;
  @apply col-span-12 sm:col-span-2;
}

.input-label {
  @apply font-medium text-black-base;
}

.input-description {
  @apply text-black-lighter text-sm block font-sans mt-1;
}

#telephone {
  @apply mt-2 h-13 rounded-md;
}
</style>