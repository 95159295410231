import Cancellation from "../views/GroupTours/Form/Cancellation";
import GroupTour from "../views/GroupTours/GroupTour";
import GroupTours from "../views/GroupTours/GroupTours";
import CreateGroupTour from "../views/GroupTours/CreateGroupTour";
import Accommodations from "../views/GroupTours/Form/Accommodations";
import Itinerary from "../views/GroupTours/Form/Itinerary";
import Pricing from "../views/GroupTours/Form/Pricing";
import TourDetails from "../views/GroupTours/Form/TourDetails";

export default [
    {
        path: "/group-tours",
        name: "GroupTours",
        props: true,
        component: GroupTours,
        meta: {requiresAuth: true},
    },

    {
        path: "/group-tours/create",
        name: "CreateGroupTour",
        redirect: {name: 'CreateGroupTourDetails'},
        props: true,
        component: CreateGroupTour,
        meta: {requiresAuth: true},
        children: [
            {
                path: 'tour-details',
                name: 'CreateGroupTourDetails',
                component: TourDetails,
                meta: {requiresAuth: true, isLastStep: false}
            },
            {

                path: 'itinerary',
                name: 'CreateGroupTourItinerary',
                component: Itinerary,
                meta: {requiresAuth: true, isLastStep: false}
            },
            {
                path: 'accommodations',
                name: 'CreateGroupTourAccommodations',
                component: Accommodations,
                meta: {requiresAuth: true, isLastStep: false}
            },
            {
                path: 'pricing',
                name: 'CreateGroupTourPricing',
                component: Pricing,
                meta: {requiresAuth: true, isLastStep: false}
            },
            {
                path: 'cancellation',
                name: 'CreateGroupTourCancellation',
                component: Cancellation,
                meta: {requiresAuth: true, isLastStep: true}
            },
        ]
    },

    {
        path: "/group-tours/:tourId",
        name: "GroupTour",
        component: GroupTour,
        props: true,
        meta: {requiresAuth: true},
    },


    {
        path: "/group-tours/edit/:tourId",
        name: "EditGroupTour",
        component: CreateGroupTour,
        redirect: {name: 'EditGroupTourDetails'},
        props: true,
        meta: {requiresAuth: true},
        children: [
            {
                path: 'tour-details',
                name: 'EditGroupTourDetails',
                component: TourDetails,
                meta: {requiresAuth: true, isLastStep: false}
            },
            {

                path: 'itinerary',
                name: 'EditGroupTourItinerary',
                component: Itinerary,
                meta: {requiresAuth: true, isLastStep: false}
            },
            {
                path: 'accommodations',
                name: 'EditGroupTourAccommodations',
                component: Accommodations,
                meta: {requiresAuth: true, isLastStep: false}
            },
            {
                path: 'pricing',
                name: 'EditGroupTourPricing',
                component: Pricing,
                meta: {requiresAuth: true, isLastStep: false}
            },
            {
                path: 'cancellation',
                name: 'EditGroupTourCancellation',
                component: Cancellation,
                meta: {requiresAuth: true, isLastStep: true}
            },
        ]
    }
]
