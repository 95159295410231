<template>
  <div class="page">
    <Sidebar />
    <div class="main">
      <Navbar class="w-full z-50"/>
      <section class="px-4 md:px-12 mt-5 pb-12">
        <div class="mt-8">
          <h1 class="font-sans font-bold text-black-base text-2xl mb-8">
            Dashboard
          </h1>
          <SelectDropdown
            paddingY="py-2"
            class="w-72 mb-12"
            description="Select a period"
            :optionsList="period"
          />
          <div class="cards-container">
            <CardMetrics title="Revenue" value="9,4k" class="card" />
            <CardMetrics
              title="Requests"
              class="card"
              :showCurrency="false"
              value="1,4k"
            />
            <CardMetrics
              title="Bookings"
              class="card"
              :showCurrency="false"
              value="288"
            />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import CardMetrics from "@/components/Cards/CardMetrics";
import Navbar from "@/components/Layout/Navbar";
import SelectDropdown from "@/components/Inputs/SelectDropdown";
import Sidebar from "@/components/Layout/Sidebar/Sidebar";
export default {
  name: "Home",
  components: {
    CardMetrics,
    Navbar,
    SelectDropdown,
    Sidebar,
  },
  data() {
    return {
      period: [
        {
          value: "Today",
          name: "Today",
        },
        {
          value: "Yesterday",
          name: "Yesterday",
        },
        {
          value: "This week",
          name: "This week",
        },
        {
          value: "This month",
          name: "This month",
        },
        {
          value: "This year",
          name: "This year",
        },
      ],
    };
  },
};
</script>
<style scoped>
.page {
  @apply flex bg-grey-light h-100vh overflow-hidden;
}
.main {
  @apply self-start w-full relative h-full overflow-y-auto;
}
.cards-container {
  @apply flex justify-between flex-wrap;
}
.card {
  @apply w-full sm:w-calc-1/3 mb-3 md:mb-0;
}
</style>