<template>
  <section id="pricing" class="pb-52 relative">
    <h1 class="form-page-title">Pricing</h1>
    <div class="form-page-container">
      <div class="form-page-section">
        <SelectDropdown
            :optionsList="currencies"
            description="Choose a currency"
            :showLabel="true"
            label="Currency"
            :selected="tourDetails.baseCurrency"
            v-model="tourDetails.baseCurrency"
        />
        <div class="input-wrapper">
          <InputText
              type="number"
              label="Single room price"
              placeholder="e.g. 1800"
              class="w-full sm:w-calc-1/2"
              name="single-room-price"
              v-model="tourDetails.baseSingleRoomPrice"
          />

          <InputText
              type="number"
              label="Shared room price"
              placeholder="e.g. 1800"
              class="w-full sm:w-calc-1/2"
              name="shared-room-price"
              v-model="tourDetails.baseSharedRoomPrice"
          />
        </div>
        <InputText
            type="number"
            label="Earning fees (in %)"
            placeholder="e.g. 3"
            class="mt-6"
            name="earning-fees"
            v-model="tourDetails.earningFees"
        />
      </div>

    </div>
    <div class="w-full border-b border-grey-base my-12"></div>
    <div id="inclusions">
      <h4 class="sub-title">Inclusions</h4>
      <ButtonInputText
          v-for="(inclusion, i) in inclusions"
          :key="i"
          :index="i"
          @removeItem="removeInclusion(i)"
          :text="inclusion.text"
          :showField="inclusion.show"
          placeholder="e.g. Transfer to the airport"
          :name="'inclusion-' + i"
          @input="addItemToInclusion"
          class="mb-3"
      />
      <ButtonPrimary
          @click.native="addInclusion"
          text="Add a new field"
          class="px-3 ml-auto block mt-8"
      />
    </div>
    <div class="w-full border-b border-grey-base my-12"></div>
    <div id="exclusions">
      <h4 class="sub-title">Exclusions</h4>
      <ButtonInputText
          v-for="(exclusion, i) in exclusions"
          :key="i"
          :index="i"
          @removeItem="removeExclusion(i)"
          :showField="exclusion.show"
          :text="exclusion.text"
          placeholder="e.g. Transfer to the airport"
          :name="'exclusion-' + i"
          class="mb-3"
          @input="addItemToExclusion"
      />
      <ButtonPrimary
          @click.native="addExclusion"
          text="Add a new field"
          class="px-3 ml-auto block mt-8"
      />
    </div>
  </section>
</template>
<script>
import ButtonInputText from "@/components/Buttons/ButtonInputText";
import InputText from "@/components/Inputs/InputText";
import ButtonPrimary from "@/components/Buttons/ButtonPrimary";
import SelectDropdown from "@/components/Inputs/SelectDropdown";
import { mapGetters} from "vuex";

export default {
  name: "Pricing",
  components: {
    InputText,
    ButtonInputText,
    ButtonPrimary,
    SelectDropdown,
  },
  props: {
    pricingDetails: {
      type: Object,
    },
  },
  data() {
    return {
      inclusions: [],
      exclusions: [],
      exclusionsWithIDs: [],
      inclusionsWithIDs: [],
      currency: "",
      region: "en-EN",
      adultsPrice: 0,
      childrenPrice: 0,
      singleRoomPrice: 0,
      earningFees: 0,
      currencies: [
        {
          value: "CHF",
          name: "CHF",
        },
        // {
        //   value: "EUR",
        //   name: "EUR",
        // },
        // {
        //   value: "GBP",
        //   name: "GBP",
        // },
        // {
        //   value: "USD",
        //   name: "USD",
        // },
      ],
    };
  },
  methods: {
    addExclusion() {
      this.exclusions.push({text: "", show: true});
    },
    addInclusion() {
      this.inclusions.push({text: "", show: true});
    },
    addItemToInclusion(value, index) {
      let getIndex = this.inclusionsWithIDs.findIndex((x) => x.id === index);
      if (getIndex > -1) {
        this.inclusionsWithIDs.splice(getIndex, 1);
      }
      this.inclusionsWithIDs.splice(index, 0, {id: index, inclusion: value});
      let array = [];
      for (let i = 0; i < this.inclusionsWithIDs.length; i++) {
        array.push(this.inclusionsWithIDs[i].inclusion);
      }
      this.tourDetails.inclusions = array.toString();
    },
    addItemToExclusion(value, index) {
      let getIndex = this.exclusionsWithIDs.findIndex((x) => x.id === index);
      if (getIndex > -1) {
        this.exclusionsWithIDs.splice(getIndex, 1);
      }
      this.exclusionsWithIDs.splice(index, 0, {id: index, exclusion: value});
      let array = [];
      for (let i = 0; i < this.exclusionsWithIDs.length; i++) {
        array.push(this.exclusionsWithIDs[i].exclusion);
      }
      this.tourDetails.exclusions = array.toString();
    },
    removeInclusion(index) {
      let getIndex = this.inclusionsWithIDs.findIndex((x) => x.id === index);
      this.inclusionsWithIDs.splice(getIndex, 1);
      this.inclusions[index].show = false;
      let array = [];
      for (let i = 0; i < this.inclusionsWithIDs.length; i++) {
        array.push(this.inclusionsWithIDs[i].inclusion);
      }
      this.tourDetails.inclusions = array.toString();
      if (this.inclusionsWithIDs.length < 1) {
        this.inclusions.push({text: "", show: true});
      }
    },
    removeExclusion(index) {
      let getIndex = this.exclusionsWithIDs.findIndex((x) => x.id === index);
      this.exclusionsWithIDs.splice(getIndex, 1);
      this.exclusions[index].show = false;
      let array = [];
      for (let i = 0; i < this.exclusionsWithIDs.length; i++) {
        array.push(this.exclusionsWithIDs[i].exclusion);
      }
      this.tourDetails.exclusions = array.toString();
      if (this.exclusionsWithIDs.length < 1) {
        this.exclusions.push({text: "", show: true});
      }
    },
    setPrice(value) {
      this.tourDetails.trip_budget = value;
    },
    formatNumber(value, currency, region) {
      value = new Intl.NumberFormat(region, {
        style: "currency",
        currency: currency || "CHF",
      }).format(value);
      return value;
    },
  },
  computed: {
    ...mapGetters({
      tourDetails: "groupTours/tourDetails",
    }),

  },

  mounted() {
    let exclusionsArray,
        inclusionsArray = [];
    if (this.tourDetails.exclusions) {
      this.exclusions = [];
      exclusionsArray = (typeof this.tourDetails.exclusions) === 'string' ? this.tourDetails.exclusions.split(",") : this.tourDetails.exclusions;
      exclusionsArray.forEach((element, index) => {
        this.exclusions.push({text: element, show: true});
        this.exclusionsWithIDs.push({id: index, exclusion: element});
      });
    } else {
      this.exclusions = [{text: "", show: true}];
    }

    if (this.tourDetails.inclusions) {
      this.inclusions = [];
      inclusionsArray = (typeof this.tourDetails.inclusions) === 'string' ? this.tourDetails.inclusions.split(",") : this.tourDetails.inclusions;
      inclusionsArray.forEach((element, index) => {
        this.inclusions.push({text: element, show: true});
        this.inclusionsWithIDs.push({id: index, inclusion: element});
      });
    } else {
      this.inclusions = [{text: "", show: true}];
    }
  },
};
</script>
<style scoped>
.form-page-title {
  @apply font-sans font-bold text-black-base text-xl mt-12 sm:text-3xl mb-4 sm:mb-8;
}

.form-page-container {
  @apply flex flex-wrap sm:flex-nowrap justify-between items-start w-full relative;
}

.form-page-section {
  @apply w-full sm:w-calc-1/2;
}

.price-table {
  @apply bg-white border border-grey-base rounded-md shadow-sm p-6 hidden sm:block mt-12 sm:mt-0;
}

.input-wrapper {
  @apply flex justify-between mt-6 flex-wrap sm:flex-nowrap w-full;
}

.sub-title {
  @apply font-sans font-bold text-black-base text-base sm:text-xl mb-3 sm:mb-6;
}
</style>