<template>
  <div class="rounded relative">
    <InputText
        @keypress.native="openResults"
        @blur="openResults"
        :label="label"
        :placeholder="placeholder"
        :value="clickedItem"
        :name="name"
        v-model="searchWord"
        :show-label="showLabel"
    />

    <ResultsDropdown
        @clickedItem="getClickedItem"
        v-if="showResults"
        class="mt-2"
        :resultItems="searchResult"
        :emptyStateTitle="emptyStateTitle"
        :emptyStateText="emptyStateText"
        :primaryAction="primaryAction"
        @primaryAction="$emit('primaryAction')"
    />
  </div>
</template>
<script>
import InputText from "@/components/Inputs/InputText";
import ResultsDropdown from "@/components/Inputs/SelectSearch/ResultsDropdown";

export default {
  name: "SelectSearch",
  components: {
    InputText,
    ResultsDropdown,
  },
  data() {
    return {
      showResults: false,
      clickedItem: "",
      searchResult: [],
      searchWord: this.value.name,
    };
  },
  props: {
    showLabel: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Object,
    },
    label: {
      type: String,
      default: "Select a hotel",
    },
    placeholder: {
      type: String,
      default: "Search for a hotel",
    },
    resultItems: {
      type: Array,
      default: () => [],
    },
    searchKeyword: {
      type: String,
      default: "",
    },
    emptyStateTitle: {
      type: String,
      default: "Title",
    },
    name: {
      type: String,
      default: "field",
    },
    emptyStateText: {
      type: String,
      default: "Text",
    },
    primaryAction: {
      type: String,
      default: "CTA Text",
    },
  },
  methods: {
    getClickedItem(value) {
      this.searchWord = value.name;
      this.$emit("input", value);
      this.$emit("newSelection", value);

      this.clickedItem = value.name;
      this.closeResults();
    },
    openResults() {
      if (this.searchWord !== "") {
        this.showResults = true;
      }
    },
    closeResults() {
      this.showResults = false;
    },
  },
  watch: {
    value(newVal) {
      this.searchWord = newVal.name;
    },
    searchWord(newValue) {
      this.searchResult = [];
      this.searchResult = this.resultItems.filter((element) => {
        return element.name.toLowerCase().includes(newValue.toLowerCase());
      });
    },
  },
};
</script>