<template>
  <ValidationProvider tag="div" v-slot="{ errors }" :rules="rules" :required="required" :name="name">
    <label class="w-full mb-6" :for="name">
      <span class="area-label">{{ label }}</span>
    </label>
    <textarea
        class="area"
        :class="[errors[0]  ? 'border-red-base' : 'border-grey-dark']"
        :name="name"
        id="name"
        :placeholder="placeholder"
        @blur="$emit('blur')"
        @input="emitMethods"
        v-model="textInput"
    ></textarea>
    <span
        v-if="errors[0]"
        class="mt-2 font-sans text-sm font-medium error-msg text-red-base"
    >
      {{ errors[0].replaceAll('-'," ") }}
    </span>

  </ValidationProvider>

</template>
<script>
import {ValidationProvider} from 'vee-validate';
export default {
  name: "TextArea",
  components: {
    ValidationProvider
  },
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
    },
    name: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    vid: {
      type: String,
      default: undefined
    },
    required: {
      type: Boolean,
      required: false
    },
    rules: {
      type: [Object, String],
      required: false
    }
  },
  data() {
    return {
      textInput: "",
    };
  },
  methods: {
    emitMethods() {
      this.$emit("input", this.textInput);
    },
  },
  watch: {
    value(newVal) {
      this.textInput = newVal;
    },
  },
  mounted() {
    if(this.value){
      this.textInput = this.value;
    }
  }
};
</script>
<style scoped>
.area {
  @apply border block w-full resize-none rounded h-32;
  @apply focus:border-teal-base focus:ring-teal-lighter focus:ring-2;
  @apply border-grey-dark text-black-base placeholder-black-lightest;
  @apply text-base;
}

.area-label {
  @apply block mb-1.5 sm:mb-3 text-sm sm:text-base font-medium;
}
</style>
