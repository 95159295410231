export default {
    data(){
        return {
            currentStepItemIndex: 0,
            isLastStep: false,
            pageIsLoading: true,
            showDialog: false,
            showOverlay: false,
            showSheet: false,
            requestSheetPosition: "-bottom-full h-0 overflow-hidden",
            hotelSheetPosition: "-bottom-full h-0 overflow-hidden",
            summarySheetPosition: "-bottom-full h-0 overflow-hidden",
            experienceSheetPosition: "-bottom-full h-0 overflow-hidden",
            experienceSheetListPosition: "-bottom-full h-0 overflow-hidden",
            pricingSheetPosition: "-bottom-full h-0 overflow-hidden",
        }
    },
    methods:{
        closeSheets() {
            this.requestSheetPosition = "-bottom-full h-0 overflow-hidden";
            this.summarySheetPosition = "-bottom-full h-0 overflow-hidden";
            this.hotelSheetPosition = "-bottom-full h-0 overflow-hidden";
            this.experienceSheetPosition = "-bottom-full h-0 overflow-hidden";
            this.experienceSheetListPosition = "-bottom-full h-0 overflow-hidden";
            this.pricingSheetPosition = "-bottom-full h-0 overflow-hidden";
            this.setShowAccommodationSheet(false);
            this.setShowExperienceListSheet(false);
            // this.isLastStep = false; //Hides hint and CTA in preview
        },
        getCurrentMultiStepItem(value) {
            this.currentStepItemIndex = this.formSections.findIndex((x) => x.name === value.name);

            for (let section of this.formSections) {
                section.show = false;
                if (section.name === value.name) {
                    section.show = value.show;
                }
            }
        },
        showSummarySheet() {
            this.closeSheets();
            this.summarySheetPosition = "bottom-0 overflow-auto h-full";
            //Scroll content of summary sheet to the top
            let container = this.$el.querySelector("#summarysheet");
            container.scrollTo(0, 0);
        },
        showPricingSheet() {
            this.closeSheets();
            this.pricingSheetPosition = "bottom-0 overflow-auto h-full";
            //Scroll content of hotel sheet to the top
            let container = this.$el.querySelector("#pricingsheet");
            container.scrollTo(0, 0);
        },
        showHotelSheet() {
            this.closeSheets();
            this.hotelSheetPosition = "bottom-0 overflow-auto h-full";
            //Scroll content of hotel sheet to the top
            let container = this.$el.querySelector("#hotelsheet");
            container.scrollTo(0, 0);
        },
        showRequestSheet() {
            this.closeSheets();
            this.requestSheetPosition = "bottom-0 overflow-auto h-full";
            //Scroll content of request sheet to the top
            let container = this.$el.querySelector("#requestsheet");
            container.scrollTo(0, 0);
        },
        showExperienceSheet() {
            this.closeSheets();
            this.experienceSheetPosition = "bottom-0 overflow-auto h-full";
            //Scroll content of request sheet to the top
            let container = this.$el.querySelector("#experiencesheet");
            container.scrollTo(0, 0);
        },
        showExperienceListSheet() {
            this.closeSheets();
            this.experienceSheetListPosition = "bottom-0 overflow-auto h-full";
            //Scroll content of request sheet to the top
            let container = this.$el.querySelector("#experience-list-sheet");
            if (container) {
                container.scrollTo(0, 0);
            }
        },
        exitTripCreationForm() {
            if (this.showUnsavedChangesWarning) {
                if (
                    window.confirm(
                        "Are you sure you want to leave the page? \nIf you leave without saving, your changes will be lost."
                    )
                ) {

                    this.$router.go(-1);
                }
            } else {
                this.$router.go(-1);
            }

        },
    },
    watch:{
        showExperienceListSheetState(newVal) {
            if (newVal === true) {
                this.showExperienceListSheet();
            }
        },
        showAccommodationSheet(newVal) {
            if (newVal === true) {
                this.showHotelSheet();
            }
        },
        savingResponseStatus(newVal) {
            if (newVal === "success") {
                this.$notify({
                    type: "success",
                    title: "Success",
                    text: `${this.savingResponseMessage}`,
                });
            } else if (newVal === 'failed') {
                this.$notify({
                    type: "error",
                    title: "Something went wrong",
                    text: `${this.savingResponseMessage}`,
                });
            }
            this.closeSheets();
        },
        $route(to) {
            this.isLastStep = to.meta.isLastStep;
        },
        shouldRedirectAfterOfferSave(newVal) {
            if (newVal === true) {
                this.$router.go(-1);
            }
        },
    }
}