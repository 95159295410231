<template>
    <ValidationProvider tag="div" v-slot="{ errors }" :rules="rules"  :name="name">
      <label class="input" :for="name">
    <span v-if="showLabel" class="input-label">
      {{ label }}
    </span>
        <button
            @click="revealPassword"
            v-if="isPassword"
            class="ml-auto"
            type="button"
        >
          <i :class="['bx', icon, 'icon-style']"></i>
        </button>
      </label>
      <input
          :id="name"
          :placeholder="placeholder"
          class="input-field"
          :required="required"
          :class="[
        showErrMsg ? 'border-red-base' : 'border-grey-dark',
        'input-field',
      ]"
          :value="value"
          :type="type"
          @blur="$emit('blur')"
          @input="emitMethods($event)"
          v-bind="$attrs"
      />
      <span
          v-if="showErrMsg||errors[0]"
          class="mt-2 font-sans text-sm font-medium error-msg text-red-base"
      >
      {{ errorMsg || errors[0].replaceAll('-'," ") }}
    </span>
    </ValidationProvider>
</template>
<script>
import {ValidationProvider} from 'vee-validate';

export default {
  name: "InputText",
  inheritAttrs: false,
  components: {
    ValidationProvider
  },
  data() {
    return {
      inputText: "",
      isHidden: true,
      inputType: this.type,
    };
  },
  props: {
    value: {type: [String, Number]},
    label: {
      type: String,
      default: "Label",
    },
    placeholder: {
      type: String,
      default: "Placeholder",
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    help: {
      type: String,
    },
    type: {
      type: String,
      default: "text",
      validator(value) {
        return [
          "url",
          "text",
          "password",
          "tel",
          "search",
          "number",
          "email"
        ].includes(value);
      }
    },
    errorMsg: {
      type: String,
      default: "",
    },
    showErrMsg: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "input",
    },
    vid: {
      type: String,
      default: undefined
    },
    required: {
      type: Boolean,
      required: false
    },
    rules: {
      type: [Object, String],
      required: false
    }
  },
  methods: {
    emitMethods(event) {
      this.$emit("input", event.target.value);
      this.$emit("secondaryInput");
    },
    revealPassword() {
      if (this.isPassword) {
        this.inputType = "text";
        this.isHidden = !this.isHidden;
      } else {
        this.inputType = "password";
        this.isHidden = !this.isHidden;
      }
    },
  },
  computed: {
    icon() {
      if (this.isHidden === true) return "bxs-show";
      return "bxs-hide";
    },
    isPassword() {
      return this.type.toLowerCase() === "password";
    },
  },

};
</script>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.input {
  @apply flex flex-wrap;
}

.input-label {
  @apply mb-3 font-sans text-black-base font-medium;
  @apply text-base;
}

.input-field {
  @apply w-full p-3 border rounded focus:border-teal-base;
  @apply focus:ring-teal-lighter focus:ring-2 text-black-base focus:outline-none placeholder-black-lightest;
  @apply text-base;
}

.icon-style {
  @apply text-xl text-black-lightest;
}
</style>
