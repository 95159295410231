<template>
  <div class="empty-state">
    <figure class="empty-state-img-container">
      <slot name="image">
        <img
            v-if="showImg"
            class="empty-state-img"
            :src="require(`@/assets/img/empty-states/${imgName}`)"
            alt="Empty State"
        />
      </slot>
    </figure>
    <h3 class="empty-state-title">
      {{ title }}
    </h3>
    <p class="empty-state-text">
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  name: "NoResultState",
  props: {
    showImg: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "No New partners yet",
    },
    text: {
      type: String,
      default: "There are no new partners yet.",
    },
    imgName: {
      type: String,
      default: "no-change-requests.svg",
    },
  },
};
</script>

<style scoped>
.empty-state {
  @apply w-full;
}

.empty-state-img-container {
  @apply max-w-lg relative left-1/2 transform -translate-x-1/2;
}

.empty-state-img {
  @apply w-full h-full object-cover mb-6;
}

.empty-state-title {
  @apply font-sans text-black-base font-semibold text-center;
  @apply text-base sm:text-xl;
}

.empty-state-text {
  @apply font-sans text-black-lighter text-center;
  @apply text-sm sm:text-base font-light;
}
</style>