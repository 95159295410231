<template>
  <div id="summarysheet" class="sheet">
    <nav class="navbar">
      <ButtonClose @click.native="$emit('closeSheet')" class="btn-close"/>
    </nav>
    <OfferSummary :offer="offer" :dates="dates" :isLastStep="isLastStep" @submitClicked="$emit('submitClicked')"
                  :isLoading="isLoading"/>
  </div>
</template>
<script>
import ButtonClose from "@/components/Buttons/ButtonClose";
import OfferSummary from "@/components/Offer/OfferSummary";

export default {
  name: "OfferSummarySheet",
  components: {
    OfferSummary,
    ButtonClose,
  },
  props: {
    dates: {
      type: String,
      required: false
    },
    offer: {
      type: Object,
    },
    tripTitle: {
      type: String,
      default: "Trip title",
    },
    isLastStep: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    tripCurrency: {
      type: String,
      default: "$",
    },
    tripPrice: {
      type: Number,
      default: 1000,
    },
    tripCountry: {
      type: String,
      default: "Trip country",
    },
    tripAdults: {
      type: Number,
      default: 1,
    },
    tripChildren: {
      type: Number,
      default: 0,
    },
    tripId: {
      type: Number,
      default: 1,
    },
    tripDays: {
      type: Number,
      default: 7,
    },
    tripAgentImg: {
      type: String,
      default:
          "https://image.freepik.com/free-photo/cheerful-carefree-black-man-dances-with-raised-fists-wears-transparent-glasses_273609-37390.jpg",
    },
    tripAgentName: {
      type: String,
      default: "Agent's name",
    },
    tripAgency: {
      type: String,
      default: "Agency",
    },
  },
};
</script>
<style scoped>
.sheet {
  @apply bg-white px-4 overflow-y-auto pb-20;
}

.navbar {
  @apply max-w-screen-sm fixed z-30 left-1/2 transform -translate-x-1/2 w-full py-4;
}

.btn-close {
  @apply absolute right-4 top-4;
}

</style>