<template>
  <div id="cancellation">
    <h2 class="section-title">Cancellation</h2>
    <div class="grid gap-4 mt-6 refund-cards s:grid-cols-2">
      <div class="refund-card">
        <div class="w-2 h-full color-label bg-teal-base"></div>
        <div class="p-3 text">
          <h5 class="font-sans font-medium text-base-text">
            Full refund · 100%
          </h5>
          <p class="font-sans text-sm text-base-text">
            Up to {{ fullRefundDays }}
            {{
              fullRefundDays > 1 ? 'days' : 'day'
            }}
            before the trip
          </p>
        </div>
      </div>
      <div class="refund-card">
        <div class="w-2 h-full color-label bg-yellow-base"></div>
        <div class="p-3 text">
          <h5 class="font-sans font-medium text-base-text">
            Partial refund · {{ partialRefund  ? partialRefund : 0}}%
          </h5>
          <p class="font-sans text-sm text-base-text">
            Up to {{ partialRefundDays }}
            {{
              partialRefundDays > 1 ? 'days' : 'day'
            }}
            before the trip
          </p>
        </div>
      </div>
      <div class="refund-card">
        <div class="w-2 h-full color-label bg-red-base"></div>
        <div class="p-3 text">
          <h5 class="font-sans font-medium text-base-text">
            No refund · 0%
          </h5>
          <p class="font-sans text-sm text-base-text">
            Up to {{ noRefundDays }}
            {{
              noRefundDays > 1 ? 'days' : 'day'
            }}
            before the trip
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CancellationBlock',
  props: {
    noRefundDays:[String,Number],
    partialRefund:[String,Number],
    partialRefundDays:[String,Number],
    fullRefundDays:[String,Number]
  },
};
</script>
<style scoped>
.section-title {
  @apply mt-12 font-sans text-2xl font-semibold;
}

.refund-card {
  @apply rounded-md  overflow-hidden border border-grey-base shadow-sm flex bg-white;
}
</style>
