<template>
  <div id="configurator">
    <div class="h-full">
      <AccommodationBlock
        v-for="(night, index) in nights"
        :blockId="index"
        :key="index"
        :night="night"
        :blockOpacity="night.blockOpacity"
        :showBlock="night.showBlock"
        :hotels="accommodations"
        :startDate="night.startDate"
        :currentDate="night.startDate"
        :checkInDate="night.checkInDate"
        :currentNight="index + 1"
        :amountOfNights="nights.length"
        :class="night.class"
        :numberOfNights="night.nightsAvailable"
        :showSummary="night.showSummary"
        :selectedNumberOfNights="night.overnightStays"
        :showInputFields="night.showInputFields"
        :showPlaceholder="night.showPlaceholder"
        :disablePlaceholderBtn="night.disablePlaceholderBtn"
        @delete="deleteBlock(index)"
        @edit="editBlock(index)"
        @showHotelSheet="showHotelSheet"
        @getSelectValue="getNightValue"
        @openBlock="openBlock(index)"
        @saveBlock="saveBlock"
        @resetNights="resetNights(index)"
        @cancelAction="cancelBlock(index)"
      />
    </div>
  </div>
</template>

<script>
import AccommodationBlock from "@/components/Offer/Accommodations/AccommodationBlock";
import { mapActions, mapGetters } from "vuex";
import {
  getNumNightsFromString,
  generateNights,
  checkIfDatesAreEqual,
} from "@/shared/dateHandler";

export default {
  name: "AccommodationConfigurator",
  components: {
    AccommodationBlock,
  },
  data() {
    return {
      startDate: "06/11/2021",
      endDate: "06/19/2021",
      hotels: [],
      showSummary: false,
      showInput: false,
      currentSelectValue: undefined,
      currentBlockIndex: 0,
      nights: [],
    };
  },
  props: {
    alreadyHasAccommodations: {
      type: Boolean,
      default: false,
    },
    newAccommodation: {
      type: String,
      default: "",
    },
    dates: {
      type: String,
      required: true,
    },
    destination: {
      type: String,
      required: true,
    },
    selectedAccommodation: {
      type: Array,
      required: false,
    },
  },
  methods: {
    ...mapActions({
      setShowAccommodationSheet: "accommodations/setShowAccommodationSheet",
      getAccommodations: "accommodations/getAccommodations",
      resetAccommodationDetails: "offer/resetAccommodationDetails",
    }),

    showHotelSheet() {
      this.setShowAccommodationSheet(true);
    },
    createNights() {
      this.nights = generateNights(this.dates);
    },
    //Other Methods
    createBlockDropDownValues(index) {
      let block = this.nights[index];
      block.nightsAvailable = [];
      let selectDropdownValues = [{ value: 0, name: 0 }];
      let count = 1;
      for (let i = index; i < this.nights.length; i++) {
        selectDropdownValues.push({ value: count, name: count });
        count++;
      }
      block.nightsAvailable.push(...selectDropdownValues);
    },
    openBlock(index) {
      let block = this.nights[index]; //Get currently selected block
      block.showPlaceholder = false; //Hide block's placeholder element
      block.showInputFields = true; //Reveal block's form
      this.createBlockDropDownValues(index);
      this.disableNonCurrentBlocks(index); //Disable all other blocks that aren't currently selected
    },
    getNightValue(value) {
      this.currentSelectValue = parseInt(value); //Get the value of amount of nights selected in currently open block
    },
    cancelBlock(index) {
      let block = this.nights[index]; //Get current selected block
      if (block.amountOfNights >= this.currentSelectValue) {
        block.showSummary = true;
        block.showInputFields = false;
      } else {
        block.showPlaceholder = true;
        block.showInputFields = false;
      }
      this.enableNonCurrentBlocks(index); //Enable disabled not currently selected blocks
    },
    saveBlock(night) {
      let value = night.accommodation;
      let getIndex = this.nights.findIndex((x) => x.id === night.id);
      let block = this.nights[getIndex]; //Get currently selected block
      block.showInputFields = false; //Hide block's form
      block.showSummary = true;

      //this is to reset the current select value whe it hasn't been set
      if (this.currentSelectValue == 0) {
        this.getNightValue(block.accommodation.numberOfNights);
      }
      this.showBlocks(getIndex);
      this.hideBlocks(getIndex);
      block.amountOfNights = this.currentSelectValue;
      this.enableNonCurrentBlocks(getIndex); //Enable disabled not currently selected blocks

      //meal plan
      let mealPlanStringArray = [];
      let mealplan = value.mealplan;
      if (mealplan.breakfast == true) {
        mealPlanStringArray.push("Breakfast");
      }

      if (mealplan.lunch == true) {
        mealPlanStringArray.push("Lunch");
      }

      if (mealplan.dinner == true) {
        mealPlanStringArray.push("Dinner");
      }

      let accommodation = {
        id: block.id,
        name: value.hotel.name.trim(),
        address: value.hotel.address.trim(),
        country: value.hotel.country,
        checkin: value.checkin.trim(),
        image: value.hotel.image,
        description: value.hotel.description,
        fullCheckinDate: value.fullCheckinDate.trim(),
        checkout: value.checkout.trim(),
        fullCheckoutDate: value.fullCheckoutDate.trim(),
        nights: parseInt(value.numberOfNights),
        mealplan: mealPlanStringArray.toString().trim(),
      };
      this.$emit("addAccommodation", accommodation);
    },
    deleteBlock(index) {
      const block = this.nights[index];
      block.showPlaceholder = true;
      block.showSummary = false;
      block.showInputFields = false;
      this.showBlocks(index);
      block.amountOfNights = 0;

      block.accommodation.hotel = {
        name: "",
        image: "",
        address: "",
      };

      block.accommodation.mealplan = {
        breakfast: false,
        lunch: false,
        dinner: false,
      };

      this.$emit("removeAccommodation", block.id);
    },
    hideBlocks(index) {
      let numberOfNights = this.currentSelectValue + index;
      this.nights[index].active = false;
      for (let i = index + 1; i < numberOfNights; i++) {
        this.nights[i].showSummary = false;
        this.nights[i].showPlaceholder = true;
        this.nights[i].showBlock = false;
        this.nights[i].active = false;
        this.nights[i].accommodation.hotel = {
          name: "",
          image: "",
          address: "",
        };
        this.nights[i].accommodation.mealplan = {
          breakfast: false,
          lunch: false,
          dinner: false,
        };
        this.$emit("removeAccommodation", this.nights[i].id);
      }
    },
    showBlocks(index) {
      let block = this.nights[index];
      let numberOfNights =
        block.amountOfNights > 0
          ? block.amountOfNights + index
          : this.currentSelectValue + index;
      block.active = true;
      this.createBlockDropDownValues(index);
      for (let i = index + 1; i < numberOfNights; i++) {
        this.nights[i].showBlock = true;
        this.nights[i].active = true;
      }
    },
    enableNonCurrentBlocks(index) {
      let nights = this.nights; //Get array of nights
      for (let [i, night] of nights.entries()) {
        //Loop through each night element in nights array
        //Then set disabled attribute of block's placeholder element to false and opacity to 100%, for each not currently select block
        if (index != i) {
          night.disablePlaceholderBtn = false;
          night.blockOpacity = "opacity-100";
        }
      }
    },
    disableNonCurrentBlocks(index) {
      let nights = this.nights; //Get array of nights
      for (let [i, night] of nights.entries()) {
        //Loop through each night element in nights array
        //Then set disabled attribute of block's placeholder element to true and opacity to 50%, for each not currently select block
        if (index != i) {
          night.disablePlaceholderBtn = true;
          night.blockOpacity = "opacity-50";
        }
      }
    },
    editBlock(index) {
      let block = this.nights[index]; //Get currently selected block

      block.showSummary = block.showPlaceholder = false; //Hide block's placeholder element
      block.showInputFields = true; //Reveal block's form

      this.createBlockDropDownValues(index);
      this.disableNonCurrentBlocks(index);
    },
    mountExistingBlock() {
      //recreating the  accommodation array from saved data
      try {
        if (typeof this.selectedAccommodation == "string") {
          this.selectedAccommodation = JSON.parse(this.selectedAccommodation);
        }
        let availableNightsIDs = [];
        this.selectedAccommodation.forEach((accommodationDetail) => {
          //for each item in the accommodation array
          //push the ids into an array
          availableNightsIDs.push(accommodationDetail.id);
          //get the index of the current item from the current available nights
          let getIndex = this.nights.findIndex((night) => {
            return checkIfDatesAreEqual(
              night.accommodation.fullCheckinDate,
              accommodationDetail.fullCheckinDate
            ); //night.id == accommodationDetail.id;
          });
          if (getIndex > -1) {
            let block = this.nights[getIndex];
            block.showInputFields = false; //Hide block's form
            block.showSummary = true;
            block.showPlaceholder = false;
            block.accommodation.hotel = {
              name: accommodationDetail.name,
              address: accommodationDetail.address,
              image: accommodationDetail.image,
              description: accommodationDetail.description,
              country: accommodationDetail.country,
            };
            block.accommodation.numberOfNights = accommodationDetail.nights;
            block.accommodation.checkin = accommodationDetail.checkin;
            block.accommodation.checkout = accommodationDetail.checkout;
            block.accommodation.fullCheckinDate =
              accommodationDetail.fullCheckinDate;
            block.accommodation.fullCheckoutDate =
              accommodationDetail.fullCheckoutDate;
            block.amountOfNights = accommodationDetail.nights;
            //Get currently selected block

            //mealplan
            let mealPlanArray = accommodationDetail.mealplan
              .toLowerCase()
              .split(",");
            if (mealPlanArray.includes("breakfast")) {
              block.accommodation.mealplan.breakfast = true;
            }
            if (mealPlanArray.includes("lunch")) {
              block.accommodation.mealplan.lunch = true;
            }
            if (mealPlanArray.includes("dinner")) {
              block.accommodation.mealplan.dinner = true;
            }

            for (
              let i = getIndex + 1;
              i < block.amountOfNights + getIndex;
              i++
            ) {
              this.nights[i].showBlock = false;
              this.nights[i].active = false;
            }
          }
        });
        this.currentSelectValue = 0;
      } catch (error) {
        console.log(error);
        this.$emit("resetAccommodationDetails");
      }
    },
  },
  computed: {
    ...mapGetters({
      accommodations: "accommodations/accommodations",
    }),
    availableNights() {
      let selectDropdownValues = [{ value: 0, name: 0 }];
      let nights = this.nights.filter((night) => night.active == true);
      for (let i = 0; i < nights.length; i++) {
        selectDropdownValues.push({ value: i + 1, name: i + 1 });
      }
      return selectDropdownValues;
    },
    totalNights() {
      return getNumNightsFromString(this.dates);
    },
  },
  watch: {
    dates() {
      //on date change recreate the nights
      this.resetAccommodationDetails();
      this.createNights();
    },
    newAccommodation() {
      this.getAccommodations(this.destination);
    },
    destination(newVal) {
      this.getAccommodations(newVal);
    },
  },
  mounted() {
    this.createNights();
    if (this.selectedAccommodation) {
      this.mountExistingBlock();
    }
    this.getAccommodations(this.destination);
  },
};
</script>

<style scoped></style>
