<template>
  <section id="tour-details">
    <h1 class="form-page-title">Tour details</h1>
    <SelectDropdown
      label="Destination"
      :showLabel="true"
      description="Select a destination"
      class="mb-6"
      :selected="tourDetails.destination"
      :optionsList="$africanDestinations"
      v-model="tourDetails.destination"
      @change="changedDestination"
    />
    <InputText
      label="Title"
      placeholder="e.g. Amazing Morocco"
      class="mb-6"
      name="tour-title"
      v-model="tourDetails.title"
      rules="required"
    />

    <div>
      <div
        class="img-preview mb-6"
        v-if="
          tourDetails.image
            ? tourDetails.image.path || tourDetails.image || null
            : null
        "
      >
        <ImagePreview
          :image="{
            name: 'Tour Image',
            path: `${!isSelected ? basePath : ''}${
              tourDetails.image.path || tourDetails.image
            }`,
          }"
          class="img-preview-content"
          @remove="tourDetails.image = ''"
        />
      </div>

      <InputUpload
        v-else
        v-model="tourDetails.image"
        :allowMultiple="false"
        class="input-upload mb-6"
        label="Upload a the cover image for the trip"
        name="coverImage"
        text="Only jpeg, jpg and png allowed (max 1 MB)."
        title="Click to upload the cover image"
        @itemSelected="isSelected = true"
      />
    </div>
    <div class="sm:flex mb-6 justify-between">
      <InputText
        type="number"
        label="Maximum group size"
        placeholder="e.g. 2"
        class="w-full mt-6 sm:mt-0 sm:w-calc-1/2"
        name="max-group-size"
        v-model="tourDetails.maxGroupSize"
        rules="required|min:1"
        min="1"
      />
      <InputText
        type="number"
        label="Minimum age"
        placeholder="e.g. 16"
        class="w-full sm:w-calc-1/2 mt-6 sm:mt-0"
        name="minimum-age"
        min="1"
        rules="required|min:1"
        v-model="tourDetails.minAge"
      />
    </div>
    <div>
      <SelectDropdown
        label="Language"
        :showLabel="true"
        description="Select a language"
        class="mb-6"
        :selected="tourDetails.language"
        :returnValue="(option) => option.code"
        :optionsList="$officialLanguages"
        v-model="tourDetails.language"
        @change="changedDestination"
      />
    </div>
    <div class="mb-6">
      <DateRangePicker v-model="dateRange" />
      <div class="flex font-semibold mt-2 gap-2" v-if="tourDetails.duration">
        <h2 class="font-sans text-black-base">Tour Duration</h2>
        <div
          class="inline-block rounded px-2 py-1 text-sm text-teal-text bg-teal-base text-white ml-2"
        >
          {{ tourDetails.duration }} days &
          {{ tourDetails.duration - 1 }} nights
        </div>
      </div>
    </div>

    <div class="mb-6">
      <TextArea
        label="Tour description"
        rows="6"
        name="tour-description"
        rules="required"
        v-model="tourDetails.description"
      />
    </div>

    <MultiSelect
      v-if="tourDetails.agency"
      label="Select agencies"
      placeholder="Select one or more agencies"
      class="mt-6"
      :options="partners"
      v-model="tourDetails.agency"
    />
    <div>
      <div v-if="otherAvailableTourDates">
        <MultiDatePicker
          hasHiddenItems
          v-model="otherAvailableTourDates"
          disablePreSavedDates
          :preSavedDates="preSavedDates"
          @deleteDate="deleteDate"
        >
          <template #additionalDetails="{ date }">
            <div class="flex font-semibold gap-2" v-if="date">
              <h2 class="font-sans text-black-base">Available date</h2>
              <div
                class="inline-block rounded px-2 py-1 text-sm text-teal-text bg-teal-base text-white ml-2"
              >
                {{ getTourDateRange(date) }}
              </div>
            </div>
          </template>
        </MultiDatePicker>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import {
  DateRangePicker,
  InputText,
  SelectDropdown,
  TextArea,
  MultiSelect,
  MultiDatePicker,
  InputUpload,
} from "@/components";
import {
  getNumDaysFromString,
  tourDateRangeFromDuration,
  tourDateRangeFromDurationISO,
  checkAndConvertDateToIso,
} from "@/shared/dateHandler";
import ImagePreview from "../../../components/ImagePreview";

export default {
  name: "TourDetails",
  components: {
    ImagePreview,
    InputUpload,
    MultiDatePicker,
    TextArea,
    InputText,
    SelectDropdown,
    DateRangePicker,
    MultiSelect,
  },
  props: {},
  data() {
    return {
      value: null,
      options: ["list", "of", "options"],
      showDateField: true,
      otherAvailableTourDates: null,
      preSavedDates: null,
      dateRange: "",
      isSelected: false,
    };
  },
  computed: {
    ...mapGetters({
      tourDetails: "groupTours/tourDetails",
      approvedPartners: "partners/approvedPartners",
    }),
    basePath() {
      return process.env.VUE_APP_FILE_BASE_PATH;
    },
    partners() {
      let data = [];
      this.approvedPartners.forEach((element) => {
        let partner = {
          id: element.id,
          imgPath: element.profilePhoto,
          title: element.companyName,
          subTitle: element.firstName + " " + element.lastName,
          showItem: false,
          showCheckMark: false,
        };
        data.push(partner);
      });
      return data;
    },
  },

  mounted() {
    this.dateRange = this.tourDetails.tourDates[0];
    this.tourDetails.duration = getNumDaysFromString(this.dateRange);
    this.otherAvailableTourDates = [...this.tourDetails.tourDates]
      .slice(1)
      .map((dateRange) => dateRange.split("|")[0].trim());

    this.otherAvailableTourDates = this.otherAvailableTourDates.map((date) =>
      checkAndConvertDateToIso(date)
    );
    this.preSavedDates = [...this.otherAvailableTourDates];
  },
  methods: {
    ...mapActions({
      getExperiences: "experiences/getExperiences",
      dateChanged: "groupTours/dateChanged",
      deleteDateFromBackend: "groupTours/deleteDateFromBackend",
    }),

    deleteDate(date) {
      const fullDate = this.tourDetails.tourDates.find((d) => d.includes(date));
      this.deleteDateFromBackend({
        tourId: this.tourDetails.id,
        date: fullDate,
      });
    },
    toggleDatePicker() {
      this.showDateField = true;
    },
    toggleNumberSpinner() {
      this.showDateField = false;
    },
    changedDestination() {
      this.getExperiences(this.tourDetails.destination);
    },
    generateOtherAvailableTourDates() {
      const newArray = this.otherAvailableTourDates.map((date) => {
        return tourDateRangeFromDurationISO(date, this.tourDetails.duration);
      });
      this.tourDetails.tourDates = [
        this.tourDetails.tourDates[0].trim(),
        ...newArray,
      ];
    },
    getTourDateRange(date) {
      //something weird going on here
      return tourDateRangeFromDuration(date, this.tourDetails.duration, 0, {
        inclusive: true,
      });
    },
  },
  watch: {
    otherAvailableTourDates() {
      this.generateOtherAvailableTourDates();
    },
    dateRange(newVal) {
      if (newVal !== this.tourDetails.tourDates[0]) {
        this.tourDetails.tourDates[0] = newVal;
        this.dateChanged();
        this.tourDetails.duration = getNumDaysFromString(newVal);
      }
    },
  },
};
</script>
<style scoped>
#tour-details {
  @apply min-h-screen pb-44;
}

.form-page-title {
  @apply font-sans font-bold text-black-base text-xl mt-12 sm:text-3xl mb-4 sm:mb-8;
}
</style>
