<template>
  <div class="bg-white rounded-md max-w-md p-6 pt-8 w-full shadow">
    <div class="flex items-center mb-2 w-full">
      <i v-if="isIcon" :class="['mr-2', faIconClass, iconColor]"></i>
      <h4 class="font-sans text-lg font-bold text-black-base">{{ title }}</h4>
    </div>
    <p class="font-sans text-black-lightest">
      {{ text }}
    </p>
    <div class="flex mt-8 justify-end w-full">
      <ButtonFrameless
        @click.native="$emit('cancelDialog')"
        text="No"
        class="px-3 mr-3"
      />
      <ButtonFrameless
        @click.native="$emit('approveDialog')"
        text="Yes"
        class="px-3"
      />
    </div>
  </div>
</template>
<script>
import ButtonFrameless from "@/components/Buttons/ButtonFrameless";
export default {
  name: "Dialog",
  components: {
    ButtonFrameless,
  },
  props: {
    title: {
      type: String,
      default: "Dialog title",
    },
    text: {
      type: String,
      default: "Are you sure you want to do this action?",
    },
    isIcon: {
      type: Boolean,
      default: true,
    },
    faIconClass: {
      type: String,
      default: "fas fa-exclamation-circle",
    },
    iconColor: {
      type: String,
      default: "text-grey-dark",
    },
  },
};
</script>