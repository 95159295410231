import Customers from "@/views/Customers/Customers";
import Customer from "@/views/Customers/Customer";
import Request from "@/views/Requests/Request";


export default [
    {
        path: "/customers",
        name: "Customers",
        component: Customers,
        meta: {requiresAuth: true},
    }, {
        path: "/customers/:id",
        name: "Customer",
        component: Customer,
        meta: {requiresAuth: true},
    },
    {
        path: "/customers/:customerId/requests/:requestId",
        name: "CustomerRequest",
        component: Request,
        props: true,
        meta: {requiresAuth: true},
    }
]