import api from "@/api";

export default {
    namespaced: true,
    state: {
        partners: [],
        selectedPartner: {},
        selectedPartnerOffers: [],
        isLoadingData: false,
        isSubmittingData: false,
        newPartner: {},
        submissionStatusMessage: '',
        submissionStatus: '',
    },
    getters: {
        partners(state) {
            return state.partners
        },
        approvedPartners(state) {
            return state.partners.filter((partner) => {
                return partner.status === "approved";
            });
        },
        newPartners(state) {
            return state.partners.filter((partner) => {
                return (!["approved", "rejected"].includes(partner.status));
            });
        },
        selectedPartner(state) {
            return state.selectedPartner;
        },
        selectedPartnerOffers(state) {
            return state.selectedPartnerOffers
        },

        isLoadingData(state) {
            return state.isLoadingData
        },

        isSubmittingData(state) {
            return state.isSubmittingData
        },
        submissionStatus(state) {
            return state.submissionStatus;
        },
        submissionStatusMessage(state) {
            return state.submissionStatusMessage;
        },
        newPartner(state) {
            return state.newPartner
        }
    },
    mutations: {
        SET_PARTNERS(state, value) {
            state.partners = value;
        },
        SET_SELECTED_PARTNER(state, value) {
            state.selectedPartner = value;
        },
        SET_SELECTED_PARTNER_OFFERS(state, value) {
            state.selectedPartnerOffers = value;
        },
        SET_IS_LOADING_DATA(state, value) {
            state.isLoadingData = value
        },
        SET_IS_SUBMITTING_DATA(state, value) {
            state.isSubmittingData = value
        },
        SET_SUBMISSION_STATUS(state, value) {
            state.submissionStatus = value;
        },
        SET_SUBMISSION_STATUS_MESSAGE(state, value) {
            state.submissionStatusMessage = value;
        },
        SET_NEW_PARTNER(state, value) {
            state.newPartner = value;
        },
        UPDATE_REJECTED_PARTNER(state, value) {
            let getIndex = state.partners.findIndex(partner => partner.id == value.id);
            state.partners.splice(getIndex, 1, value);
        }
    },
    actions: {
        getPartners({commit}) {
            commit('SET_IS_LOADING_DATA', true);
            api.get("/all-partners")
                .then(({data}) => {
                    commit('SET_PARTNERS', data.partners);
                    commit('SET_IS_LOADING_DATA', false);
                })
                .catch(() => {
                    commit('SET_IS_LOADING_DATA', false);
                });
        },
        getSelectedPartnerAndOffers({commit}, partnerID) {
            commit('SET_IS_LOADING_DATA', true);
            api.getAll([
                `/single-partner/${partnerID}`,
                `/partner-offers/${partnerID}`,
            ])
                .then((response) => {
                    let partner = response[0].data.partner;
                    let address = partner.companyAddress;
                    let addressSplit = address.split(",");
                    partner.companyStreet = addressSplit[0];
                    partner.companyLocation = addressSplit[1];
                    commit('SET_SELECTED_PARTNER', partner);
                    commit('SET_SELECTED_PARTNER_OFFERS', response[1].data.offers);
                    commit('SET_IS_LOADING_DATA', false);
                })
                .catch(() => {
                    commit('SET_IS_LOADING_DATA', false);
                });
        },
        getSelectedPartner({commit}, partnerID) {
            commit('SET_IS_LOADING_DATA', true);
            api.get(
                `/single-partner/${partnerID}`)
                .then((response) => {
                    let partner = response.data.partner;
                    let address = partner.companyAddress;
                    let addressSplit = address.split(",");
                    partner.companyStreet = addressSplit[0];
                    partner.companyLocation = addressSplit[1];
                    commit('SET_SELECTED_PARTNER', partner);
                    commit('SET_IS_LOADING_DATA', false);
                })
                .catch(() => {
                    commit('SET_IS_LOADING_DATA', false);
                });
        },
        async acceptNewPartner({commit}, partnerID) {
            commit('SET_IS_SUBMITTING_DATA', true);

            let request = await api.post(`/accept-new-partner/${partnerID}`);

            try {
                if (request.data) {
                    let data = request.data
                    if (data.response === "success") {
                        data.partner.approved = true;
                        commit('SET_SELECTED_PARTNER', data.partner)
                        commit('SET_IS_SUBMITTING_DATA', false);
                    }


                }

                return request;
            } catch (e) {
                commit('SET_IS_SUBMITTING_DATA', false);
                return request;
            }

        },
        async rejectNewPartner({commit}, partnerID) {
            commit('SET_IS_SUBMITTING_DATA', true);
            let request = api.post(`/reject-new-partner/${partnerID}`);

            try {

                if (request.data) {
                    let data = request.data
                    if (data.response === "success") {
                        data.partner.approved = false;
                        data.partner.status = 'rejected'
                        commit('SET_SELECTED_PARTNER', data.partner);
                        commit('UPDATE_REJECTED_PARTNER', data.partner);
                        commit('SET_IS_SUBMITTING_DATA', false);
                    }
                } else {
                    commit('SET_IS_SUBMITTING_DATA', false);
                }
                return request;
            } catch (e) {
                commit('SET_IS_SUBMITTING_DATA', false);
                return request;
            }
        },
        addNewPartner({commit}, formData) {
            commit('SET_IS_SUBMITTING_DATA', true);
            api.post("/new-partner", formData, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
                .then(({data}) => {
                    commit('SET_IS_SUBMITTING_DATA', false);
                    commit('SET_NEW_PARTNER', data.data);
                    commit('SET_SUBMISSION_STATUS', data.response);
                    commit('SET_SUBMISSION_STATUS_MESSAGE', data.message);

                })
                .catch((error) => {
                    commit('SET_IS_SUBMITTING_DATA', false);
                    commit('SET_SUBMISSION_STATUS', 'failed');
                    commit('SET_SUBMISSION_STATUS_MESSAGE', error.response.data.message);
                });
        },
        editPartner({commit}, formData) {
            api.post("/update-partner", formData, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
                .then(({data}) => {
                    commit('SET_IS_SUBMITTING_DATA', false);
                    commit('SET_SELECTED_PARTNER', data.data);
                    commit('SET_SUBMISSION_STATUS', data.response);
                    commit('SET_SUBMISSION_STATUS_MESSAGE', data.message);
                })
                .catch((error) => {
                    commit('SET_IS_SUBMITTING_DATA', false);
                    commit('SET_SUBMISSION_STATUS', 'failed');
                    commit('SET_SUBMISSION_STATUS_MESSAGE', error.response.data.message);
                });
        }
    }
}