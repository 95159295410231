<template>
  <component v-bind:is="controlRouteFromParent?'button':'router-link'"
             :to="{ name: linkName }"
             replace
             class="w-full"
             :class="['item', widthStyle,controlRouteFromParent&&isActive?'activeItem':statusStyle]"
             @click="controlRouteFromParent?$emit('redirectTo',linkName):$event.default"
  >
    <span class="item-number">{{ index + 1 }}</span>
    <span class="item-text">{{ text }}</span>
  </component>
</template>
<script>
export default {
  name: "MultiStepItem",
  props: {
    text: {
      type: String,
      default: "Text",
    },
    linkName: {
      type: String,
    },
    index: {
      type: Number,
      default: 1,
    },
    isActive: {
      type: Boolean,
      default: true,
    },
    statusStyle: {
      type: String,
      default: "border border-gray-dark text-black-lightest hover:bg-grey-base ",
    },
    widthStyle: {
      type: String,
      default: "col-span-1",
    },
    controlRouteFromParent: {
      type: Boolean,
      default: false,
    }
  },
};
</script>
<style scoped>
.item {
  @apply text-xs text-center;
  @apply duration-300 transition ease-in-out;
  @apply p-2 focus:outline-none rounded-md;
}

.item-number {
  @apply block;
}

.item-text {
  @apply truncate hidden sm:block w-full;
}

.activeItem{
  @apply bg-teal-darker text-white hover:bg-teal-base;
}
</style>