<template>
  <div id="summary" class="">
    <section class="offer-summary">
      <h1 class="page-title">Tour summary</h1>
      <Banner
          v-if="isLastStep"
          class="mt-4"
          iconColor="text-teal-base"
          customStyle="bg-teal-lighter"
          title="Offer review"
          text="Make sure to review the offer before sending it to the customer."
      />
      <Gallery
          class="mt-4"
          @click.native="openLightBox(true, photosInItinerary)"
          :mainPhoto="photosInItinerary[0]"
          :sidePhotoTop="photosInItinerary[1]"
          :sidePhotoBottom="photosInItinerary[2]"
          :showCalltoAction="photosInItinerary.length != 0"
      />
      <h2 class="section-title">{{ tourDetails.title }}</h2>
      <div class="flex mt-2 text-sm details text-black-lighter">
        <div class="destination">{{ tourDetails.destination }}</div>
        <div class="px-3 divider">|</div>
        <div class="duration">
          {{ getTotalDays() }} {{ grammatical_number_days }}
        </div>
        <div class="px-3 divider">|</div>
        <div class="max-group-size">Max group size: {{tourDetails.maxGroupSize}}</div>
        <div class="px-3 divider">|</div>
        <div class="min-age">Min Age: {{tourDetails.minAge}}</div>
      </div>

      <div class="flex justify-between mt-6">
        <span class="font-sans text-sm font-semibold text-black-base">
          Tour ID. {{ tourDetails.id }}
        </span>
        <div
            class="font-sans font-semibold text-right total-price text-black-base"
        >
        </div>
      </div>
      <Divider class="w-full mt-6 mb-4"/>
      <template v-if="tripPartnerDetails.length > 0">
        <template v-for="(tripPartnerDetail, index) in tripPartnerDetails">
          <div
              class="flex items-center w-full agency"
              v-if="tripPartnerDetail"
              :key="index"
          >
            <figure
                class="relative w-24 h-24 mr-3 overflow-hidden rounded-full"
            >
              <img
                  class="absolute object-cover w-full h-full"
                  :src="`${basePath}${tripPartnerDetail.imgPath}`"
                  alt="agent_name"
              />
            </figure>
            <div class="agency-details">
              <div class="font-sans text-xs leading-tight text-black-lighter">
                Offered by
              </div>
              <div
                  class="font-sans font-semibold leading-tight text-black-base"
              >
                {{ tripPartnerDetail.title }}
              </div>
              <div class="font-sans leading-tight text-black-base">
                from {{ tripPartnerDetail.subTitle }}
              </div>
            </div>
          </div>
        </template>
      </template>
      <Divider class="w-full mt-4"/>
      <div id="itinerary">
        <h2 class="section-title">Itinerary</h2>
        <DayBlock
            v-for="(itinerary, index) in offer"
            :key="index"
            :itinerary="itinerary"
            :title="`Day ${index + 1}: ${itinerary.title}`"
            @showPhotos="showAccommodationPhotos"
        />

        <h2 class="section-title">Price details</h2>
        <div class="mt-8">
          <p>Single room price : {{ tourDetails.currency }} {{ tourDetails.singleRoomPrice }}</p>
          <p>Shared room price : {{ tourDetails.currency }} {{ tourDetails.sharedRoomPrice }}</p>
        </div>

        <CancellationBlock
            :noRefundDays="tourDetails.noRefundDays"
            :partialRefund="tourDetails.partialRefund"
            :partialRefundDays="tourDetails.partialRefundDays"
            :fullRefundDays="tourDetails.fullRefundDays"
            class="pb-32"/>
      </div>
    </section>
    <div class="bar" v-if="isLastStep">
      <div class="wrapper flex max-w-5xl w-full justify-end">
        <template v-if="!isLoading">
          <ButtonPrimary
              text="Save Tour"
              class="block px-8 ml-auto"
              @click.native="$emit('submitClicked')"
          />
        </template>
        <template v-else>
          <Loading margin="my-0 ml-2" :showLabel="true" label="Saving Offer"/>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import Banner from "@/components/Banners/Banner";
import ButtonPrimary from "@/components/Buttons/ButtonPrimary";
import CancellationBlock from "@/components/Blocks/CancellationBlock";
import DayBlock from "@/components/Blocks/DayBlock";
import Divider from "@/components/Layout/Divider";
import Gallery from "@/components/Gallery/Gallery";
import {mapActions, mapGetters} from "vuex";
import Loading from "@/components/Preloaders/Loading";

const url = process.env.VUE_APP_FILE_BASE_PATH;
import {getStartDate, getNumDaysFromString, checkIfDatesAreEqual,generatedArrayOfDatesFromDateAndDuration} from "@/shared/dateHandler";

export default {
  name: "TourSummary",
  components: {
    Loading,
    Banner,
    ButtonPrimary,
    CancellationBlock,
    DayBlock,
    Divider,
    Gallery,
  },
  props: {
    dates: {
      type: String,
      required: false
    },
    tourDetails: {
      type: Object,
    },
    isLastStep: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    agent_img: {
      type: String,
      default:
          "https://image.freepik.com/free-photo/cheerful-carefree-black-man-dances-with-raised-fists-wears-transparent-glasses_273609-37390.jpg",
    },
    agent_name: {
      type: String,
      default: "Agent's name",
    },
    agency: {
      type: String,
      default: "Agency",
    },
  },
  data() {
    return {
      isLightbox: false,
      photos: [],
    };
  },
  mounted() {
    this.getPartners();
  },
  methods: {
    ...mapActions({
      setShowLightBox: "lightBox/setLightBoxStatus",
      setLightBoxPhotos: "lightBox/setLightBoxPhotos",
      getPartners: "partners/getPartners",
    }),
    openLightBox(boolean, photos) {
      this.isLightbox = boolean;
      this.photos = photos;
      this.setShowLightBox(true);
      this.setLightBoxPhotos(photos);
    },
    closeLightbox() {
      this.isLightbox = false;
      this.photos = [];
      this.setShowLightBox(false);
      this.setLightBoxPhotos([]);
    },
    formatToCurrency(value) {
      value = new Intl.NumberFormat(this.region, {
        style: "currency",
        currency: this.tourDetails.currency || "USD",
      }).format(value);
      return value;
    },
    getTotalDays() {
      return getNumDaysFromString(this.dates)
    },
    setDates() {
      //Create array with itinerary dates
      let startDate = getStartDate(this.dates).toDate();
      let totalDays = this.getTotalDays();
      let arr = [];
      for (let i = 0; i < totalDays; i++) {
        let newDate = `${startDate.toLocaleString("en-UK", {
          weekday: "short",
          day: "numeric",
          month: "long",
          year: "numeric",
        })}`;
        arr.push({date: newDate});
        startDate.setDate(startDate.getDate() + 1);
      }
      return arr;
    },
    setItinerary() {
      let itinerary = this.tourDetails.itinerary;
      let obj = this.setDates();
      obj.forEach((item) => {

        const currentItem = itinerary.find(i => checkIfDatesAreEqual(i.fullDate, item.date));
        if (currentItem) {
          item.id = currentItem.id;
          item.title = currentItem.title;
          item.experiences = currentItem.experiences;
        }
      });

      return obj;
    },
    calculateDiff(checkin_date, checkout_date) {
      let checkin = new Date(checkin_date);
      let checkout = new Date(checkout_date);
      let diff = checkout - checkin;
      let day = 1000 * 60 * 60 * 24;
      diff = Math.round((diff + day) / day);
      return diff;
    },
    setAccommodationToDates(
        current_date,
        datesArray,
        accommodation,
        itinerary_obj
    ) {
      for (let i = 0; i < datesArray.length; i++) {
        let date = datesArray[i];
        if (checkIfDatesAreEqual(date,current_date)) {
          itinerary_obj.accommodation = accommodation;
        }
      }
    },
    getCurrentAccommodationDates(days, checkin_date) {
      let arr = [];
      let startDate = new Date(checkin_date);
      //Get dates of the current accommodation
      if (days >= 1) {
        for (let j = 0; j < days; j++) {
          let date = `${startDate.toLocaleString("en-UK", {
            weekday: "short",
            day: "numeric",
            month: "long",
            year: "numeric",
          })}`;
          arr.push(date);
          startDate.setDate(startDate.getDate() + 1);
        }
      }
      return arr;
    },
    setAccommodations() {
      let accommodations = this.tourDetails.accommodation;
      let itinerary = this.setItinerary();
     accommodations.forEach(accommodation => {
        let dates = generatedArrayOfDatesFromDateAndDuration(accommodation.fullCheckinDate, accommodation.nights);
        itinerary.forEach(day => {
          dates.forEach(date => {
            if (checkIfDatesAreEqual(date.date, day.date)) {
              day.accommodation = accommodation;
            }
          })
        })
      })
      return itinerary;
    },
    showAccommodationPhotos(paths) {
      let routes = paths.split(",");
      let arr = [];
      for (let route of routes) {
        let path_split = route.split("/");
        arr.push({
          img: `${url}/${route}`,
          alt: path_split[4],
        });
      }

      this.openLightBox(true, arr);
    },
    replace(str) {
      return str.replace(/-/g, " ");
    },
  },
  computed: {
    ...mapGetters({
      approvedPartners: "partners/approvedPartners",
    }),
    partners() {
      let results = [];
      this.approvedPartners.forEach((element) => {
        let partner = {
          id: element.id,
          imgPath: element.profile_photo,
          title: element.company_name,
          subTitle: element.first_name + " " + element.last_name,
          showItem: false,
          showCheckMark: false,
        };
        results.push(partner);
      });
      return results;
    },
    basePath() {
      return process.env.VUE_APP_FILE_BASE_PATH;
    },
    daysInDates() {
      const date1 = this.tourDetails.dates.split("-")[0].trim();
      const date2 = this.tourDetails.dates.split("-")[1].trim();

      return this.calculateDiff(date1, date2);
    },
    photosInItinerary() {
      let itineraryRawData = [];
      if (this.tourDetails.itinerary) {
        if (typeof this.tourDetails.itinerary === "string") {
          itineraryRawData = JSON.parse(this.tourDetails.itinerary);
        } else {
          itineraryRawData = this.tourDetails.itinerary;
        }
      }

      const itineraries = itineraryRawData.map(
          (itinerary) => itinerary.experiences
      );
      const photos = itineraries.map((itinerary) =>
          itinerary.map((photo) => photo.photo.split(","))
      );
      const soo = photos.flat(2);

      let a = [];
      soo.forEach((e) => {
        if (e) {
          let array = e.split("/"); // Split image path and put splits into array
          a.push({
            img: `${process.env.VUE_APP_FILE_BASE_PATH}/${e}`,
            alt: this.replace(array[4]), //Check if e is available and access 5th array element
          });
        }
      });
      return a;
    },
    accommodations() {
      return this.tourDetails.accommodation;
    },
    offer() {
      return this.setAccommodations();
    },
    tripDates() {
      let accommodation = this.tourDetails.accommodation;
      const trip = this.tourDetails.itinerary;
      const myTrips = [];
      trip.map((acc) => {
        const experience = accommodation.filter((exp) => {
          const date = new Date(acc.fullDate);
          return (
              date >= new Date(exp.fullCheckinDate) &&
              date < new Date(exp.fullCheckoutDate)
          );
        });
        myTrips.push({accommodation: experience, itinerary: acc});
      });
      return myTrips;
    },
    total_price() {
      return this.formatToCurrency(this.tourDetails.total_price);
    },
    grammatical_number_adults() {
      if (this.tourDetails.adults == 1) return "adult";
      return "adults";
    },
    comma() {
      if (this.tourDetails.children > 0) return ",";
      return "";
    },
    grammatical_number_children() {
      if (this.tourDetails.children == 1 && this.tourDetails.children > 0)
        return "child";

      if (this.tourDetails.children != 1 && this.tourDetails.children > 0)
        return "children";
      return "";
    },
    grammatical_number_days() {
      if (this.tourDetails.days == 1) return "day";
      return "days";
    },
    tripPartnerDetails() {
      let agencies = [];
      if (this.tourDetails.agencies && this.tourDetails.agencies.length > 0) {
        agencies = [];
        this.tourDetails.agencies.forEach((agent) => {
          agencies.push(this.partners.find((x) => x.id === agent));
        });
      } else {
        let dummy = {
          id: 0,
          imgPath: "/img/partners/Travel-Afrique-Botswana/profile_image.jpeg",
          showCheckMark: false,
          showItem: false,
          subTitle: "Travel Agent",
          title: "Agent",
        };
        agencies.push(dummy);
      }
      return agencies;
    },
  },

};
</script>
<style scoped>
.sheet {
  @apply bg-white px-4 overflow-y-auto pb-20;
}

.navbar {
  @apply max-w-screen-sm fixed z-30 left-1/2 transform -translate-x-1/2 w-full py-4;
}

.btn-close {
  @apply absolute right-4;
}

.offer-summary {
  @apply max-w-screen-sm relative left-1/2 transform -translate-x-1/2 sm:px-4 w-full mt-20;
}

.page-title {
  @apply text-3xl font-semibold;
}

.section-title {
  @apply mt-12 font-sans text-2xl font-semibold;
}

.bar {
  @apply inline-block bg-white sticky z-10 left-1/2 transform -translate-x-1/2 p-2 rounded-lg border -bottom-16 border-grey-base shadow-md;
}
</style>