<template>
  <div id="summary" class="">
    <section class="offer-summary">
      <h1 class="page-title">Offer summary</h1>
      <Banner
        v-if="isLastStep"
        class="mt-4"
        iconColor="text-teal-base"
        customStyle="bg-teal-lighter"
        title="Offer review"
        text="Make sure to review the offer before sending it to the customer."
      />
      <Gallery
        class="mt-4"
        @click.native="openLightBox(true, photosInItinerary)"
        :mainPhoto="photosInItinerary[0]"
        :sidePhotoTop="photosInItinerary[1]"
        :sidePhotoBottom="photosInItinerary[2]"
        :showCalltoAction="photosInItinerary.length != 0"
      />
      <h2 class="section-title">{{ offer.tripTitle }}</h2>
      <div class="flex mt-2 text-sm details text-black-lighter">
        <div class="destination">{{ offer.tripDestination }}</div>
        <div class="px-3 divider">|</div>
        <div class="travelers">
          {{ offer.tripAdults }} {{ grammaticalNumberAdults }}{{ comma }}
          {{ offer.tripChildren > 0 ? offer.tripChildren : "" }}
          {{ grammaticalNumberChildren }}
        </div>
        <div class="px-3 divider">|</div>
        <div class="duration">
          {{ getTotalDays() }} {{ grammaticalNumberDays }}
        </div>
      </div>

      <div class="flex justify-between mt-6">
        <span class="font-sans text-sm font-semibold text-black-base">
          Reference Nr. {{ offer.tripRequestId }}
        </span>
        <div
          class="font-sans font-semibold text-right total-price text-black-base"
        >
          <div class="text-sm label">Total Price</div>
          <div class="text-lg price">{{ tripTotalPrice }}</div>
        </div>
      </div>
      <Divider class="w-full mt-6 mb-4" />
      <template v-if="tripPartnerDetails.length > 0">
        <template v-for="(tripPartnerDetail, index) in tripPartnerDetails">
          <div
            class="flex items-center w-full agency"
            v-if="tripPartnerDetail"
            :key="index"
          >
            <figure
              class="relative w-24 h-24 mr-3 overflow-hidden rounded-full"
            >
              <img
                class="absolute object-cover w-full h-full"
                :src="`${basePath}${tripPartnerDetail.imgPath}`"
                alt="tripAgentName"
              />
            </figure>
            <div class="agency-details">
              <div class="font-sans text-xs leading-tight text-black-lighter">
                Offered by
              </div>
              <div
                class="font-sans font-semibold leading-tight text-black-base"
              >
                {{ tripPartnerDetail.title }}
              </div>
              <div class="font-sans leading-tight text-black-base">
                from {{ tripPartnerDetail.subTitle }}
              </div>
              <!-- DO NOT DELETE -->
              <!--<div class="flex items-center w-full mt-1 review">
                <div class="mr-2 text-xs stars text-teal-base">
                  <i class="mr-1 fas fa-star-of-life"></i>
                  <i class="mr-1 fas fa-star-of-life"></i>
                  <i class="mr-1 fas fa-star-of-life"></i>
                  <i class="mr-1 fas fa-star-of-life"></i>
                  <i class="fas fa-star-of-life"></i>
                </div>
                <button class="font-sans text-xs underline text-black-lighter">
                  144 reviews
                </button>
              </div> -->
            </div>
          </div>
        </template>
      </template>
      <Divider class="w-full mt-4" />
      <div id="itinerary">
        <h2 class="section-title">Itinerary</h2>
        <DayBlock
          v-for="(itinerary, index) in tripOffer"
          :key="index"
          :itinerary="itinerary"
          :title="`Day ${index + 1}${
            itinerary.title ? ': ' + itinerary.title : ''
          }`"
          @showPhotos="showAccommodationPhotos"
        />
        <PricingBlock :offer="offer" :selectedCurrencyRates="selectedCurrencyRates"  />
        <CancellationBlock
          :noRefundDays="offer.tripNoRefundDays"
          :partialRefund="offer.tripPartialRefund"
          :partialRefundDays="offer.tripPartialRefundDays"
          :fullRefundDays="offer.tripFullRefundDays"
          class="pb-32"
        />
      </div>
    </section>
    <div class="bar" v-if="isLastStep">
      <div class="wrapper flex max-w-5xl w-full justify-end">
        <template v-if="!isLoading">
          <ButtonPrimary
            text="Send offer to customer"
            class="block px-8 ml-auto"
            @click.native="$emit('submitClicked')"
          />
        </template>
        <template v-else>
          <Loading margin="my-0 ml-2" :showLabel="true" label="Saving Offer" />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import Banner from "@/components/Banners/Banner";
import ButtonPrimary from "@/components/Buttons/ButtonPrimary";
import CancellationBlock from "@/components/Blocks/CancellationBlock";
import DayBlock from "@/components/Blocks/DayBlock";
import Divider from "@/components/Layout/Divider";
import Gallery from "@/components/Gallery/Gallery";
import PricingBlock from "@/components/Blocks/PricingBlock";
import { mapActions, mapGetters } from "vuex";
import Loading from "@/components/Preloaders/Loading";

const url = process.env.VUE_APP_FILE_BASE_PATH;
import {
  getNumDaysFromString,
  generatedArrayOfDatesFromDates,
  checkIfDatesAreEqual,
  generatedArrayOfDatesFromDateAndDuration,
} from "@/shared/dateHandler";

export default {
  name: "OfferSummary",
  components: {
    Loading,
    Banner,
    ButtonPrimary,
    CancellationBlock,
    DayBlock,
    Divider,
    Gallery,
    PricingBlock,
  },
  props: {
    dates: {
      type: String,
      required: false,
    },
    offer: {
      type: Object,
    },
    tripTitle: {
      type: String,
      default: "Trip title",
    },
    isLastStep: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    tripCurrency: {
      type: String,
      default: "$",
    },
    tripPrice: {
      type: Number,
      default: 1000,
    },
    tripCountry: {
      type: String,
      default: "Trip country",
    },
    tripAdults: {
      type: Number,
      default: 1,
    },
    tripChildren: {
      type: Number,
      default: 0,
    },
    tripId: {
      type: Number,
      default: 1,
    },
    tripDays: {
      type: Number,
      default: 7,
    },
    tripAgentImg: {
      type: String,
      default:
        "https://image.freepik.com/free-photo/cheerful-carefree-black-man-dances-with-raised-fists-wears-transparent-glasses_273609-37390.jpg",
    },
    tripAgentName: {
      type: String,
      default: "Agent's name",
    },
    tripAgency: {
      type: String,
      default: "Agency",
    },
  },
  data() {
    return {
      isLightbox: false,
      photos: [],
    };
  },
  mounted() {
    this.getPartners();
  },
  methods: {
    ...mapActions({
      setShowLightBox: "lightBox/setLightBoxStatus",
      setLightBoxPhotos: "lightBox/setLightBoxPhotos",
      getPartners: "partners/getPartners",
    }),
    
    openLightBox(boolean, photos) {
      this.isLightbox = boolean;
      this.photos = photos;
      this.setShowLightBox(true);
      this.setLightBoxPhotos(photos);
    },
    closeLightbox() {
      this.isLightbox = false;
      this.photos = [];
      this.setShowLightBox(false);
      this.setLightBoxPhotos([]);
    },
    formatToCurrency(value) {
      value = new Intl.NumberFormat(this.region, {
        style: "currency",
        currency: this.offer.tripCurrency || "USD",
      }).format(value);
      return value;
    },
    getTotalDays() {
      return getNumDaysFromString(this.dates);
    },
    setItinerary() {
      let obj = generatedArrayOfDatesFromDates(this.dates);
      obj.forEach((dateItem) => {
        const currentItem = this.offer.tripItinerary.find((i) =>
          checkIfDatesAreEqual(i.fullDate, dateItem.date)
        );
        if (currentItem) {
          dateItem.id = currentItem.id;
          dateItem.title = currentItem.title;
          dateItem.experiences = currentItem.experiences;
        }
      });
      return obj;
    },
    setAccommodations() {
      let itinerary = this.setItinerary();
      this.offer.tripAccommodationDetails.forEach((accommodation) => {
        let dates = generatedArrayOfDatesFromDateAndDuration(
          accommodation.fullCheckinDate,
          accommodation.nights
        );
        itinerary.forEach((itinerary) => {
          dates.forEach((date) => {
            if (checkIfDatesAreEqual(date.date, itinerary.date)) {
              itinerary.accommodation = accommodation;
            }
          });
        });
      });

      return itinerary;
    },
    showAccommodationPhotos(paths) {
      let routes = paths.split(",");
      let arr = [];
      for (let route of routes) {
        let pathSplit = route.split("/");
        arr.push({
          img: `${url}/${route}`,
          alt: pathSplit[4],
        });
      }

      this.openLightBox(true, arr);
    },
    replace(str) {
      return str.replace(/-/g, " ");
    },
  },
  computed: {
    ...mapGetters({
      approvedPartners: "partners/approvedPartners",
      exchangeRates: "offer/exchangeRates",
    }),
    selectedCurrencyRates() {
      if (Object.keys(this.exchangeRates).length) {
        return JSON.parse(JSON.stringify(this.exchangeRates[`ratesCHF`]));
      } else {
        return {};
      }
    },
    partners() {
      let results = [];
      this.approvedPartners.forEach((element) => {
        let partner = {
          id: element.id,
          imgPath: element.profilePhoto,
          title: element.companyName,
          subTitle: element.firstName + " " + element.lastName,
          showItem: false,
          showCheckMark: false,
        };
        results.push(partner);
      });
      return results;
    },
    basePath() {
      return process.env.VUE_APP_FILE_BASE_PATH;
    },
    photosInItinerary() {
      let itineraryRawData = [];
      if (this.offer.tripItinerary) {
        if (typeof this.offer.tripItinerary === "string") {
          itineraryRawData = JSON.parse(this.offer.tripItinerary);
        } else {
          itineraryRawData = this.offer.tripItinerary;
        }
      }

      const itineraries = itineraryRawData.map(
        (itinerary) => itinerary.experiences
      );
      const photos = itineraries.map((itinerary) =>
        itinerary.map((photo) => photo.photo.split(","))
      );
      const soo = photos.flat(2);

      let a = [];
      soo.forEach((e) => {
        if (e) {
          let array = e.split("/"); // Split image path and put splits into array
          a.push({
            img: `${process.env.VUE_APP_FILE_BASE_PATH}/${e}`,
            alt: this.replace(array[4]), //Check if e is available and access 5th array element
          });
        }
      });
      return a;
    },
    accommodations() {
      return this.offer.tripAccommodationDetails;
    },
    tripOffer() {
      return this.setAccommodations();
    },
    tripTotalPrice() {
      return this.formatToCurrency(this.offer.tripTotalPrice);
    },
    grammaticalNumberAdults() {
      if (this.offer.tripAdults == 1) return "adult";
      return "adults";
    },
    comma() {
      if (this.offer.tripChildren > 0) return ",";
      return "";
    },
    grammaticalNumberChildren() {
      if (this.offer.tripChildren == 1 && this.offer.tripChildren > 0)
        return "child";

      if (this.offer.tripChildren != 1 && this.offer.tripChildren > 0)
        return "children";
      return "";
    },
    grammaticalNumberDays() {
      if (this.offer.tripDays == 1) return "day";
      return "days";
    },
    tripPartnerDetails() {
      let agencies = [];
      if (this.offer.tripAgencies && this.offer.tripAgencies.length > 0) {
        agencies = [];
        this.offer.tripAgencies.forEach((agent) => {
          agencies.push(this.partners.find((x) => x.id === agent));
        });
      } else {
        let dummy = {
          id: 0,
          imgPath: "/img/partners/Travel-Afrique-Botswana/profile_image.jpeg",
          showCheckMark: false,
          showItem: false,
          subTitle: "Travel Agent",
          title: "Agent",
        };
        agencies.push(dummy);
      }
      return agencies;
    },
  },
};
</script>
<style scoped>
.sheet {
  @apply bg-white px-4 overflow-y-auto pb-20;
}

.navbar {
  @apply max-w-screen-sm fixed z-30 left-1/2 transform -translate-x-1/2 w-full py-4;
}

.btn-close {
  @apply absolute right-4;
}

.offer-summary {
  @apply max-w-screen-sm relative left-1/2 transform -translate-x-1/2 sm:px-4 w-full mt-20;
}

.page-title {
  @apply text-3xl font-semibold;
}

.section-title {
  @apply mt-12 font-sans text-2xl font-semibold;
}

.bar {
  @apply inline-block bg-white sticky z-10 left-1/2 transform -translate-x-1/2 p-2 rounded-lg border -bottom-16 border-grey-base shadow-md;
}
</style>
