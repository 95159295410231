<template>
  <div id="hotelsheet" class="sheet">
    <nav class="navbar">
      <ButtonClose @click.native="$emit('closeSheet')" class="btn-close"/>
    </nav>
    <div class="wrapper">
      <h2 class="font-semibold text-black-base text-2xl mt-6 mb-6">
        Add a new hotel
      </h2>
      <form class="w-full">
        <InputText
            label="Hotel Name"
            placeholder="e.g. Marriott Hotel"
            name="hotel-name"
            v-model="hotel.name"
            class="mb-6"
        />
        <InputText
            label="Hotel Address"
            placeholder="e.g. Accra, Ghana"
            name="hotel-name"
            class="mb-6"
            v-model="hotel.address"
        />
        <SelectDropdown
            description="Select a country"
            :showLabel="true"
            label="Country"
            class="mb-6"
            name="country"
            :optionsList="$africanDestinations"
            v-model="hotel.country"
        />
        <TextArea
            label="Hotel description"
            placeholder="Describe the hotel"
            name="hote-description"
            v-model="hotel.description"
        />

        <InputUpload
            name="hotel-photos"
            faIconClass="far fa-camera-retro"
            label="Hotel photos"
            class="mt-6"
            title="Upload photos here"
            text="Only .jpg, .jpeg and .png allowed"
            allowedFileTypes="image/x-png,image/jpg,image/jpeg"
            v-model="hotel.photos"
        />

        <template v-if="isSubmittingData">
          <div class="s:w-64 block ml-auto py-2">
            <Loading margin="my-0" :showLabel="true" label="Adding Accommodation"/>
          </div>
        </template>
        <template v-else>
          <ButtonPrimary
              text="Submit"
              class="mt-6 ml-auto block px-12"
              @click.native="submitAccommodation"
          />
        </template>

      </form>
    </div>
  </div>
</template>
<script>
import ButtonClose from "@/components/Buttons/ButtonClose";
import {ButtonPrimary, InputText, InputUpload, SelectDropdown, TextArea, Loading} from "@/components";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "HotelSheet",
  components: {
    Loading,
    ButtonClose,
    ButtonPrimary,
    InputText,
    InputUpload,
    SelectDropdown,
    TextArea,
  },
  props: {
    destination: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      hotel: {
        name: "",
        description: "",
        address: "",
        country: "",
        photos: [],
      },
      hotelStars: [
        {value: "1", name: "1"},
        {value: "2", name: "2"},
        {value: "3", name: "3"},
        {value: "4", name: "4"},
        {value: "5", name: "5"},
      ],
    };
  },

  methods: {
    ...mapActions({
      addNewAccommodation: 'accommodations/addNewAccommodation',
      getAccommodations: 'accommodations/getAccommodations',
    }),
    submitAccommodation() {
      let formData = new FormData();
      for (let key in this.hotel) {
        //some filtering is going on here to reduce the request size
        if (key === "photos") {
          for (let i = 0; i < this.hotel.photos.length; i++) {
            formData.append("photos[" + i + "]", this.hotel.photos[i]);
          }
        } else {
          formData.append(key, this.hotel[key]);
        }
      }
      this.addNewAccommodation(formData).then(() => {
        this.hotel = {
          name: "",
          description: "",
          address: "",
          country: "",
          photos: [],
        }
      }).then(() => {
        this.getAccommodations(this.destination);
      })
    },
  },
  computed: {
    ...mapGetters({
      isSubmittingData: 'accommodations/isSubmittingData',
      submissionStatusMessage: 'accommodations/submissionStatusMessage',
      submissionStatus: 'accommodations/submissionStatus',
      newAccommodation: 'accommodations/newAccommodation',
    }),
  },
  watch: {
    newAccommodation(newVal) {
      this.$emit('newAccommodationAdded', `${newVal.name}-${newVal.country}-${newVal.createdAt}`)
    },
    submissionStatus(newVal) {
      if (newVal === 'success') {
        this.$notify({
          type: "success",
          title: "Success",
          text: "The accommodation was added successfully",
        });
        this.hotel = {
          name: "",
          description: "",
          address: "",
          country: "",
          photos: [],
        };
      } else {
        this.$notify({
          type: "error",
          title: "OOPS! Something went wrong",
          text: "Please check and ensure all experience form fields are filled",
        });
      }
    }
  },
};
</script>
<style scoped>
.sheet {
  @apply bg-white px-4 overflow-y-auto pb-20;
}

.navbar {
  @apply max-w-screen-sm fixed z-30 left-1/2 transform -translate-x-1/2 w-full py-4;
}

.btn-close {
  @apply absolute right-4 sm:right-0;
}

.wrapper {
  @apply max-w-screen-sm relative left-1/2 transform -translate-x-1/2 mt-20;
}
</style>
