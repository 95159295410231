<template>
  <div id="table" class="relative">
    <div :class="['t-header', gridCols]">
      <template v-for="(title, index) in columns">
        <template v-if="title.toLowerCase() == 'id' && !showID"></template>

        <template v-else>
          <div :key="index" :class="['t-header-title', colSpan(title)]">
            {{ title }}
          </div>
        </template>
      </template>
    </div>

    <slot></slot>


  </div>
</template>

<script>
export default {
  name: "Table",
  props: {
    columns: {
      type: Array,
      default: () => [
        "Title 1",
        "Title 2",
        "Title 3",
        "Title 4",
        "Title 5",
        "Title 6",
      ],
    },
    showID: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    data: {
      type: Array,
      default: () => [],
    },
    gridCols: {
      type: String,
      default: "grid-cols-12",
    },
  },
  methods: {
    colSpan(title) {
      let colSpan =
          !title ||
          ["image", "img", "pic", "avatar"].includes(title.toLowerCase())
              ? "col-span-1"
              : "col-span-2";

      return colSpan;
    },
  },
  computed: {},
};
</script>
<style scoped>
.t-header {
  @apply hidden sm:grid px-6 grid-flow-col gap-4 mt-8 pb-3 w-full;
}

.t-header-title {
  @apply text-xs uppercase font-sans text-black-lighter;
}
</style>