<template>
  <router-link :to="route" class="btn-link">
    {{ text }}
  </router-link>
</template>
<script>

export default {
  name: "ButtonLink",
  props: {
    route: {
      type: String,
      default: "/",
    },
    text: {
      type: String,
      default: "Button",
    },
  },
};
</script>
<style scoped>
.btn-link {
  @apply bg-black-base border-black-lighter h-11 px-3 hover:bg-black-lighter text-white shadow rounded border;
  @apply transition duration-300 ease-in-out;
  @apply focus:ring-black-base focus:border-black-lighter focus:outline-none focus:scale-95 focus:ring-offset-2 focus:ring-2;
  @apply flex justify-center items-center;
  @apply text-xs sm:text-base;
}
</style>