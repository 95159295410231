import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "@/assets/css/inter.css";
import "@/assets/css/tailwind.css";
import "@/assets/icons/fontawesome/css/all.min.css";
import VueTimeago from "vue-timeago";
import 'es6-promise/auto';
import Vuex from "vuex";
import store from "./store";
import Notifications from "vue-notification";
import 'boxicons';
import "@/assets/icons/boxicons/css/boxicons.min.css";
import Countries from "@/data/countries/destinations.json";
import Languages from "@/data/languages/languages.json";
import VueMeta from 'vue-meta';
import { extend, localize } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en.json";
Vue.use(Notifications);
Vue.use(VueTimeago, {
  name: "Timeago", // Component name, `Timeago` by default
  locale: "en", // Default locale
});
Vue.config.productionTip = false;
Vue.use(Vuex);
Vue.use(VueMeta);
Vue.prototype.$africanDestinations = Countries.en;
Vue.prototype.$officialLanguages = Languages;

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize("en", en);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
