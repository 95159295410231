<template>
  <PageComponent title="Partners">
    <template #actions>
      <ButtonLink text="Create a partner" route="/partners/create"/>
    </template>
    <div>
      <Tab :tabItems="tabItems"/>
      <template v-if="isLoadingData">
        <Loading/>
      </template>
      <template v-else>
        <div v-show="tabItems[0].isActive">
          <div>
            <template v-if="approvedPartners.length > 0">
              <Table
                  :columns="columns"
                  :showID="false"
                  gridCols="grid-cols-8"
              >
                <template v-if="approvedPartners">
                  <router-link
                      v-for="partner in approvedPartners"
                      :to="{ name: 'Partner', params: { id: partner.id } }"
                      :key="partner.id"
                      class="t-row"
                  >
                    <div class="row-mb">
                      <div class="w-full">
                        <div class="row-mb-header">
                          <timeago
                              class="row-mb-date"
                              :datetime="partner.createdAt"
                              :auto-update="60"
                          ></timeago>
                        </div>
                        <div class="row-mb-name">
                          {{ partner.companyName }}
                        </div>
                        <div class="row-mb-text">{{ partner.phone }}</div>
                      </div>
                      <div class="row-mb-icon-container">
                        <i
                            class="
                                bx bx-chevron-right bx-sm
                                absolute
                                bottom-0
                              "
                        ></i>
                      </div>
                    </div>
                    <div class="t-col font-semibold">
                      {{ partner.companyName }}
                    </div>

                    <div class="t-col">
                      {{ partner.firstName }} {{ partner.lastName }}
                    </div>

                    <div class="t-col">
                      {{ partner.email }}
                    </div>

                    <div class="t-col">
                      {{ partner.phone }}
                    </div>

                    <div class="t-col">
                      <timeago
                          :datetime="partner.createdAt"
                          :auto-update="60"
                      ></timeago>
                    </div>
                  </router-link>
                </template>
              </Table>
            </template>

            <template v-if="approvedPartners.length < 1">
              <EmptyStateFrameless
                  main-message="No partners yet"
                  sub-message="There are no partners yet."
              />
            </template>
          </div>
        </div>
        <div v-show="tabItems[1].isActive">
          <template v-if="newPartners.length > 0">
            <Table :columns="columns" :showID="false">
              <template v-for="partner in newPartners">
                <router-link
                    :to="{ name: 'Partner', params: { id: partner.id } }"
                    :key="partner.id"
                    class="t-row"
                >
                  <div class="row-mb">
                    <div class="w-full">
                      <div class="row-mb-header">
                        <timeago
                            class="row-mb-date"
                            :datetime="partner.createdAt"
                            :auto-update="60"
                        ></timeago>
                      </div>
                      <div class="row-mb-name">
                        {{ partner.companyName }}
                      </div>
                      <div class="row-mb-text">{{ partner.phone }}</div>
                    </div>
                    <div class="row-mb-icon-container">
                      <i
                          class="bx bx-chevron-right bx-sm absolute bottom-0"
                      ></i>
                    </div>
                  </div>
                  <div class="t-col font-semibold">
                    {{ partner.companyName }}
                  </div>

                  <div class="t-col">
                    {{ partner.firstName }} {{ partner.lastName }}
                  </div>

                  <div class="t-col">
                    {{ partner.email }}
                  </div>

                  <div class="t-col">
                    {{ partner.phone }}
                  </div>

                  <div class="t-col">
                    <timeago
                        :datetime="partner.createdAt"
                        :auto-update="60"
                    ></timeago>
                  </div>
                </router-link>
              </template>
            </Table>
          </template>

          <template v-if="newPartners.length < 1">
            <EmptyStateFrameless
                main-message="No New partners yet"
                sub-message="There are no new partners yet."
            />
          </template>
        </div>
      </template>
    </div>
  </PageComponent>
</template>

<script>
import Table from "@/components/Table/Table";
import Tab from "@/components/Tabs/Tab";
import EmptyStateFrameless from "@/components/EmptyStates/EmptyStateFrameless";
import Loading from "@/components/Preloaders/Loading";
import {mapActions, mapGetters} from "vuex";
import {PageComponent,ButtonLink} from "@/components";

export default {
  name: "Partners",
  components: {
    PageComponent,
    Loading,
    EmptyStateFrameless,
    Table,
    Tab,
    ButtonLink,
  },
  data() {
    return {
      columns: ["ID", "Company Name", "Name", "Email", "Phone", "Registration"],
      tabItems: [
        {
          name: "Partners",
          isActive: true,
          textColor: "text-black-base",
        },
        {
          name: "New Partner Requests",
          isActive: false,
          textColor: "text-grey-dark",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getPartners: 'partners/getPartners'
    })
  },

  computed: {
    ...mapGetters({
      isLoadingData: 'partners/isLoadingData',
      approvedPartners: 'partners/approvedPartners',
      newPartners: 'partners/newPartners'
    })
  },
  beforeMount() {
    //get all customers
    this.getPartners();
  },
};
</script>
<style scoped>
.t-row {
  @apply grid grid-flow-col grid-cols-8 gap-4;
  @apply mt-3 sm:h-14 sm:px-6 w-full;
  @apply bg-white shadow-sm hover:shadow-md transition duration-300 rounded;
}

.t-col {
  @apply text-sm font-sans text-black-base hidden sm:flex items-center col-span-2 break-all;
}

.row-mb {
  @apply sm:hidden col-span-12 flex justify-between p-4;
}

.row-mb-header {
  @apply inline-flex items-center;
}

.row-mb-date {
  @apply mr-3 text-xs text-black-lighter;
}

.row-mb-name {
  @apply font-semibold leading-5;
}

.row-mb-text {
  @apply text-sm leading-5;
}

.row-mb-icon-container {
  @apply relative w-7 h-full;
}
</style>