<template>
  <div class="p-5 bg-white rounded shadow-sm border border-grey-base">
    <div class="text-base text-black-lightest">{{ title }}</div>
    <div class="flex items-center w-full justify-between pt-1">
      <div class="text-2xl font-bold text-black-base">
        <span v-if="showCurrency">
          {{ currency }}
        </span>
        <span>
          {{ value }}
        </span>
      </div>
      <div
        :class="[
          'flex items-center px-2 py-0.5 mx-2 text-sm text-green-text bg-green-lighter rounded-full',
        ]"
      >
        <i class="far fa-chevron-up"></i>

        <span class="ml-1">{{ percentage }}%</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CardMetrics",
  props: {
    showCurrency: {
      type: Boolean,
      default: true,
    },
    currency: {
      type: String,
      default: "$",
    },
    title: {
      type: String,
      default: "Card title",
    },
    value: {
      type: String,
      default: "9,441.45",
    },
    percentage: {
      type: String,
      default: "36.9",
    },
  },
};
</script>