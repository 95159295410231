import api from "@/api";

export default {
    namespaced: true,
    state: {
        img: {
            travelafrique: require("@/assets/img/logo.jpg"),
            placeholder: require("@/assets/img/placeholders/placeholder.png")
        },
        showAvatar: false,
        showOverlay: false,
        showAssignmentDialog: false,
        showAssignmentConfirmationDialog: false,
        isLoadingData: false,
        isSubmittingData: false,
        isSubmittedSuccessFully: false,
        openRequests: [],
        draftRequests: [],
        changeRequests: [],
        reviewRequests: [],
        pendingRequests: [],
        completedRequests: [],
        cancelledRequests: [],
        archivedRequests: [],
        selectedTripRequestRequestedChanges: [],
        selectedTripRequest: {},
        exchangeRates: {},
    },
    getters: {
        getImg(state) {
            return state.img;
        },
        showAvatar(state) {
            return state.showAvatar;
        },
        showOverlay(state) {
            return state.showOverlay;
        },
        showAssignmentDialog(state) {
            return state.showAssignmentDialog;
        },
        completedRequests(state) {
            return state.completedRequests;
        },
        pendingRequests(state) {
            return state.pendingRequests;
        },
        draftRequests(state) {
            return state.draftRequests;
        },
        cancelledRequests(state) {
            return state.cancelledRequests;
        },
        reviewRequests(state) {
            return state.reviewRequests;
        },
        openRequests(state) {
            return state.openRequests;
        },
        changeRequests(state) {
            return state.changeRequests;
        },
        archivedRequests(state) {
            return state.archivedRequests;
        },
        isLoadingData(state) {
            return state.isLoadingData;
        },

        selectedTripRequestRequestedChanges(state) {
            return state.selectedTripRequestRequestedChanges;
        },

        selectedTripRequest(state) {
            return state.selectedTripRequest;
        },
        isSubmittingData(state) {
            return state.isSubmittingData;
        },
        isSubmittedSuccessFully(state) {
            return state.isSubmittedSuccessFully;
        },
        showAssignmentConfirmationDialog(state) {
            return state.showAssignmentConfirmationDialog;
        }
    },
    mutations: {
        SET_AVATAR_STATE(state, value) {
            state.showAvatar = value;
        },
        SET_ASSIGNMENT_DIALOG_STATE(state, value) {
            state.showAssignmentDialog = value;
        },
        SET_OVERLAY_STATE(state, value) {
            state.showOverlay = value;
        },
        SET_ASSIGNMENT_CONFIRMATION_DIALOG_STATE(state, value) {
            state.showAssignmentConfirmationDialog = value
        },
        SET_OPEN_REQUESTS(state, value) {
            state.openRequests = value;
        },
        SET_DRAFT_REQUESTS(state, value) {
            state.draftRequests = value;
        },
        SET_CHANGE_REQUESTS(state, value) {
            state.changeRequests = value;
        },
        SET_REVIEW_REQUESTS(state, value) {
            state.reviewRequests = value;
        },
        SET_PENDING_REQUESTS(state, value) {
            state.pendingRequests = value;
        },
        SET_COMPLETED_REQUESTS(state, value) {
            state.completedRequests = value;
        },
        SET_CANCELLED_REQUESTS(state, value) {
            state.cancelledRequests = value;
        },
        SET_ARCHIVE_REQUESTS(state, value) {
            state.archivedRequests = value;
        },

        SET_IS_LOADING_DATA(state, value) {
            state.isLoadingData = value;
        },
        SET_IS_SUBMITTING_DATA(state, value) {
            state.isSubmittingData = value;
        },

        SET_IS_SUBMITTED_SUCCESSFULLY(state, value) {
            state.isSubmittedSuccessFully = value;
        },

        SET_SELECTED_TRIP_REQUEST_REQUESTED_CHANGES(state, value) {
            state.selectedTripRequestRequestedChanges = value;
        },

        SET_SELECTED_TRIP_REQUEST(state, value) {
            state.selectedTripRequest = value;
        },
        SET_EXCHANGE_RATES(state, value) {
            state.exchangeRates = value;
        },

        UPDATE_SINGLE_TRIP_REQUEST(state, value) {
            let getIndex = state.openRequests.data.findIndex(item => {
                return item.id === value.id;
            })
            state.openRequests.data.splice(getIndex, 1, value);
        }

    },
    actions: {
        displayAvatar({commit}) {
            commit("SET_AVATAR_STATE", true);
        },
        hideAvatar({commit}) {
            commit("SET_AVATAR_STATE", false);
        },
        showAssignmentDialog({commit}) {
            commit("SET_ASSIGNMENT_DIALOG_STATE", true);
        },
        hideAssignmentDialog({commit}) {
            commit("SET_ASSIGNMENT_DIALOG_STATE", false);
        },
        setShowAssignmentConfirmationDialog({commit}, value) {
            commit("SET_ASSIGNMENT_CONFIRMATION_DIALOG_STATE", value);
        },
        openOverlay({commit}) {
            commit("SET_OVERLAY_STATE", true);
        },
        closeOverlay({commit}) {
            commit("SET_OVERLAY_STATE", false);
        },
        getTripRequests({commit}) {
            commit('SET_IS_LOADING_DATA', true)
            api.getAll([
                '/open-trip-requests',
                '/draft-trip-requests',
                '/changes-trip-requests',
                '/review-trip-requests',
                '/pending-trip-requests',
                '/completed-trip-requests',
                '/cancelled-trip-requests',
                '/archive-trip-requests'
            ])
                .then((response) => {
                    commit('SET_OPEN_REQUESTS', response[0].data.tripRequests)
                    commit('SET_DRAFT_REQUESTS', response[1].data.tripRequests)
                    commit('SET_CHANGE_REQUESTS', response[2].data.tripRequests)
                    commit('SET_REVIEW_REQUESTS', response[3].data.tripRequests)
                    commit('SET_PENDING_REQUESTS', response[4].data.tripRequests)
                    commit('SET_COMPLETED_REQUESTS', response[5].data.tripRequests)
                    commit('SET_CANCELLED_REQUESTS', response[6].data.tripRequests)
                    commit('SET_ARCHIVE_REQUESTS', response[7].data.tripRequests)
                    commit('SET_IS_LOADING_DATA', false)
                })

        },

        getSpecificTripRequests({commit},{tripRequestType,pageNumber}){
            api.get(`/${tripRequestType}-trip-requests?page=${pageNumber}`).then(({data})=>{

                switch (tripRequestType){
                    case 'open':
                        commit('SET_OPEN_REQUESTS',data.tripRequests);
                        break;
                    case 'draft':
                        commit('SET_DRAFT_REQUESTS',data.tripRequests);
                        break;
                    case 'change':
                        commit('SET_CHANGE_REQUESTS',data.tripRequests);
                        break;
                    case 'review':
                        commit('SET_REVIEW_REQUESTS',data.tripRequests);
                        break;
                    case 'pending':
                        commit('SET_PENDING_REQUESTS',data.tripRequests);
                        break;
                    case 'completed':
                        commit('SET_COMPLETED_REQUESTS',data.tripRequests);
                        break;
                    case 'cancelled':
                        commit('SET_CANCELLED_REQUESTS',data.tripRequests);
                        break;
                    case 'archive':
                        commit('SET_ARCHIVE_REQUESTS',data.tripRequests);
                        break;

                }

            })
        },

        getSelectedTripRequestRequestedChanges({commit}, tripRequestID) {
            api.get(`/get-trip-requested-changes/${tripRequestID}`)
                .then(({data}) => {
                    if (data.requested_changes) {
                        commit('SET_SELECTED_TRIP_REQUEST_REQUESTED_CHANGES', data.requestedChanges)
                    }
                });
        },

        getExchangeRates({commit},) {
            api.get('/ta-exchange-rates')
                .then(({data}) => {
                    console.log(data)
                    commit('SET_EXCHANGE_RATES', data)
                });
        },

        setSelectedTripRequest({commit}, tripRequest) {
            commit("SET_SELECTED_TRIP_REQUEST", tripRequest)
        },

        async assignTripRequestToPartner({commit}, requestData) {
            commit('SET_IS_SUBMITTING_DATA', true)
            let request = await api.post('/delegate-trip-request', requestData);
            try {
                if (request.data) {

                    commit('UPDATE_SINGLE_TRIP_REQUEST', request.data.tripRequest);
                    //set the submit status
                    commit('SET_IS_SUBMITTING_DATA', false);
                    commit('SET_IS_SUBMITTED_SUCCESSFULLY', true);
                    //hide the dialog here
                    commit("SET_ASSIGNMENT_DIALOG_STATE", false);
                    commit("SET_ASSIGNMENT_CONFIRMATION_DIALOG_STATE", false);
                    commit("SET_OVERLAY_STATE", false);
                    return request;
                }
            } catch (e) {
                commit('SET_IS_SUBMITTING_DATA', false);
                return request;
            }


        }
    }
}