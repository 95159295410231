<template>
  <div id="table" class="relative">

    <div class="empty-state w-full" v-if="showEmptyState">
      <figure class="max-w-lg relative left-1/2 transform -translate-x-1/2">
        <img
            class="w-full h-full object-cover"
            src="@/assets/img/empty-states/no-change-requests.svg"
            alt="Empty State"
        />
      </figure>
      <h3
          class="font-sans text-black-base text-xl font-semibold text-center mt-6"
      >
        No tours yet
      </h3>
      <p class="font-sans text-black-lighter text-center">
        There are no tours under this section yet.
      </p>
    </div>

    <div v-else>
      <div class="hidden sm:grid px-6 grid-flow-col grid-cols-6 gap-4 mt-8 pb-3 w-full">
        <div
            v-for="(title, index) in header"
            :key="index"
            :class="[
            'text-xs uppercase font-sans text-black-lighter',
            title.customClass,
          ]"
        >
          {{ title.label }}
        </div>
      </div>

      <GroupTourBookingsTableRow
          class="mb-2"
          v-for="(booking, index) in bookings"
          :key="index"
          :booking="booking"
          :showStatus="true"
      />
    </div>
  </div>
</template>
<script>
import GroupTourBookingsTableRow from "./GroupTourBookingsTableRow";

export default {
  name: "GroupToursTable",
  components: {
    GroupTourBookingsTableRow,
  },
  props: {
    header: {
      type: Array,
      default: () => [
        {label:"ID", customClass:""},
        {label:"Name",customClass:"col-span-2"},
        {label:"Transaction ID",customClass:"col-span-2"},
        {label:"Booked At",customClass:""},
      ],
    },
    bookings: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object,
      required: false
    },
  },
  computed: {
    showEmptyState() {
      let boolean = this.bookings.length > 0;
      return !boolean;
    },
  },
};
</script>

<style>
.table-column {
  @apply text-sm font-sans text-black-base font-semibold flex items-center col-span-2;
}

</style>