<template>
  <section id="accommodation">
    <h1 class="form-page-title">Accommodations</h1>

    <div class="w-full">
      <AccommodationConfigurator
          :alreadyHasAccommodations="alreadyHasAccommodations"
          :destination="tourDetails.destination"
          :dates="tourDateRangeString"
          :selectedAccommodation="tourDetails.accommodation"
          :newAccommodation="newAccommodation"
          @resetAccommodation="resetAccommodations"
          @removeAccommodation="removeAccommodationFromTour"
          @addAccommodation="addAccommodationToTour"
      />
    </div>
  </section>
</template>
<script>
import AccommodationConfigurator from "@/components/Offer/Accommodations/AccommodationConfigurator";
import {mapActions, mapGetters} from "vuex";
import {tourDateRangeFromDurationISO} from "@/shared/dateHandler";

export default {
  name: "Accommodations",
  components: {
    AccommodationConfigurator,
  },
  props: {
    alreadyHasAccommodations: {
      type: Boolean,
      default: false,
    },
    newAccommodation: {
      type: String,
      default: "",
    },
  },
  methods: {
    ...mapActions({
      addAccommodation: "groupTours/addAccommodation",
      resetAccommodations: "groupTours/resetAccommodations",
      removeAccommodation: "groupTours/removeAccommodation",
    }),
    addAccommodationToTour(accommodation) {
      this.addAccommodation(accommodation)
    },
    removeAccommodationFromTour(id) {
      this.removeAccommodation(id);
    },
  },
  computed: {
    ...mapGetters({
      tourDetails: "groupTours/tourDetails",
      accommodations: "accommodations/accommodations"
    }),
    tourDateRangeString(){
      if(this.tourDetails.tourDates){
        return tourDateRangeFromDurationISO(this.tourDetails.tourDates[0].split("|")[0],this.tourDetails.duration )
      }
      return null;
    }
  },
};
</script>
<style scoped>
#accommodation {
  @apply pb-44;
}

.form-page-title {
  @apply font-sans font-bold text-black-base text-xl mt-12 sm:text-3xl mb-4 sm:mb-8;
}
</style>