<template>
  <PageComponent title="Customers">
    <div>
      <div v-if="isLoadingData">
        <Loading/>
      </div>
      <div v-else>
        <Table gridCols="grid-cols-10" :columns="columns" :showID="false">
          <template v-for="(rowData, index) in customers">
            <TableRow :key="index" class="mb-2">
              <router-link
                  :to="{ name: 'Customer', params: { id: rowData.id } }"
                  :key="rowData.id"
                  class="t-row"
              >
                <div class="row-mb">
                  <div class="w-full">
                    <div class="row-mb-header">
                      <timeago
                          class="row-mb-date"
                          :datetime="rowData.createdAt"
                          :auto-update="60"
                      ></timeago>
                    </div>
                    <div class="row-mb-name">{{ rowData.name }}</div>
                    <div class="row-mb-text">{{ rowData.email }}</div>
                  </div>
                  <div class="row-mb-icon-container">
                    <i
                        class="bx bx-chevron-right bx-sm absolute bottom-0"
                    ></i>
                  </div>
                </div>
                <div class="t-col font-semibold">
                  {{ rowData.name }}
                </div>

                <div class="t-col">
                  {{ rowData.email }}
                </div>

                <div class="t-col">
                  {{ rowData.phone }}
                </div>

                <div class="t-col">
                  <timeago
                      :datetime="rowData.createdAt"
                      :auto-update="60"
                  ></timeago>
                </div>

                <div class="t-col">
                  {{ rowData.role }}
                </div>
              </router-link>
            </TableRow>
          </template>
        </Table>
      </div>
    </div>
  </PageComponent>
</template>

<script>
import Table from "@/components/Table/Table";
import TableRow from "@/components/Table/TableRow";
import Loading from "@/components/Preloaders/Loading";
import {mapGetters, mapActions} from "vuex";
import {PageComponent} from "@/components";

export default {
  name: "Customers",
  components: {
    Loading,

    Table,
    TableRow,
    PageComponent
  },
  data() {
    return {
      columns: ["ID", "Name", "Email", "Phone", "Joined", "Role"],
    };
  },
  beforeMount() {
    this.getCustomers();
  },
  methods: {
    ...mapActions({
      getCustomers: 'customers/getCustomers'
    })
  },
  computed: {
    ...mapGetters({
      customers: 'customers/customers',
      isLoadingData: 'customers/isLoadingData'
    })
  }
};
</script>
<style scoped>

.t-row {
  @apply grid grid-flow-col grid-cols-10 gap-4 relative;
  @apply h-auto sm:h-14 sm:px-6 w-full mb-2.5;
  @apply bg-white shadow-sm sm:hover:shadow-md;
  @apply transition duration-300 rounded;
}

.t-col {
  @apply text-sm font-sans text-black-base items-center col-span-2 hidden sm:flex;
}

.row-mb {
  @apply sm:hidden col-span-12 flex justify-between p-4;
}

.row-mb-header {
  @apply inline-flex items-center;
}

.row-mb-date {
  @apply mr-3 text-xs text-black-lighter;
}

.row-mb-name {
  @apply font-semibold leading-5;
}

.row-mb-text {
  @apply text-sm leading-5;
}

.row-mb-icon-container {
  @apply relative w-7 h-full;
}
</style>