<template>

  <div class="w-screen h-screen flex justify-center items-center">
    <div>
      <h1 class="font-sans text-xl font-semibold text-teal-base">Oops! Page not found</h1>
      <p>We must have encountered some challenges loading this page</p>

      <p class="text-black-base mt-6 font-semibold ">Perhaps you are here because</p>
      <ul class="list-disc ">
        <li>The page does not exist or has been removed</li>
        <li>You must have mistyped the URL</li>
        <li>You like 404 pages :)</li>
      </ul>

      <div class="mt-6">
        <router-link to="/">
          <i class="far fa-arrow-left"></i> <span class="pl-3 uppercase text-sm font-semibold">Go back to the home page</span>
        </router-link>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "PageNotFoundComponent"
}
</script>

<style scoped>

</style>