<template>
  <div id="pricingsheet" class="sheet">
    <nav class="navbar">
      <ButtonClose @click.native="$emit('closeSheet')" class="btn-close"/>
    </nav>
    <div class="pricing-table">
      <TotalPriceSummary :tripDetails="tripDetails"/>
    </div>

  </div>
</template>
<script>
import ButtonClose from "@/components/Buttons/ButtonClose";
import {mapGetters} from 'vuex'
import TotalPriceSummary from "../Offer/TotalPriceSummary";

export default {
  name: "RequestSheet",
  components: {
    TotalPriceSummary,
    ButtonClose,
  },
  data() {
    return {
      tabItems: [
        {name: "Request details", isActive: true},
        {name: "Requested changes", isActive: false},
      ],
    };
  },
  props: {
    request: {
      required: true,
      type: Object,
    },
    isTabItems: {
      type: Boolean,
      default: true,
    },
    changeRequests: {
      type: Array,
      default: () => [],
    },
    hasExtras: {
      required: false,
      type: Boolean,
    },
    hideOfferButton: {
      required: false,
      type: Boolean,
    },
    travelers: {
      type: Object,
      default: () => {
        return {
          adults: 1,
          children: {
            number: 3,
            ages: [0, 1, 3],
          },
        };
      },
    },
  },
  computed: {
    ...mapGetters({
      tripDetails: 'offer/tripDetails',
    }),
  },
};
</script>
<style scoped>
.sheet {
  @apply bg-white overflow-y-auto pb-20 px-4 sm:px-12;
}

.navbar {
  @apply max-w-screen-sm fixed z-50 left-1/2 transform -translate-x-1/2 w-full py-4;
}

.btn-close {
  @apply absolute right-3;
}
.pricing-table{
  @apply pt-20;
}
</style>