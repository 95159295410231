<template>
  <div>
    <label
      :for="name"
      v-if="(!allowMultiple && files.length < 1) || allowMultiple"
    >
      <span v-if="showLabel" class="input-label">{{ label }}</span>
      <div class="input-container">
        <EmptyStateFramed
          class="border-none"
          :title="title"
          :text="text"
          :iconClass="iconClass"
        />
        <input
          :multiple="allowMultiple"
          class="invisible w-0 h-0 overflow-hidden absolute"
          :accept="allowedFileTypes"
          type="file"
          :name="name"
          :id="name"
          @input="getFile"
        />
      </div>
    </label>
    <div
      :class="
        allowMultiple
          ? 'input-file-preview-container'
          : 'input-file-preview-container-single'
      "
    >
      <div
        v-for="(file, index) in files"
        v-show="showFile"
        :key="index"
        class="input-file-preview"
      >
        <figure class="relative h-56 w-full">
          <img
            class="absolute w-full h-full object-cover"
            :src="file.path"
            alt=""
          />
        </figure>
        <div class="buttom-part flex items-center justify-between px-4 py-2">
          <div class="flex items-center">
            <i :class="[iconClass, 'bx text-teal-base']"></i>
            <p class="input-file-name">{{ file.name }}</p>
          </div>
          <button type="button" class="btn-remove" @click="removeFile(index)">
            <i class="bx bx-trash"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EmptyStateFramed from "@/components/EmptyStates/EmptyStateFramed";

export default {
  name: "InputUpload",
  components: {
    EmptyStateFramed,
  },
  data() {
    return {
      showFile: false,
      files: [],
      allFiles: [],
    };
  },
  props: {
    value: {
      type: [String, Object, Array, File],
    },
    allowedFileTypes: {
      type: String,
      default: ".jpg, .jpeg, .png, .pdf",
    },
    iconClass: {
      type: String,
      default: "bx-image-alt",
    },
    title: {
      type: String,
      default: "Title",
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    showDescription: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: "Description",
    },
    label: {
      type: String,
      default: "Label",
    },
    text: {
      type: String,
      default: "Text",
    },
    name: {
      type: String,
      default: "file",
    },

    allowMultiple: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    if (this.value || this.value.length) {
      if (this.value instanceof File) {
        this.files.push(this.value);
        this.files.map((file) => {
          file.path = URL.createObjectURL(file);
        });
      }
      // this.files.push(this.value);
    }
  },
  methods: {
    getFile(event) {
      //Set FileList array in a variable
      let uploadedFiles = event.target.files;

      if (uploadedFiles.length < 1) {
        return;
      }

      if (this.allowMultiple) {
        this.allFiles = [...event.target.files, ...this.allFiles];
        //Push the files in the Files Array
        this.files = [...uploadedFiles, ...this.files];
      } else {
        this.files = [uploadedFiles[0]];
      }

      //Create blob filepath for preview
      for (let file of this.files) {
        file.path = URL.createObjectURL(file);
      }

      //Show uploaded files if files array isn't empty
      if (this.files.length > 0) {
        this.showFile = true;
      }

      this.$emit("itemSelected");
      if (this.allowMultiple) {
        this.$emit("input", this.allFiles);
      } else {
        this.$emit("input", event.target.files[0]);
      }
    },
    removeFile(index) {
      this.files.splice(index, 1);
      this.allFiles.splice(index, 1);
      this.$emit("input", this.allFiles);
    },
  },
};
</script>
<style scoped>
.input-label {
  @apply text-black-base block font-sans font-semibold;
  @apply text-sm sm:text-base;
}

.input-container {
  @apply border hover:bg-grey-lighter cursor-pointer mt-3 border-dashed border-grey-dark rounded;
}

.input-file-preview-container {
  @apply grid grid-cols-4 sm:gap-6 w-full;
}

.input-file-preview-container-single {
  @apply grid grid-cols-1  w-full;
}

.input-file-preview {
  @apply rounded-md border border-grey-base col-span-full sm:col-span-2 overflow-hidden mt-6;
}

.input-file-name {
  @apply ml-1.5 sm:ml-3 text-sm sm:text-base truncate;
}

.btn-remove {
  @apply rounded-full ml-2 text-red-base bg-white w-10 h-8 py-2;
  @apply hover:bg-grey-light transition-all ease-out duration-300;
  @apply flex items-center justify-center;
  @apply border border-grey-base;
}
</style>
