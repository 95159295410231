<template>
  <section id="trip-details">
    <h1 class="form-page-title">Trip details</h1>
    <SelectDropdown
      label="Destination"
      :showLabel="true"
      description="Destination"
      class="mb-6"
      :selected="tripDetails.tripDestination"
      :optionsList="$africanDestinations"
      v-model="tripDetails.tripDestination"
      @change="changedDestination"
    />
    <InputText
      label="Trip title"
      placeholder="e.g. Amazing Morocco"
      class="mb-6"
      name="trip-title"
      v-model="tripDetails.tripTitle"
    />
    <div class="mb-6">
      <DateRangePicker v-model="tripDetails.tripDates" @input="getDates" />
    </div>
    <MultiSelect
      label="Select agencies"
      placeholder="Select one or more agencies"
      class="mt-6"
      :options="partners"
      v-model="tripDetails.tripAgencies"
    />
  </section>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import {
  DateRangePicker,
  InputText,
  MultiSelect,
  SelectDropdown,
} from "@/components";

export default {
  name: "TripDetails",
  components: {
    DateRangePicker,
    InputText,
    MultiSelect,
    SelectDropdown,
  },
  props: {},
  data() {
    return {
      value: null,
      options: ["list", "of", "options"],
      tripDates: "",
      showDateField: true,
      knowsDateOfTravel: true,
      dateRange: "",
    };
  },
  computed: {
    ...mapGetters({
      tripDetails: "offer/tripDetails",
      approvedPartners: "partners/approvedPartners",
    }),

    partners() {
      let data = [];
      this.approvedPartners.forEach((element) => {
        let partner = {
          id: element.id,
          imgPath: element.profilePhoto,
          title: element.companyName,
          subTitle: element.firstName + " " + element.lastName,
          showItem: false,
          showCheckMark: false,
        };
        data.push(partner);
      });
      return data;
    },
  },

  mounted() {
    this.tripDetails.tripDateKnown = true;
    this.tripDates = this.tripDetails.tripDates;
  },
  methods: {
    ...mapActions({
      getExperiences: "experiences/getExperiences",
      dateChanged: "offer/dateChanged",
    }),
    getDates(data) {
      console.log(data);
      this.dateChanged();
      this.tripDetails.tripDates = data;
    },
    toggleDatePicker() {
      this.showDateField = true;
    },
    toggleNumberSpinner() {
      this.showDateField = false;
    },
    changedDestination() {
      this.getExperiences(this.tripDetails.tripDestination);
    },
  },
};
</script>
<style scoped>
#trip-details {
  @apply min-h-screen pb-44;
}

.form-page-title {
  @apply font-sans font-bold text-black-base text-xl mt-12 sm:text-3xl mb-4 sm:mb-8;
}
</style>
