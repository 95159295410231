<template>
  <div class="input-wrapper">
    <label for="start-date-picker" class="w-full mb-6 sm:mb-0">
      <span class="input-label" v-if="showLabels">Start date</span>
      <input
        :data-dd-opt-min-date="getCurrentDate"
        :data-dd-opt-default-date="getDefaultDate"
        :id="id"
        :name="id"
        placeholder="Start date"
        ref="startDatePicker"
        class="input-datedropper-start"
        type="text"
        v-model="startDate"
      />
      <button
        @click="$emit('removeItem', startDate)"
        type="button"
        class="delButton"
        v-if="showDeleteButton"
      >
        <i class="bx bx-trash"></i>
      </button>
    </label>
  </div>
</template>
<script>
import "../../assets/datedropper/datedropper-javascript";
import dayjs from "dayjs";

export default {
  name: "DatePicker",
  props: {
    value: String,
    showLabels: {
      type: Boolean,
      default: true,
    },
    showDeleteButton: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "start-date-picker",
    },
  },
  mounted() {
    new window.dateDropper({
      selector: `#${this.id}`,
      startFromMonday: true,
      minYear: new Date().getFullYear(),
      format: "d/m/y",
      showArrowsOnHover: false,
      expandedOnly: true,
      overlay: true,
      defaultDate: true,
      onChange: () => {
        //this.startDate= res.output.string;
        //this.updateDate(res.output.string);
      },
    });

    // if (this.value === "") {
    //   this.startDate = this.getCurrentDate;
    // } else {
    //   this.startDate = this.value;
    // }
  },
  data() {
    return {
      startDate: "",
    };
  },

  computed: {
    getCurrentDate() {
      return dayjs().format("ddd, D MMM YYYY");
    },
    getDefaultDate() {
      return dayjs(this.value).format("YYYY/MM/D");
    },
  },

  methods: {
    updateDate(newDate = this.startDate) {
      this.$emit("input", newDate);
    },
  },
  watch: {
    value(newVal) {
      if (newVal !== this.startDate) {
        this.startDate = newVal;
      }
    },
  },
};
</script>
<style src="@/assets/datedropper/datedropper.css"></style>
<style scoped>
.input-datedropper-start {
  @apply rounded border placeholder-black-lightest text-black-base border-grey-dark;
  @apply w-full focus:border-teal-base focus:ring-teal-lighter focus:ring-2;
  @apply p-2 sm:p-3 text-sm sm:text-base;
}

.input-wrapper {
  @apply flex flex-wrap justify-between relative;
}

.delButton {
  @apply bg-red-light focus:outline-none hover:bg-red-lighter;
  @apply duration-300 transition-all px-4 py-1 sm:py-2 rounded text-red-text;
  @apply ml-4 absolute top-1/2 right-1.5 transform -translate-y-1/2;
  @apply text-base;
}

.input-label {
  @apply mb-1.5 sm:mb-3 font-sans font-medium text-black-base block;
  @apply text-sm sm:text-base;
}
</style>
