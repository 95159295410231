<template>
  <div
    class="rounded-md border border-grey-base col-span-2 overflow-hidden mt-6"
  >
    <figure class="relative h-56 w-full">
      <img
        class="absolute w-full h-full object-cover"
        :src="image.path"
        alt=""
      />
    </figure>
    <div class="buttom-part flex items-center justify-between px-4 py-2">
      <div class="flex items-center">
        <i class="far fa-camera-retro text-teal-base"></i>
        <p class="ml-3">{{ image.name }}</p>
      </div>
      <ButtonIcon
        faIconClass="fal fa-trash-alt"
        customStyle="text-red-base hover:bg-red-lighter transition duration-300 block py-2 px-3 focus:outline-none focus:scale-95 focus:ring-offset-2 focus:ring-2 focus:ring-red-darker rounded-full"
        @click.native="$emit('remove')"
      />
    </div>
  </div>
</template>
<script>
import ButtonIcon from "@/components/Buttons/ButtonIcon";

export default {
  name: "ImagePreview",
  components: {
    ButtonIcon,
  },
  props: {
    image: {
      type: Object,
    },
  },
};
</script>