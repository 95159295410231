<template>
  <div>
    <nav class="navbar">
      <div class="nav-wrapper">
        <button
            v-if="currentPage > 1"
            class="btn-back"
            type="button"
            @click="goToPreviousPage()"
        >
          <i class="bx bx-arrow-back"></i>
          <span class="btn-back-text">Back</span>
        </button>

        <button
            v-else
            class="close-btn"
            type="button"
            @click="exitCreatePartnerForm"
        >
          <i class="bx bx-arrow-back"></i>
          <span class="btn-back-text">Exit</span>
        </button>

        <ProgressIndicator
            :currentPage="currentPage"
            :totalPages="pages.length"
        />
      </div>
    </nav>
    <form
        action=""
        class="form"
        @submit="showData($event)"
    >
      <div v-show="pages[0].show" class="form-page">
        <h2 class="form-page-title">
          Partner's personal details
        </h2>
        <p class="form-page-description">
          Let us know who is behind the company
        </p>
        <div class="form-page-container">
          <div class="form-page-wrapper">
            <InputText
                v-model="form.firstName"
                class="input-half"
                label="First name"
                name="first-name"
                placeholder="First name"
            />
            <InputText
                v-model="form.lastName"
                class="input-half"
                label="Last name"
                name="last-name"
                placeholder="Last name"
            />
            <InputText
                v-model="form.email"
                class="input-full"
                label="Email"
                name="email"
                placeholder="Email"
            />

            <InputPhone
                v-model="form.phone"
                :phoneNumber="form.phone"
                @getObj="updateNumberObj($event)"
                @input="updateNumber($event)"
                @sliceZeros="updateNumber($event)"
            />

            <InputUpload
                v-model="form.identification"
                :allowMultiple="false"
                class="input-upload"
                label="Upload a copy of the partners ID or passport"
                name="personal-doc"
                text="Only pdf, jpeg, jpg and png allowed (max 10 MB)."
                title="Click to upload the partner's file"
            />
          </div>
        </div>
      </div>
      <div v-show="pages[1].show" class="form-page">
        <h2 class="form-page-title">
          Partner's Company details
        </h2>
        <p class="form-page-description">
          About the partner's local agency
        </p>
        <div class="form-page-container">
          <div class="form-page-wrapper">
            <InputText
                v-model="form.companyName"
                class="input-full"
                label="Legal name of the company"
                name="company-name"
                placeholder="Company name"
            />
            <InputText
                v-model="form.position"
                class="input-full"
                label="Your position in the company"
                name="position"
                placeholder="CEO, Manager, Agent, etc."
            />

            <div class="w-full">
              <h4 class="form-page-section-title">
                Company address
              </h4>
            </div>


            <InputText
                v-model="form.companyStreet"
                class="input-full"
                label="Street & housenr."
                name="street"
                placeholder="Street & housenr."
            />

            <InputText
                v-model="form.companyLocation"
                class="input-full"
                label="Location"
                name="location"
                placeholder="Enter partners location"
            />
            <InputText
                v-model="form.companyZipCode"
                class="input-half"
                label="ZIP Code"
                name="zip-code"
                placeholder="ZIP"
                type="number"
            />

            <SelectDropdown
                v-model="form.companyCountry"
                :optionsList="$africanDestinations"
                :showLabel="true"
                class="input-half"
                description="Country of headquarters"
                label="Country of headquarters"
            />

            <InputUpload
                v-model="form.companyIdentification"
                :allowMultiple="false"
                class="input-upload"
                label="Upload a document with the registered name of the partner's company and the address you entered (e.g. electricity bill, bank statement, certificate of incorporation etc.)"
                name="company-doc"
                text="Only pdf, jpeg, jpg and png allowed (max 10 MB)."
                title="Click to upload the partner's file"
            />
          </div>
        </div>
      </div>
      <div v-show="pages[2].show" class="form-page">
        <h2 class="form-page-title">
          Operations
        </h2>
        <p class="form-page-description">
          Choose the options that best suit you
        </p>
        <div class="form-page-container">
          <div class="form-page-wrapper">
            <div class="input-radio">
              <p class="input-label">
                Are you a DMC? (Destination Management Company)
              </p>

              <label class="mr-8">
                <input
                    v-model="form.dmc"
                    :value="true"
                    name="dmc"
                    type="radio"
                />
                <span class="label ml-2">Yes</span>
              </label>

              <label>
                <input
                    v-model="form.dmc"
                    :value="false"
                    name="dmc"
                    type="radio"
                />
                <span class="label ml-2">No</span>
              </label>
            </div>
            <div class="input-radio">
              <p class="input-label">
                Do you have a travel agency license?
              </p>

              <label class="mr-8">
                <input
                    v-model="form.license"
                    :value="true"
                    name="license"
                    type="radio"
                />
                <span class="label ml-2">Yes</span>
              </label>

              <label>
                <input
                    v-model="form.license"
                    :value="false"
                    name="license"
                    type="radio"
                />
                <span class="label ml-2">No</span>
              </label>
            </div>


            <div class="mb-6 w-full">
              <MultiSelect
                  label="Destination(s) in which you provide services for travelers"
                  placeholder="Select one or more countries"
                  class="block w-full"
                  :options="$africanDestinations"
                  :optionDisplayValue="(option)=>{return option.value}"
                  v-model="form.destinations"
              />
            </div>


            <div class="input-radio">
              <p class="input-label">
                Do you have a public liability insurance?
              </p>

              <label class="mr-8">
                <input
                    v-model="form.insurance"
                    :value="true"
                    name="insurance"
                    type="radio"
                />
                <span class="label ml-2">Yes</span>
              </label>

              <label>
                <input
                    v-model="form.insurance"
                    :value="false"
                    name="insurance"
                    type="radio"
                />
                <span class="label ml-2">No</span>
              </label>
            </div>

            <InputText
                v-model="form.website"
                class="input-full"
                label="Website (optional)"
                name="website"
                placeholder="e.g. www.website.com"
            />

            <InputText
                v-model="form.companyEmail"
                class="input-full"
                label="Company Email (optional)"
                name="company-email"
                placeholder="e.g. info@example.com"
            />

            <InputText
                v-model="form.startYear"
                class="input-full"
                label="When did the partner's agency start its operations?"
                name="operation-start"
                placeholder="e.g. 12. June 1993"
            />

            <div class="input-checkbox">
              <p class="input-label">
                In which languages do you offer the partner's services?
              </p>

              <template v-for="(language, index) in $officialLanguages">
                <div :key="index" class="flex flex-wrap my-6">
                  <label class="flex items-center w-full">
                    <input
                        v-model="form.languages"
                        :value="language.value"
                        class="check-box-component form-checkbox"
                        type="checkbox"
                    />
                    <span class="check-box-text">{{ language.name }}</span>
                  </label>
                </div>
              </template>
            </div>

            <InputUpload
                v-model="form.profilePhoto"
                :allowMultiple="false"
                :showDescription="true"
                allowedFileTypes=".jpg, .png, .jpeg"
                class="mt-3 w-full col-span-2"
                description="Customers will see this photo, when booking through the partner's agency"
                label="Upload a high quality photo of you"
                name="photo"
                text="Only pdf, jpeg, jpg and png allowed (max 10 MB)."
                title="Click to upload the partner's file"
            />
          </div>
        </div>
      </div>
      <div class="bottom-bar">
        <div class="bottom-bar-wrapper">
          <ButtonPrimary
              v-if="!isSubmittingData"
              :text="buttonText"
              class="btn-action"
              @click.native="goToNextPage()"
          />

          <div v-if="isSubmittingData" class="s:w-64 block ml-auto py-2">
            <Loading :showLabel="true" label="Creating Partner" margin="my-0"/>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<script>

import {mapActions, mapGetters} from "vuex";
import {
  ButtonPrimary,
  InputPhone,
  InputText,
  InputUpload,
  Loading,
  MultiSelect,
  ProgressIndicator,
  SelectDropdown,
} from "@/components";

export default {
  name: "BusinessForm",
  components: {
    InputPhone,
    ButtonPrimary,
    InputText,
    InputUpload,
    SelectDropdown,
    ProgressIndicator,
    Loading,
    MultiSelect,
  },
  data() {
    return {
      isLoading: false,
      pages: [{show: true}, {show: false}, {show: false}],

      form: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        identification: "",
        companyName: "",
        position: "",
        companyCountry: "",
        companyAddress: "",
        companyZipCode: "",
        companyIdentification: "",
        companyEmail: "",
        dmc: "",
        destinations: [],
        license: "",
        insurance: "",
        website: "",
        startYear: "",
        languages: [],
        profilePhoto: "",
        companyStreet: "",
        companyLocation: "",
      },
    };
  },
  methods: {

    ...mapActions({
      addNewPartner: 'partners/addNewPartner'
    }),
    updateNumberObj(obj) {
      this.form.phone = obj.dialCode + obj.phone;
    },
    updateNumber(value) {
      this.form.phone = value.dialCode + value.phone;
    },
    submitForm() {
      this.isLoading = false;

      let formData = new FormData();

      //concatenate the location and the street to form the address

      this.form.companyAddress =
          this.form.companyStreet +
          "," +
          this.form.companyLocation +
          "," +
          this.form.companyZipCode;

      for (let key in this.form) {
        if (key == "identification") {
          formData.append(`identification`, this.form.identification);
        } else if (key == "companyIdentification") {
          formData.append(
              `companyIdentification`,
              this.form.companyIdentification
          );
        } else if (key == "profilePhoto") {
          formData.append(`profilePhoto`, this.form.profilePhoto);
        } else if (key == "languages") {
          formData.append("languages", this.form.languages.toString());
        } else if (key === "dmc" || key === "license" || key === "insurance") {
          //change true or false to 1 and zero
          formData.append(key, this.form[key] == true ? 1 : 0);
        } else if (key === "destinations") {
          const selectedDestinations= this.$africanDestinations.reduce((container, currentItem)=>{
            if(this.form.destinations.includes (currentItem.id))
              container.push(currentItem.value)
            return container;
          },[])
          formData.append(key, selectedDestinations.toString());
        } else {
          formData.append(key, this.form[key]);
        }
      }

      this.addNewPartner(formData);
    },
    exitCreatePartnerForm() {
      this.$router.go(-1);
    },
    showData(e) {
      e.preventDefault();
    },
    goToPreviousPage() {
      for (let [index, page] of this.pages.entries()) {
        if (page.show == true) {
          page.show = false;
          this.pages[index - 1].show = true;
          break;
        }
      }
    },
    goToNextPage() {
      if (this.currentPage == this.pages.length) {
        this.submitForm();
      }

      for (let [index, page] of this.pages.entries()) {
        if (page.show == true && index != this.pages.length - 1) {
          page.show = false;
          this.pages[index + 1].show = true;
          break;
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      isSubmittingData: 'partners/isSubmittingData',
      newPartner: 'partners/newPartner',
      submissionStatus: 'partners/submissionStatus',
      submissionStatusMessage: 'partners/submissionStatusMessage',
    }),
    currentPage() {
      let currentPage;
      for (let [index, page] of this.pages.entries()) {
        if (page.show == true) {
          currentPage = index + 1;
          break;
        }
      }
      return currentPage;
    },
    buttonText() {
      return this.currentPage == this.pages.length? "Submit":"Continue";
    },
  },
  watch: {
    submissionStatus(newVal) {
      if (newVal === 'success') {
        this.$notify({
          type: "success",
          title: "Success",
          text: this.submissionStatusMessage,
        });
        this.$router.push({name: "Partners"});

      } else {
        this.$notify({
          type: "error",
          title: "OOPS! Something went wrong",
          text: "Please check and ensure all experience form fields are filled",
        });
      }
    }
  }
};
</script>

<style scoped>
.navbar {
  @apply top-0 bg-white flex w-full flex-wrap items-center px-4 md:px-12 h-20;
}

.nav-wrapper {
  @apply relative transform left-1/2 -translate-x-1/2 max-w-screen-md w-full flex items-center justify-between h-12;
}

.btn-back {
  @apply text-xl flex items-center focus:outline-none focus:ring-2 focus:ring-black-lighter;
  @apply focus:scale-95 focus:ring-offset-2 hover:text-black-base;
  @apply transition ease-in-out duration-300 rounded p-3 text-black-lightest;
}

.btn-back-text {
  @apply pl-3 uppercase text-sm font-semibold;
}

.close-btn {
  @apply text-xl flex items-center focus:outline-none;
  @apply focus:ring-2 focus:ring-black-lighter focus:scale-95;
  @apply focus:ring-offset-2 hover:text-black-base transition ease-in-out;
  @apply duration-300 rounded p-3 text-black-lightest;
}

.form {
  @apply w-full px-4 pb-48 md:px-12 mt-10;
}

.form-page {
  @apply max-w-screen-md relative transform left-1/2 -translate-x-1/2;
}

.form-page-title {
  @apply font-sans font-bold text-left sm:text-center text-3xl sm:text-4xl;
  @apply mb-4 text-black-base;
}

.form-page-section-title {
  @apply font-sans text-grey-dark uppercase font-semibold text-sm;
  @apply mt-10;
}

.form-page-description {
  @apply font-sans text-black-lightest text-left  sm:text-center text-lg sm:text-xl;
  @apply mb-16;
}

.form-page-container {
  @apply mb-6 sm:mb-8 w-full;
}

.form-page-wrapper {
  @apply flex flex-wrap justify-between;
}

.input-full,
.input-half {
  @apply mb-8 self-start;
}

.input-full {
  @apply w-full;
}

.input-half {
  @apply w-full sm:w-calc-1/2;
}

.input-label {
  @apply font-medium mb-3 block text-black-base;
}


.input-upload {
  @apply mt-6 w-full;
}

.input-checkbox,
.input-radio {
  @apply w-full mb-6;
}

.check-box-component {
  @apply w-5 h-5 border rounded  border-black-lightest;
}

.check-box-text {
  @apply ml-3 text-sm text-black-base;
}

.bottom-bar {
  @apply fixed left-0 bottom-0 p-4 border-t border-grey-base w-full;
  @apply bg-white sm:mt-16;
}

.bottom-bar-wrapper {
  @apply flex justify-between items-center max-w-screen-md relative transform left-1/2 -translate-x-1/2;
}

.btn-action {
  @apply w-full sm:w-64 block px-8 h-12 ml-auto;
}
</style>