<template>
  <section id="cancellation" class="pb-52">
    <h1 class="form-page-title">Cancellation policy</h1>
    <div class="relative h-full w-full">
      <h2 class="sub-title">Full refund</h2>
      <div class="full-tag tag">100%</div>
      <InputText
        label="Amount of days before the trip"
        type="number"
        placeholder="e.g. 60"
        name="full-refund"
        v-model="tripDetails.tripFullRefundDays"
      />
      <div class="divider"></div>
      <h2 class="sub-title">Partial refund</h2>
      <div class="partial-tag tag">
        <template v-if="tripDetails.tripPartialRefund">
          {{ tripDetails.tripPartialRefund || 0
          }}{{ percentageSign }}
        </template>
        <template v-else> Enter amount below </template>
      </div>
      <InputText
        label="Amount to be refunded in %"
        type="number"
        placeholder="e.g. 10"
        name="refund-value"
        v-model="tripDetails.tripPartialRefund"
      />
      <InputText
        label="Amount of days before the trip"
        type="number"
        placeholder="e.g. 30"
        name="partial-refund"
        class="mt-6"
        v-model="tripDetails.tripPartialRefundDays"
      />
      <div class="divider"></div>
      <h2 class="sub-title">No refund</h2>
      <div class="no-tag tag">0%</div>
      <InputText
        label="Amount of days before the trip"
        type="number"
        placeholder="e.g. 30"
        name="no-refund"
        v-model="tripDetails.tripNoRefundDays"
      />
    </div>
  </section>
</template>
<script>
import InputText from "@/components/Inputs/InputText";
import { mapGetters } from "vuex";
export default {
  name: "Cancellation",
  components: {
    InputText,
  },
  data() {
    return {
      partialRefundValue: "Enter amount below",
      region: "en-EN",
    };
  },
  methods: {},
  computed: {
    ...mapGetters({
      tripDetails: "offer/tripDetails",
    }),
    percentageSign() {
      if (
        this.tripDetails.tripPartialRefund == "Enter amount below"
      ) {
        return "";
      }
      return "%";
    },
  },
};
</script>
<style scoped>
.form-page-title {
  @apply font-sans font-bold text-black-base text-xl mt-12 sm:text-3xl mb-4 sm:mb-8;
}
.sub-title {
  @apply font-sans font-semibold text-black-base text-lg sm:text-2xl mb-1;
}
.full-tag {
  @apply text-teal-text bg-teal-light;
}
.partial-tag {
  @apply text-yellow-text bg-yellow-light;
}

.no-tag {
  @apply bg-red-light text-red-text;
}

.tag {
  @apply font-semibold mb-8 inline-block;
  @apply rounded px-2 py-1 text-sm;
}

.divider {
  @apply my-12 border-b border-grey-base w-full;
}
</style>