import Vue from "vue";
import Vuex from "vuex";
import offer from './modules/offer.store';
import groupTours from './modules/group-tours.store';
import auth from './modules/auth.store';
import experiences from './modules/experiences.store';
import accommodations from './modules/accommodations.store';
import lightBox from './modules/lightbox.store';
import partners from './modules/partners.store';
import customers from './modules/customers.store';
import requests from './modules/requests.store';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);
//persist auth state 
const tokenState = createPersistedState({
    paths: ['auth','groupTours.tourDetails']
})

export default new Vuex.Store({
    modules: {
        offer,
        auth,
        lightBox,
        experiences,
        accommodations,
        partners,
        customers,
        requests,
        groupTours
    },
    plugins: [tokenState]
})





